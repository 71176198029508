import React, { useState } from 'react';
import { Construction, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type DeckMaterial = 'pressure-treated' | 'cedar' | 'composite' | 'pvc';
type DeckHeight = 'ground' | 'elevated' | 'multi';
type RailingType = 'wood' | 'metal' | 'composite' | 'glass';

export default function DeckCalculator() {
  // State for deck details
  const [deckMaterial, setDeckMaterial] = useState<DeckMaterial>('pressure-treated');
  const [deckHeight, setDeckHeight] = useState<DeckHeight>('ground');
  const [railingType, setRailingType] = useState<RailingType>('wood');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [heightValue, setHeightValue] = useState('10'); // Fixed: Added heightValue state
  const [includeStairs, setIncludeStairs] = useState(false);
  const [stairSteps, setStairSteps] = useState('3');
  const [includeSeating, setIncludeSeating] = useState(false);
  const [includePergola, setIncludePergola] = useState(false);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const materialCosts = {
    'pressure-treated': {
      decking: 4.50, // per sq ft
      framing: 3.50,
      posts: 45 // each
    },
    cedar: {
      decking: 8.50,
      framing: 5.50,
      posts: 75
    },
    composite: {
      decking: 12.00,
      framing: 3.50,
      posts: 45
    },
    pvc: {
      decking: 14.00,
      framing: 3.50,
      posts: 45
    }
  };

  const railingCosts = {
    wood: 30, // per linear ft
    metal: 55,
    composite: 65,
    glass: 120
  };

  const laborRates = {
    installation: 12, // per sq ft
    stairs: 250, // per step
    seating: 45, // per linear ft
    pergola: 35 // per sq ft
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    if (!l || !w) return null;

    const area = l * w;
    const perimeter = 2 * (l + w);
    
    // Calculate base material cost
    const material = materialCosts[deckMaterial];
    const materialCost = area * material.decking;
    const framingCost = area * material.framing;
    
    // Calculate post requirements and costs
    const postSpacing = 6; // feet
    const postsNeeded = Math.ceil((l / postSpacing) * (w / postSpacing));
    const heightMultiplier = deckHeight === 'ground' ? 1 : deckHeight === 'elevated' ? 1.5 : 2;
    const postCost = postsNeeded * material.posts * heightMultiplier;

    // Calculate railing costs if needed
    const railingCost = (deckHeight !== 'ground' || heightMultiplier > 1) 
      ? perimeter * railingCosts[railingType]
      : 0;

    // Calculate stair costs
    const steps = parseInt(stairSteps);
    const stairCost = includeStairs ? (steps * laborRates.stairs) : 0;

    // Calculate additional features
    const seatingCost = includeSeating ? (perimeter * laborRates.seating) : 0;
    const pergolaCost = includePergola ? (area * laborRates.pergola) : 0;

    // Calculate labor costs
    const baseLaborCost = area * laborRates.installation * heightMultiplier;
    const totalLaborCost = baseLaborCost + stairCost + seatingCost + pergolaCost;

    // Add permit fees for certain jobs
    const permitFee = includePermit ? 250 : 0;
    const hardwareCost = area * 1.25; // Fasteners, brackets, etc.

    // Calculate totals
    const materialTotal = materialCost + framingCost + postCost + railingCost + hardwareCost;
    const tax = materialTotal * 0.075; // 7.5% sales tax on materials
    const total = materialTotal + totalLaborCost + tax + permitFee;

    return {
      area: area.toFixed(0),
      perimeter: perimeter.toFixed(0),
      costs: {
        base: materialCost.toFixed(2),
        framing: framingCost.toFixed(2),
        posts: postCost.toFixed(2),
        railing: railingCost.toFixed(2),
        hardware: hardwareCost.toFixed(2)
      },
      labor: totalLaborCost.toFixed(2),
      features: {
        stairs: stairCost.toFixed(2),
        seating: seatingCost.toFixed(2),
        pergola: pergolaCost.toFixed(2)
      },
      permit: permitFee.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-600 to-amber-800 p-6">
      {/* Rest of the JSX remains the same */}
    </div>
  );
}