import React, { useState } from 'react';
import { Square, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';
import { WindowConfigPanel } from '../components/windows/WindowConfigPanel';
import { WindowBreakdown } from '../components/windows/WindowBreakdown';
import { WindowList } from '../components/windows/WindowList';
import { calculateWindowEstimate } from '../utils/windowCalculations';
import type { WindowConfig } from '../types/windows';

export default function WindowCalculator() {
  const [windowConfigs, setWindowConfigs] = useState<WindowConfig[]>([]);
  const [currentConfig, setCurrentConfig] = useState<WindowConfig>({
    id: '1',
    type: 'double',
    material: 'vinyl',
    glass: 'standard',
    grade: 'standard',
    details: {
      width: '36',
      height: '60',
      quantity: '1',
      includeFrame: true,
      includeHardware: true,
      includeFinishing: false,
      needsStructuralWork: false,
      includeScreens: true,
      includeGrilles: false
    }
  });

  const addWindowConfig = () => {
    setWindowConfigs([...windowConfigs, {
      ...currentConfig,
      id: (windowConfigs.length + 1).toString()
    }]);
    setCurrentConfig({
      ...currentConfig,
      id: (windowConfigs.length + 2).toString(),
      details: {
        ...currentConfig.details,
        quantity: '1'
      }
    });
  };

  const removeWindowConfig = (id: string) => {
    setWindowConfigs(windowConfigs.filter(config => config.id !== id));
  };

  const results = calculateWindowEstimate(windowConfigs);

  return (
    <div className="min-h-screen bg-gradient-to-b from-cyan-600 to-cyan-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Square className="w-8 h-8 text-cyan-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Window Calculator</h1>
        </div>

        <div className="space-y-6">
          <WindowConfigPanel
            config={currentConfig}
            onChange={setCurrentConfig}
          />

          <button
            onClick={addWindowConfig}
            className="w-full py-2 px-4 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 transition flex items-center justify-center space-x-2"
          >
            <span>Add Window to Quote</span>
          </button>

          {windowConfigs.length > 0 && (
            <WindowList
              configs={windowConfigs}
              onRemove={removeWindowConfig}
            />
          )}

          {results && windowConfigs.length > 0 && (
            <WindowBreakdown results={results} />
          )}

          <div className="bg-cyan-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-cyan-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-cyan-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for custom sizes or special requirements.</p>
                <p>* Professional measurement recommended for accurate pricing.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Window Costs</h3>
            <p className="text-gray-700">
              Window costs in the Lima/Dayton area vary based on several factors including window type, size, and material. Basic installations typically range from $300-800 per window, with premium or custom options costing more.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Energy efficiency ratings and potential savings</li>
              <li>Frame material durability and maintenance</li>
              <li>Glass options and UV protection</li>
              <li>Installation requirements</li>
              <li>Warranty coverage</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term energy savings when selecting windows.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}