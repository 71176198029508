import React from 'react';
import { Home } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="mt-8 border-t border-gray-200 pt-4">
      <div className="flex flex-col items-center space-y-2 text-sm text-gray-600">
        <Link to="/" className="hover:text-blue-600 transition">More Local Tools</Link>
        <button 
          onClick={() => navigate('/')} 
          className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 transition"
        >
          <Home className="w-4 h-4" />
          <span>Index</span>
        </button>
      </div>
    </div>
  );
}