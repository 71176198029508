import React, { useState } from 'react';
import { Wrench, Calculator, DollarSign, Clock, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type JobType = 'repair' | 'replacement' | 'installation';
type FixtureType = 'faucet' | 'toilet' | 'shower' | 'sink' | 'waterHeater' | 'pipe';

interface MaterialCosts {
  [key: string]: {
    economy: number;
    standard: number;
    premium: number;
  };
}

export default function PlumbingEstimator() {
  // State for job details
  const [jobType, setJobType] = useState<JobType>('repair');
  const [fixtureType, setFixtureType] = useState<FixtureType>('faucet');
  const [quality, setQuality] = useState<'economy' | 'standard' | 'premium'>('standard');
  const [quantity, setQuantity] = useState<string>('1');
  const [wireLength, setWireLength] = useState<string>('');
  const [emergency, setEmergency] = useState(false);
  const [weekend, setWeekend] = useState(false);

  // Current market rates (2024)
  const laborRates = {
    base: 95, // per hour
    emergency: 175, // per hour
    weekend: 142.50, // per hour (1.5x base)
    minimum: 175, // minimum service call
  };

  // Material costs by fixture type and quality
  const materialCosts: MaterialCosts = {
    faucet: {
      economy: 60,
      standard: 150,
      premium: 350
    },
    toilet: {
      economy: 150,
      standard: 250,
      premium: 500
    },
    shower: {
      economy: 200,
      standard: 400,
      premium: 800
    },
    sink: {
      economy: 80,
      standard: 200,
      premium: 450
    },
    waterHeater: {
      economy: 500,
      standard: 800,
      premium: 1500
    },
    pipe: { // per foot
      economy: 3,
      standard: 5,
      premium: 8
    }
  };

  // Replacement parts costs by fixture type
  const replacementPartsCosts = {
    faucet: {
      cartridge: 35,
      aerator: 15,
      handle: 25,
      supply_line: 20
    },
    toilet: {
      flapper: 12,
      fill_valve: 25,
      handle: 15,
      wax_ring: 10,
      supply_line: 20
    },
    shower: {
      cartridge: 45,
      head: 35,
      handle: 30,
      diverter: 40,
      stem: 50
    },
    sink: {
      p_trap: 25,
      drain: 30,
      strainer: 20,
      supply_line: 20
    },
    waterHeater: {
      thermocouple: 45,
      heating_element: 65,
      thermostat: 85,
      pressure_valve: 55,
      anode_rod: 40,
      gas_valve: 175,
      burner: 95
    },
    pipe: {
      coupling: 15,
      elbow: 18,
      valve: 45,
      union: 25
    }
  };

  // Demolition and disposal costs
  const removalCosts = {
    faucet: 45,
    toilet: 75,
    shower: 150,
    sink: 85,
    waterHeater: 200,
    pipe: 25 // per linear foot
  };

  // Time estimates in hours
  const timeEstimates = {
    repair: {
      faucet: 1,
      toilet: 1.5,
      shower: 2,
      sink: 1,
      waterHeater: 2,
      pipe: 0.5 // per 10 feet
    },
    replacement: {
      faucet: 1.5,
      toilet: 2,
      shower: 4,
      sink: 2,
      waterHeater: 4,
      pipe: 0.75 // per 10 feet
    },
    installation: {
      faucet: 2,
      toilet: 3,
      shower: 6,
      sink: 3,
      waterHeater: 6,
      pipe: 1 // per 10 feet
    }
  };

  const calculateEstimate = () => {
    if (!quantity) return null;

    // Calculate labor rate based on conditions
    let hourlyRate = laborRates.base;
    if (emergency) hourlyRate = laborRates.emergency;
    else if (weekend) hourlyRate = laborRates.weekend;

    // Calculate base time needed
    let hours = timeEstimates[jobType][fixtureType];
    const units = parseInt(quantity);
    
    // Adjust time for multiple units
    if (fixtureType !== 'pipe') {
      hours = hours * units;
    } else if (wireLength) {
      const length = parseFloat(wireLength);
      hours = hours * (length / 10);
    }

    // Calculate materials cost
    let materials = materialCosts[fixtureType][quality];
    if (fixtureType === 'pipe' && wireLength) {
      const length = parseFloat(wireLength);
      materials = materials * length;
    } else {
      materials = materials * units;
    }

    // Calculate replacement parts cost for repairs
    let partsCost = 0;
    if (jobType === 'repair') {
      const parts = replacementPartsCosts[fixtureType];
      // For repairs, assume 1-2 parts need replacement
      partsCost = Object.values(parts).slice(0, 2).reduce((sum, cost) => sum + cost, 0);
    }

    // Calculate removal cost for replacements
    let removalCost = 0;
    if (jobType === 'replacement') {
      if (fixtureType === 'pipe' && wireLength) {
        const length = parseFloat(wireLength);
        removalCost = removalCosts[fixtureType] * length;
      } else {
        removalCost = removalCosts[fixtureType] * units;
      }
    }

    // Calculate total labor cost (minimum service call applies)
    const laborCost = Math.max(laborRates.minimum, hours * hourlyRate);

    // Add permit fees for certain jobs
    const permitFee = (jobType === 'installation' || fixtureType === 'waterHeater') ? 175 : 0;

    // Calculate totals
    const subtotal = laborCost + materials + partsCost + removalCost;
    const tax = (materials + partsCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax + permitFee;

    return {
      labor: laborCost.toFixed(2),
      materials: materials.toFixed(2),
      parts: partsCost.toFixed(2),
      removal: removalCost.toFixed(2),
      tax: tax.toFixed(2),
      permit: permitFee.toFixed(2),
      total: total.toFixed(2),
      hours: hours.toFixed(1)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 to-blue-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Wrench className="w-8 h-8 text-blue-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Plumbing Estimator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Job Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['repair', 'replacement', 'installation'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setJobType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    jobType === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Type of Work</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['faucet', 'toilet', 'shower', 'sink', 'waterHeater', 'pipe'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFixtureType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    fixtureType === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'waterHeater' ? 'Water Heater' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Quantity</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
            />
          </div>

          {fixtureType === 'pipe' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Pipe Length (feet)</label>
              <input
                type="number"
                value={wireLength}
                onChange={(e) => setWireLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter length in feet"
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Material Quality</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setQuality(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    quality === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="flex space-x-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={emergency}
                onChange={(e) => {
                  setEmergency(e.target.checked);
                  if (e.target.checked) setWeekend(false);
                }}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <span>Emergency Service</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={weekend}
                onChange={(e) => {
                  setWeekend(e.target.checked);
                  if (e.target.checked) setEmergency(false);
                }}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <span>Weekend Service</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-blue-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Estimated Time:</span>
                  </div>
                  <span className="font-semibold">{results.hours} hours</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Materials:</span>
                    <span className="font-semibold">${results.materials}</span>
                  </div>
                  {parseFloat(results.parts) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Replacement Parts:</span>
                      <span className="font-semibold">${results.parts}</span>
                    </div>
                  )}
                  {parseFloat(results.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Removal/Disposal:</span>
                      <span className="font-semibold">${results.removal}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                  {parseFloat(results.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit Fee:</span>
                      <span className="font-semibold">${results.permit}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-blue-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-blue-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-blue-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Permit fees may be required and vary by municipality.</p>
                <p>* Some plumbing work may require licensed professionals by law.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}