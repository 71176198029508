import React from 'react';
import { Trash2 } from 'lucide-react';
import type { DoorConfig } from '../../types/doors';

interface DoorListProps {
  configs: DoorConfig[];
  onRemove: (id: string) => void;
}

export function DoorList({ configs, onRemove }: DoorListProps) {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-gray-800">Selected Doors</h3>
      <div className="space-y-2">
        {configs.map((config) => (
          <div key={config.id} className="bg-gray-50 p-4 rounded-lg flex justify-between items-center">
            <div>
              <h4 className="font-medium text-gray-900">
                {config.details.quantity}x {config.type} Door
              </h4>
              <p className="text-sm text-gray-600">
                {config.material} - {config.style} - {config.grade}
              </p>
              <p className="text-sm text-gray-500">
                {config.details.width}" × {config.details.height}"
              </p>
            </div>
            <button
              onClick={() => onRemove(config.id)}
              className="text-red-600 hover:text-red-700 p-2"
            >
              <Trash2 className="w-5 h-5" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}