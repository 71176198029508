import React, { useState } from 'react';
import CourseSetup from '../components/CourseSetup';
import PlayerSetup from '../components/PlayerSetup';
import ScoreCard from '../components/ScoreCard';

export default function GolfScore() {
  const [stage, setStage] = useState<'course' | 'players' | 'game'>('course');
  const [holePars, setHolePars] = useState(Array(18).fill(4));
  const [players, setPlayers] = useState([
    { name: '', scores: Array(18).fill(0) }
  ]);

  const handleHoleParsChange = (pars: number[]) => {
    setHolePars(pars);
  };

  const handleNameChange = (index: number, name: string) => {
    const newPlayers = [...players];
    newPlayers[index].name = name;
    setPlayers(newPlayers);
  };

  const handleAddPlayer = () => {
    if (players.length < 6) {
      setPlayers([...players, { name: '', scores: Array(18).fill(0) }]);
    }
  };

  const handleRemovePlayer = (index: number) => {
    const newPlayers = players.filter((_, i) => i !== index);
    setPlayers(newPlayers);
  };

  const handleScoreUpdate = (playerIndex: number, holeIndex: number, increment: boolean) => {
    const newPlayers = [...players];
    const currentScore = newPlayers[playerIndex].scores[holeIndex];
    newPlayers[playerIndex].scores[holeIndex] = increment ? currentScore + 1 : Math.max(0, currentScore - 1);
    setPlayers(newPlayers);
  };

  switch (stage) {
    case 'course':
      return (
        <CourseSetup
          holePars={holePars}
          onHoleParsChange={handleHoleParsChange}
          onComplete={() => setStage('players')}
        />
      );
    case 'players':
      return (
        <PlayerSetup
          players={players}
          onNameChange={handleNameChange}
          onAddPlayer={handleAddPlayer}
          onRemovePlayer={handleRemovePlayer}
          onStartGame={() => setStage('game')}
        />
      );
    case 'game':
      return (
        <ScoreCard
          players={players}
          holePars={holePars}
          onScoreUpdate={handleScoreUpdate}
        />
      );
  }
}