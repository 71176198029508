import React from 'react';
import type { DoorDetails } from '../../types/doors';

interface PaintOptionsProps {
  details: DoorDetails;
  onChange: (updates: Partial<DoorDetails>) => void;
}

const standardColors = [
  { name: 'Pure White', value: '#FFFFFF' },
  { name: 'Dove Gray', value: '#D3D3D3' },
  { name: 'Iron Black', value: '#2B2B2B' },
  { name: 'Naval Blue', value: '#1B365D' },
  { name: 'Forest Green', value: '#2C5530' },
  { name: 'Deep Red', value: '#8B0000' },
  { name: 'Bronze', value: '#614E1A' },
  { name: 'Almond', value: '#EFDECD' }
];

export function PaintOptions({ details, onChange }: PaintOptionsProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={details.prePainted ?? false}
            onChange={(e) => onChange({ prePainted: e.target.checked })}
            className="rounded text-amber-600 focus:ring-amber-500"
          />
          <span>Pre-Paint Door</span>
        </label>
      </div>

      {details.prePainted && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Paint Finish</label>
            <select
              value={details.paintFinish || 'none'}
              onChange={(e) => onChange({ paintFinish: e.target.value as DoorDetails['paintFinish'] })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
            >
              <option value="none">Select Finish</option>
              <option value="matte">Matte</option>
              <option value="satin">Satin</option>
              <option value="semi-gloss">Semi-Gloss</option>
              <option value="gloss">High Gloss</option>
            </select>
          </div>

          <div>
            <label className="flex items-center space-x-2 mb-2">
              <input
                type="checkbox"
                checked={details.customColor ?? false}
                onChange={(e) => onChange({ customColor: e.target.checked })}
                className="rounded text-amber-600 focus:ring-amber-500"
              />
              <span>Use Custom Color</span>
            </label>

            {details.customColor ? (
              <input
                type="text"
                value={details.paintColor || ''}
                onChange={(e) => onChange({ paintColor: e.target.value })}
                placeholder="Enter custom color code or name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
              />
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                {standardColors.map((color) => (
                  <button
                    key={color.value}
                    onClick={() => onChange({ paintColor: color.value })}
                    className={`flex items-center space-x-2 p-2 rounded-lg border ${
                      details.paintColor === color.value
                        ? 'border-amber-500 bg-amber-50'
                        : 'border-gray-200 hover:border-amber-300'
                    }`}
                  >
                    <div
                      className="w-6 h-6 rounded border border-gray-300"
                      style={{ backgroundColor: color.value }}
                    />
                    <span className="text-sm">{color.name}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}