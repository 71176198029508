import { DoorConfig } from '../types/doors';

// Base costs for different door types (2024 Lima/Dayton area rates)
const baseCosts = {
  entry: {
    economy: { steel: 250, wood: 350, fiberglass: 400, aluminum: 300, vinyl: 275 },
    standard: { steel: 450, wood: 650, fiberglass: 750, aluminum: 500, vinyl: 475 },
    premium: { steel: 850, wood: 1200, fiberglass: 1400, aluminum: 900, vinyl: 850 },
    custom: { steel: 1200, wood: 1800, fiberglass: 2000, aluminum: 1300, vinyl: 1200 }
  },
  interior: {
    economy: { steel: 150, wood: 200, fiberglass: 250, aluminum: 175, vinyl: 150 },
    standard: { steel: 250, wood: 350, fiberglass: 400, aluminum: 300, vinyl: 275 },
    premium: { steel: 450, wood: 600, fiberglass: 700, aluminum: 500, vinyl: 450 },
    custom: { steel: 650, wood: 900, fiberglass: 1000, aluminum: 700, vinyl: 650 }
  },
  garage: {
    economy: { steel: 750, wood: 1000, fiberglass: 1200, aluminum: 850, vinyl: 800 },
    standard: { steel: 1200, wood: 1600, fiberglass: 1800, aluminum: 1400, vinyl: 1300 },
    premium: { steel: 2000, wood: 2500, fiberglass: 2800, aluminum: 2200, vinyl: 2000 },
    custom: { steel: 2800, wood: 3500, fiberglass: 4000, aluminum: 3000, vinyl: 2800 }
  },
  storm: {
    economy: { steel: 175, wood: 225, fiberglass: 250, aluminum: 200, vinyl: 175 },
    standard: { steel: 275, wood: 350, fiberglass: 400, aluminum: 300, vinyl: 275 },
    premium: { steel: 450, wood: 600, fiberglass: 700, aluminum: 500, vinyl: 450 },
    custom: { steel: 650, wood: 800, fiberglass: 900, aluminum: 700, vinyl: 650 }
  },
  patio: {
    economy: { steel: 600, wood: 800, fiberglass: 900, aluminum: 700, vinyl: 650 },
    standard: { steel: 1000, wood: 1400, fiberglass: 1600, aluminum: 1200, vinyl: 1100 },
    premium: { steel: 1800, wood: 2200, fiberglass: 2500, aluminum: 2000, vinyl: 1800 },
    custom: { steel: 2500, wood: 3000, fiberglass: 3500, aluminum: 2800, vinyl: 2500 }
  }
};

// Installation costs by door type
const installationCosts = {
  entry: 350,
  interior: 200,
  garage: 600,
  storm: 150,
  patio: 450,
  custom: 500
};

// Paint costs and options
const paintCosts = {
  materials: {
    economy: 35,
    standard: 55,
    premium: 85
  },
  labor: {
    economy: 45,
    standard: 65,
    premium: 95
  },
  colors: {
    standard: 0,
    custom: 25,
    specialty: 50
  },
  finish: {
    matte: 0,
    satin: 10,
    gloss: 15,
    specialty: 35
  }
};

// Additional costs
const additionalCosts = {
  frame: {
    economy: 100,
    standard: 175,
    premium: 300,
    custom: 450
  },
  hardware: {
    economy: 50,
    standard: 125,
    premium: 250,
    custom: 400
  },
  structuralWork: {
    minor: 300,
    major: 800
  },
  disposal: 75,
  permit: 150
};

export function calculateDoorEstimate(configs: DoorConfig[]) {
  let totalCost = 0;
  const estimates = configs.map(config => {
    // Get base cost for door type, material and grade
    const baseCost = baseCosts[config.type][config.grade][config.material];
    
    // Calculate size multiplier
    const standardSize = config.type === 'garage' ? 7 * 9 : 3 * 7; // Standard sizes in sq ft
    const actualSize = (parseFloat(config.details.width) * parseFloat(config.details.height)) / 144;
    const sizeMultiplier = Math.max(1, actualSize / standardSize);

    // Calculate quantity costs
    const quantity = parseInt(config.details.quantity) || 1;
    const doorCost = baseCost * sizeMultiplier * quantity;

    // Calculate installation cost
    const installCost = installationCosts[config.type] * quantity;

    // Calculate paint costs if finishing is included
    let paintCost = 0;
    if (config.details.includeFinishing) {
      const surfaceArea = (actualSize * 2) * quantity; // Both sides
      const materialCost = paintCosts.materials[config.details.paintGrade || 'standard'];
      const laborCost = paintCosts.labor[config.details.paintGrade || 'standard'];
      const colorCost = paintCosts.colors[config.details.paintColor || 'standard'];
      const finishCost = paintCosts.finish[config.details.paintFinish || 'satin'];
      
      paintCost = (materialCost + laborCost) * surfaceArea + (colorCost + finishCost) * quantity;
    }

    // Calculate additional costs
    const frameCost = config.details.includeFrame ? additionalCosts.frame[config.grade] * quantity : 0;
    const hardwareCost = config.details.includeHardware ? additionalCosts.hardware[config.grade] * quantity : 0;
    const structuralCost = config.details.needsStructuralWork ? additionalCosts.structuralWork.major : 0;
    
    // Calculate total for this door configuration
    const subtotal = doorCost + installCost + paintCost + frameCost + hardwareCost + structuralCost;
    const tax = subtotal * 0.075; // 7.5% sales tax
    const total = subtotal + tax;

    totalCost += total;

    return {
      doorCost,
      installCost,
      paintCost,
      frameCost,
      hardwareCost,
      structuralCost,
      tax,
      total,
      details: {
        type: config.type,
        material: config.material,
        grade: config.grade,
        quantity,
        size: {
          width: config.details.width,
          height: config.details.height,
          area: actualSize.toFixed(2)
        }
      }
    };
  });

  return {
    estimates,
    totalCost
  };
}