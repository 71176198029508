import React, { useState } from 'react';
import { Thermometer, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type InsulationType = 'fiberglass' | 'cellulose' | 'spray-foam' | 'rigid-foam';
type LocationType = 'attic' | 'walls' | 'crawlspace' | 'basement';
type InstallMethod = 'batts' | 'blown' | 'spray' | 'board';

export default function InsulationCalculator() {
  // State for insulation details
  const [insulationType, setInsulationType] = useState<InsulationType>('fiberglass');
  const [locationType, setLocationType] = useState<LocationType>('attic');
  const [installMethod, setInstallMethod] = useState<InstallMethod>('batts');
  const [squareFeet, setSquareFeet] = useState('');
  const [depth, setDepth] = useState('');
  const [includeRemoval, setIncludeRemoval] = useState(false);
  const [includeVaporBarrier, setIncludeVaporBarrier] = useState(true);
  const [includeVenting, setIncludeVenting] = useState(false);
  const [includeAirSealing, setIncludeAirSealing] = useState(true);
  const [needsFraming, setNeedsFraming] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const materialCosts = {
    fiberglass: {
      batts: 1.25, // per sq ft
      blown: 1.50
    },
    cellulose: {
      blown: 1.35
    },
    'spray-foam': {
      spray: 3.50
    },
    'rigid-foam': {
      board: 2.75
    }
  };

  const laborRates = {
    batts: 1.50, // per sq ft
    blown: 1.75,
    spray: 2.00,
    board: 2.25
  };

  const additionalCosts = {
    removal: 1.25, // per sq ft
    vaporBarrier: 0.75,
    venting: {
      attic: 350, // flat rate
      crawlspace: 450
    },
    airSealing: 1.50, // per linear ft
    framing: 2.50, // per sq ft
    disposal: 250, // flat rate
    setup: 175 // equipment setup fee
  };

  // R-value requirements by location
  const rValueRequirements = {
    attic: 49, // R-49 recommended
    walls: 21,
    crawlspace: 19,
    basement: 15
  };

  // Calculate inches needed for desired R-value
  const calculateDepthNeeded = (type: InsulationType, location: LocationType) => {
    const rValue = rValueRequirements[location];
    const rPerInch = {
      fiberglass: 3.2,
      cellulose: 3.7,
      'spray-foam': 6.5,
      'rigid-foam': 5.0
    };
    return Math.ceil(rValue / rPerInch[type]);
  };

  const calculateEstimate = () => {
    const area = parseFloat(squareFeet);
    const customDepth = parseFloat(depth);
    if (!area) return null;

    // Calculate required depth if not specified
    const requiredDepth = customDepth || calculateDepthNeeded(insulationType, locationType);
    
    // Calculate base material and labor costs
    const material = materialCosts[insulationType][installMethod];
    const labor = laborRates[installMethod];
    const baseInsulationCost = area * (material + labor) * (requiredDepth / 3.5);

    // Calculate additional costs
    const removalCost = includeRemoval ? (area * additionalCosts.removal) : 0;
    const vaporBarrierCost = includeVaporBarrier ? (area * additionalCosts.vaporBarrier) : 0;
    const ventingCost = includeVenting ? additionalCosts.venting[locationType] || 0 : 0;
    
    // Calculate air sealing (assume 20% of area needs sealing)
    const sealingCost = includeAirSealing ? (area * 0.2 * additionalCosts.airSealing) : 0;
    
    // Calculate framing if needed
    const framingCost = needsFraming ? (area * additionalCosts.framing) : 0;

    // Add setup and disposal fees
    const setupCost = additionalCosts.setup;
    const disposalCost = includeRemoval ? additionalCosts.disposal : 0;

    // Calculate totals
    const subtotal = baseInsulationCost + removalCost + vaporBarrierCost + 
                    ventingCost + sealingCost + framingCost + setupCost + disposalCost;
    const tax = (baseInsulationCost + vaporBarrierCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    // Calculate energy savings
    const previousRValue = 11; // Assume R-11 for old insulation
    const newRValue = requiredDepth * {
      fiberglass: 3.2,
      cellulose: 3.7,
      'spray-foam': 6.5,
      'rigid-foam': 5.0
    }[insulationType];
    
    const savingsPercent = Math.min(50, Math.round(((newRValue - previousRValue) / previousRValue) * 100));
    const annualSavings = Math.round((savingsPercent / 100) * 450); // Average $450 annual heating/cooling cost

    return {
      costs: {
        insulation: baseInsulationCost.toFixed(2),
        removal: removalCost.toFixed(2),
        vapor: vaporBarrierCost.toFixed(2),
        venting: ventingCost.toFixed(2),
        sealing: sealingCost.toFixed(2),
        framing: framingCost.toFixed(2),
        setup: setupCost.toFixed(2),
        disposal: disposalCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      details: {
        rValue: newRValue.toFixed(1),
        depth: requiredDepth.toFixed(1),
        savingsPercent: savingsPercent,
        annualSavings: annualSavings
      }
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-500 to-orange-700 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Thermometer className="w-8 h-8 text-orange-500 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Insulation Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Insulation Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['fiberglass', 'cellulose', 'spray-foam', 'rigid-foam'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => {
                    setInsulationType(type);
                    // Update install method based on type
                    if (type === 'spray-foam') setInstallMethod('spray');
                    else if (type === 'rigid-foam') setInstallMethod('board');
                    else if (type === 'cellulose') setInstallMethod('blown');
                    else setInstallMethod('batts');
                  }}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    insulationType === type
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type.replace('-', ' ')}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Location</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['attic', 'walls', 'crawlspace', 'basement'] as const).map((location) => (
                <button
                  key={location}
                  onClick={() => setLocationType(location)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    locationType === location
                      ? 'bg-orange-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {location}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Area (square feet)</label>
              <input
                type="number"
                value={squareFeet}
                onChange={(e) => setSquareFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200"
                placeholder="Enter area"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Depth (inches, optional)</label>
              <input
                type="number"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200"
                placeholder="Leave blank for recommended"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeRemoval}
                onChange={(e) => setIncludeRemoval(e.target.checked)}
                className="rounded text-orange-500 focus:ring-orange-500"
              />
              <span>Remove Old Insulation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeVaporBarrier}
                onChange={(e) => setIncludeVaporBarrier(e.target.checked)}
                className="rounded text-orange-500 focus:ring-orange-500"
              />
              <span>Include Vapor Barrier</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeVenting}
                onChange={(e) => setIncludeVenting(e.target.checked)}
                className="rounded text-orange-500 focus:ring-orange-500"
              />
              <span>Include Ventilation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeAirSealing}
                onChange={(e) => setIncludeAirSealing(e.target.checked)}
                className="rounded text-orange-500 focus:ring-orange-500"
              />
              <span>Include Air Sealing</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsFraming}
                onChange={(e) => setNeedsFraming(e.target.checked)}
                className="rounded text-orange-500 focus:ring-orange-500"
              />
              <span>Needs Framing/Support</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-orange-500 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Recommended Depth:</span>
                  </div>
                  <span className="font-semibold">{results.details.depth} inches (R-{results.details.rValue})</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Insulation Materials & Labor:</span>
                    <span className="font-semibold">${results.costs.insulation}</span>
                  </div>
                  {parseFloat(results.costs.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Removal:</span>
                      <span className="font-semibold">${results.costs.removal}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.vapor) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Vapor Barrier:</span>
                      <span className="font-semibold">${results.costs.vapor}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.venting) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Ventilation:</span>
                      <span className="font-semibold">${results.costs.venting}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.sealing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Air Sealing:</span>
                      <span className="font-semibold">${results.costs.sealing}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.framing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Framing:</span>
                      <span className="font-semibold">${results.costs.framing}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Setup Fee:</span>
                    <span className="font-semibold">${results.costs.setup}</span>
                  </div>
                  {parseFloat(results.costs.disposal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Disposal Fee:</span>
                      <span className="font-semibold">${results.costs.disposal}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-orange-500 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-orange-500">${results.total}</span>
                </div>

                <div className="bg-green-50 p-4 rounded-lg mt-4">
                  <h3 className="font-semibold text-green-800 mb-2">Estimated Energy Savings</h3>
                  <div className="space-y-1">
                    <div className="flex justify-between text-green-700">
                      <span>Efficiency Improvement:</span>
                      <span className="font-semibold">{results.details.savingsPercent}%</span>
                    </div>
                    <div className="flex justify-between text-green-700">
                      <span>Estimated Annual Savings:</span>
                      <span className="font-semibold">${results.details.annualSavings}</span>
                    </div>
                    <div className="text-sm text-green-600 mt-2">
                      Payback Period: {Math.round(parseFloat(results.total) / results.details.annualSavings * 10) / 10} years
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-orange-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-orange-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-orange-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Energy savings estimates based on typical usage patterns and local climate data.</p>
                <p>* Professional inspection recommended for accurate R-value assessment.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Insulation Costs</h3>
            <p className="text-gray-700">
              Insulation costs in the Lima/Dayton area vary based on several factors including material type, location, and additional requirements. Most residential insulation projects range from $2,000 to $8,000, with potential energy savings offsetting costs over time.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Local climate and R-value requirements</li>
              <li>Existing insulation condition</li>
              <li>Accessibility and installation challenges</li>
              <li>Moisture control and ventilation needs</li>
              <li>Energy efficiency goals and budget</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and having a professional energy audit performed to identify specific needs and opportunities for improvement.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}