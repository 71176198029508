import React, { useState } from 'react';
import { Car, Calculator, DollarSign, Wrench, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

export default function AutoRepairCalculator() {
  const [make, setMake] = useState('');
  const [year, setYear] = useState('');
  const [zipCode, setZipCode] = useState('45865');
  const [repairType, setRepairType] = useState('');
  const [estimatedCost, setEstimatedCost] = useState<number | null>(null);

  const carMakes = [
    'Ford', 'Honda', 'Toyota', 'Chevrolet', 'Nissan',
    'Hyundai', 'Kia', 'BMW', 'Mercedes-Benz', 'Audi'
  ];

  const repairTypes = {
    // Engine-related repairs
    'Oil Change': { basePrice: 45, complexity: 1 },
    'Spark Plugs': { basePrice: 100, complexity: 2 },
    'Timing Belt': { basePrice: 500, complexity: 3 },
    'Engine Tune-Up': { basePrice: 300, complexity: 2 },
    'Head Gasket': { basePrice: 1200, complexity: 4 },
    'Engine Mount': { basePrice: 350, complexity: 3 },
    'Valve Cover Gasket': { basePrice: 200, complexity: 2 },
    'PCV Valve': { basePrice: 80, complexity: 1 },
    
    // Transmission-related repairs
    'Transmission Service': { basePrice: 200, complexity: 2 },
    'Transmission Fluid Change': { basePrice: 150, complexity: 1 },
    'Clutch Replacement': { basePrice: 800, complexity: 4 },
    'Transmission Mount': { basePrice: 300, complexity: 3 },
    'CV Axle': { basePrice: 350, complexity: 3 },
    'Differential Service': { basePrice: 150, complexity: 2 },
    
    // Brake system
    'Brake Pads': { basePrice: 150, complexity: 2 },
    'Brake Rotors': { basePrice: 300, complexity: 2 },
    'Brake Fluid Flush': { basePrice: 100, complexity: 1 },
    'Brake Line Replacement': { basePrice: 250, complexity: 3 },
    'Brake Caliper': { basePrice: 200, complexity: 2 },
    'Master Cylinder': { basePrice: 400, complexity: 3 },
    
    // Electrical system
    'Battery Replacement': { basePrice: 150, complexity: 1 },
    'Alternator': { basePrice: 400, complexity: 3 },
    'Starter': { basePrice: 350, complexity: 2 },
    'Ignition Coil': { basePrice: 200, complexity: 2 },
    'Mass Air Flow Sensor': { basePrice: 300, complexity: 2 },
    'Oxygen Sensor': { basePrice: 250, complexity: 2 },
    
    // Cooling system
    'Water Pump': { basePrice: 300, complexity: 3 },
    'Radiator Replacement': { basePrice: 400, complexity: 3 },
    'Thermostat': { basePrice: 150, complexity: 2 },
    'Coolant Flush': { basePrice: 100, complexity: 1 },
    'Radiator Hose': { basePrice: 150, complexity: 2 },
    'Heater Core': { basePrice: 800, complexity: 4 },
    
    // Fuel system
    'Fuel Pump': { basePrice: 450, complexity: 3 },
    'Fuel Filter': { basePrice: 100, complexity: 2 },
    'Fuel Injector Service': { basePrice: 250, complexity: 2 },
    'Carburetor Rebuild': { basePrice: 350, complexity: 3 },
    'Fuel Tank': { basePrice: 800, complexity: 4 },
    
    // Suspension and steering
    'Shock Absorbers': { basePrice: 400, complexity: 2 },
    'Struts': { basePrice: 500, complexity: 3 },
    'Ball Joints': { basePrice: 300, complexity: 3 },
    'Tie Rod Ends': { basePrice: 200, complexity: 2 },
    'Control Arms': { basePrice: 350, complexity: 3 },
    'Power Steering Pump': { basePrice: 400, complexity: 3 },
    'Steering Rack': { basePrice: 800, complexity: 4 },
    'Wheel Bearing': { basePrice: 250, complexity: 2 },
    'Alignment': { basePrice: 100, complexity: 1 },
    
    // Exhaust system
    'Catalytic Converter': { basePrice: 800, complexity: 3 },
    'Muffler Replacement': { basePrice: 200, complexity: 2 },
    'Exhaust Manifold': { basePrice: 500, complexity: 3 },
    'O2 Sensor': { basePrice: 200, complexity: 2 },
    
    // HVAC system
    'AC Recharge': { basePrice: 150, complexity: 1 },
    'AC Compressor': { basePrice: 700, complexity: 4 },
    'AC Condenser': { basePrice: 500, complexity: 3 },
    'Blower Motor': { basePrice: 300, complexity: 2 },
    'Heater Blower': { basePrice: 350, complexity: 2 }
  };

  const calculateAgeFactor = (yearMade: string) => {
    const currentYear = 2024;
    const age = currentYear - parseInt(yearMade);
    
    if (age <= 5) return 1.2; // Newer cars: higher parts cost
    if (age <= 10) return 1.0; // Standard pricing
    if (age <= 15) return 0.8; // Older cars: more abundant parts
    return 0.7; // Very old cars: cheapest parts
  };

  const calculateLocationFactor = (zip: string) => {
    const regionMap: { [key: string]: number } = {
      '4': 1.0,  // Midwest standard rate
      '1': 1.2,  // Northeast
      '2': 1.1,  // East Coast
      '3': 0.95, // Southeast
      '5': 0.9,  // South Central
      '6': 0.95, // Southwest
      '7': 1.0,  // Midwest
      '8': 1.05, // Mountain
      '9': 1.3,  // West Coast
      '0': 1.1   // Other
    };
    return regionMap[zip.charAt(0)] || 1.0;
  };

  const calculateMakeFactor = (carMake: string) => {
    const makeFactors: { [key: string]: number } = {
      'Ford': 0.95,
      'Honda': 1.0,
      'Toyota': 1.0,
      'Chevrolet': 0.95,
      'Nissan': 0.98,
      'Hyundai': 0.93,
      'Kia': 0.92,
      'BMW': 1.4,
      'Mercedes-Benz': 1.45,
      'Audi': 1.35
    };
    return makeFactors[carMake] || 1.0;
  };

  const calculateRepairCost = () => {
    if (!make || !year || !repairType) {
      return;
    }

    const repair = repairTypes[repairType as keyof typeof repairTypes];
    const ageFactor = calculateAgeFactor(year);
    const locationFactor = calculateLocationFactor(zipCode);
    const makeFactor = calculateMakeFactor(make);

    const cost = repair.basePrice * ageFactor * locationFactor * makeFactor;
    setEstimatedCost(Math.round(cost));
  };

  const currentYear = 2024;
  const years = Array.from(
    { length: 30 },
    (_, i) => (currentYear - i).toString()
  );

  // Group repair types by category for the dropdown
  const groupedRepairTypes = Object.entries(repairTypes).reduce((acc, [name, details]) => {
    const category = name.split(' ')[0];
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(name);
    return acc;
  }, {} as { [key: string]: string[] });

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-600 to-purple-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Car className="w-8 h-8 text-purple-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Auto Repair Calculator</h1>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Vehicle Make</label>
              <select
                className="w-full p-2 border rounded bg-white"
                value={make}
                onChange={(e) => setMake(e.target.value)}
              >
                <option value="">Select Make</option>
                {carMakes.map(make => (
                  <option key={make} value={make}>{make}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Vehicle Year</label>
              <select
                className="w-full p-2 border rounded bg-white"
                value={year}
                onChange={(e) => setYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Repair Type</label>
            <select
              className="w-full p-2 border rounded bg-white"
              value={repairType}
              onChange={(e) => setRepairType(e.target.value)}
            >
              <option value="">Select Repair Type</option>
              {Object.entries(groupedRepairTypes).map(([category, repairs]) => (
                <optgroup key={category} label={`${category} Repairs`}>
                  {repairs.map(repair => (
                    <option key={repair} value={repair}>{repair}</option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>

          <button 
            className={`w-full py-2 px-4 rounded-lg flex items-center justify-center space-x-2 ${
              !make || !year || !repairType
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-purple-600 hover:bg-purple-700 text-white'
            }`}
            onClick={calculateRepairCost}
            disabled={!make || !year || !repairType}
          >
            <Calculator className="w-4 h-4" />
            <span>Calculate Estimate</span>
          </button>

          {estimatedCost !== null && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
              <h3 className="text-lg font-semibold flex items-center gap-2">
                <DollarSign className="w-5 h-5" />
                Estimated Cost
              </h3>
              <p className="text-2xl font-bold text-green-600">
                ${estimatedCost}
              </p>
              <p className="text-sm text-gray-500 mt-2">
                *Estimate based on average prices in {zipCode} for {year} {make}
              </p>
            </div>
          )}

          <div className="mt-4 p-4 bg-yellow-50 rounded-lg">
            <div className="flex items-start gap-2">
              <AlertTriangle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-1" />
              <p className="text-sm text-gray-600">
                This calculator provides rough estimates based on average prices. 
                Actual costs may vary depending on specific vehicle conditions, 
                parts availability, and local labor rates.
              </p>
            </div>
          </div>
        </div>

        <Footer />
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}