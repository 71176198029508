import React, { useState } from 'react';
import { Droplet, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type SystemType = 'conventional' | 'aerobic' | 'mound' | 'chamber' | 'drip';
type SoilType = 'gravel' | 'sand' | 'loam' | 'clay';

export default function SepticCalculator() {
  // State for septic system details
  const [systemType, setSystemType] = useState<SystemType>('conventional');
  const [bedrooms, setBedrooms] = useState('3');
  const [bathrooms, setBathrooms] = useState('2');
  const [soilType, setSoilType] = useState<SoilType>('loam');
  const [lotSize, setLotSize] = useState('');
  const [depth, setDepth] = useState('');
  const [isReplacement, setIsReplacement] = useState(false);
  const [includeDesign, setIncludeDesign] = useState(true);
  const [needsPumpStation, setNeedsPumpStation] = useState(false);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const systemCosts = {
    conventional: {
      base: 6500,
      tankCost: 2500,
      fieldCost: 4000
    },
    aerobic: {
      base: 12000,
      tankCost: 6000,
      fieldCost: 5000
    },
    mound: {
      base: 15000,
      tankCost: 3000,
      fieldCost: 8000
    },
    chamber: {
      base: 8000,
      tankCost: 3000,
      fieldCost: 5000
    },
    drip: {
      base: 18000,
      tankCost: 4000,
      fieldCost: 10000
    }
  };

  // Additional services
  const additionalCosts = {
    design: 1200,
    permit: 500,
    soilTest: 400,
    pumpStation: 3500,
    removal: 2000,
    excavation: 85 // per cubic yard
  };

  const calculateEstimate = () => {
    const area = parseFloat(lotSize);
    const depthValue = parseFloat(depth);
    if (!area || !depthValue) return null;

    // Calculate base system cost
    const system = systemCosts[systemType];
    const bedroomCount = parseInt(bedrooms);
    const bathroomCount = parseFloat(bathrooms);
    
    // Adjust costs based on size
    const sizeMultiplier = 1 + ((bedroomCount - 3) * 0.15) + ((bathroomCount - 2) * 0.1);
    
    // Calculate main components
    const tankCost = system.tankCost * sizeMultiplier;
    const fieldCost = system.fieldCost * sizeMultiplier;
    const baseCost = system.base * sizeMultiplier;

    // Calculate excavation volume and cost
    const excavationVolume = area * depthValue / 27; // Convert to cubic yards
    const excavationCost = excavationVolume * additionalCosts.excavation;

    // Add additional costs
    const designCost = includeDesign ? additionalCosts.design : 0;
    const permitCost = includePermit ? additionalCosts.permit : 0;
    const pumpCost = needsPumpStation ? additionalCosts.pumpStation : 0;
    const removalCost = isReplacement ? additionalCosts.removal : 0;
    const soilTestCost = additionalCosts.soilTest;

    // Calculate totals
    const subtotal = baseCost + tankCost + fieldCost + excavationCost + 
                    designCost + permitCost + pumpCost + removalCost + soilTestCost;
    const tax = (tankCost + fieldCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      system: {
        tank: tankCost.toFixed(2),
        field: fieldCost.toFixed(2),
        base: baseCost.toFixed(2)
      },
      excavation: {
        volume: excavationVolume.toFixed(1),
        cost: excavationCost.toFixed(2)
      },
      additional: {
        design: designCost.toFixed(2),
        permit: permitCost.toFixed(2),
        pump: pumpCost.toFixed(2),
        removal: removalCost.toFixed(2),
        soilTest: soilTestCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-700 to-blue-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Droplet className="w-8 h-8 text-blue-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Septic System Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">System Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['conventional', 'aerobic', 'mound', 'chamber', 'drip'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setSystemType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    systemType === type
                      ? 'bg-blue-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Bedrooms</label>
              <select
                value={bedrooms}
                onChange={(e) => setBedrooms(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              >
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Bathrooms</label>
              <select
                value={bathrooms}
                onChange={(e) => setBathrooms(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              >
                {['1', '1.5', '2', '2.5', '3', '3.5', '4'].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Soil Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['gravel', 'sand', 'loam', 'clay'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setSoilType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    soilType === type
                      ? 'bg-blue-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Drain Field Size (sq ft)</label>
              <input
                type="number"
                value={lotSize}
                onChange={(e) => setLotSize(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter size"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Excavation Depth (feet)</label>
              <input
                type="number"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter depth"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isReplacement}
                onChange={(e) => setIsReplacement(e.target.checked)}
                className="rounded text-blue-700 focus:ring-blue-500"
              />
              <span>Replacing Existing System</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDesign}
                onChange={(e) => setIncludeDesign(e.target.checked)}
                className="rounded text-blue-700 focus:ring-blue-500"
              />
              <span>Include System Design</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsPumpStation}
                onChange={(e) => setNeedsPumpStation(e.target.checked)}
                className="rounded text-blue-700 focus:ring-blue-500"
              />
              <span>Requires Pump Station</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-blue-700 focus:ring-blue-500"
              />
              <span>Include Permit Fees</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-blue-700 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">System Size:</span>
                  </div>
                  <span className="font-semibold">{results.excavation.volume} cubic yards</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Tank System:</span>
                    <span className="font-semibold">${results.system.tank}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Drain Field:</span>
                    <span className="font-semibold">${results.system.field}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Installation:</span>
                    <span className="font-semibold">${results.system.base}</span>
                  </div>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Excavation:</span>
                    <span className="font-semibold">${results.excavation.cost}</span>
                  </div>
                  {parseFloat(results.additional.design) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">System Design:</span>
                      <span className="font-semibold">${results.additional.design}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.pump) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Pump Station:</span>
                      <span className="font-semibold">${results.additional.pump}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Old System Removal:</span>
                      <span className="font-semibold">${results.additional.removal}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Soil Testing:</span>
                    <span className="font-semibold">${results.additional.soilTest}</span>
                  </div>
                  {parseFloat(results.additional.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permits:</span>
                      <span className="font-semibold">${results.additional.permit}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-blue-700 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-blue-700">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-blue-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for difficult terrain or special requirements.</p>
                <p>* All septic systems require proper permits and inspections.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}