import React from 'react';
import { CircleDot, Save } from 'lucide-react';
import Footer from './Footer';

interface CourseSetupProps {
  holePars: number[];
  onHoleParsChange: (pars: number[]) => void;
  onComplete: () => void;
}

export default function CourseSetup({ holePars, onHoleParsChange, onComplete }: CourseSetupProps) {
  const updatePar = (index: number, value: string) => {
    const newPar = parseInt(value) || 3;
    const newPars = [...holePars];
    newPars[index] = Math.max(2, Math.min(6, newPar));
    onHoleParsChange(newPars);
  };

  const isValidSetup = holePars.every(par => par >= 2 && par <= 6);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-800 to-green-600 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <CircleDot className="w-8 h-8 text-green-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Golf Score</h1>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-700">Front Nine</h2>
            {holePars.slice(0, 9).map((par, index) => (
              <div key={index} className="flex items-center space-x-2">
                <label className="w-20">Hole {index + 1}:</label>
                <input
                  type="number"
                  min="2"
                  max="6"
                  value={par}
                  onChange={(e) => updatePar(index, e.target.value)}
                  className="w-20 p-2 border rounded focus:ring-2 focus:ring-green-500 focus:border-transparent"
                />
              </div>
            ))}
          </div>

          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-700">Back Nine</h2>
            {holePars.slice(9).map((par, index) => (
              <div key={index + 9} className="flex items-center space-x-2">
                <label className="w-20">Hole {index + 10}:</label>
                <input
                  type="number"
                  min="2"
                  max="6"
                  value={par}
                  onChange={(e) => updatePar(index + 9, e.target.value)}
                  className="w-20 p-2 border rounded focus:ring-2 focus:ring-green-500 focus:border-transparent"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
          <div className="text-center space-y-2">
            <div className="font-semibold">
              Front Nine Total: {holePars.slice(0, 9).reduce((a, b) => a + b, 0)}
            </div>
            <div className="font-semibold">
              Back Nine Total: {holePars.slice(9).reduce((a, b) => a + b, 0)}
            </div>
            <div className="text-lg font-bold">
              Course Total: {holePars.reduce((a, b) => a + b, 0)}
            </div>
          </div>
        </div>

        <button
          onClick={onComplete}
          disabled={!isValidSetup}
          className={`mt-6 w-full py-2 px-4 rounded flex items-center justify-center space-x-2
            ${isValidSetup 
              ? 'bg-green-600 hover:bg-green-700 text-white' 
              : 'bg-gray-300 cursor-not-allowed text-gray-500'}`}
        >
          <Save className="w-5 h-5" />
          <span>Continue to Player Setup</span>
        </button>

        <Footer />
      </div>
    </div>
  );
}