import React, { useState } from 'react';
import { Layers, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type SidingType = 'vinyl' | 'fiber-cement' | 'wood' | 'metal' | 'stucco';
type QualityGrade = 'economy' | 'standard' | 'premium';

export default function SidingCalculator() {
  // State for siding details
  const [sidingType, setSidingType] = useState<SidingType>('vinyl');
  const [qualityGrade, setQualityGrade] = useState<QualityGrade>('standard');
  const [houseLength, setHouseLength] = useState('');
  const [houseWidth, setHouseWidth] = useState('');
  const [houseHeight, setHouseHeight] = useState('');
  const [stories, setStories] = useState('1');
  const [includeInsulation, setIncludeInsulation] = useState(true);
  const [includeGutters, setIncludeGutters] = useState(true);
  const [includeTrim, setIncludeTrim] = useState(true);
  const [needsRemoval, setNeedsRemoval] = useState(false);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const sidingCosts = {
    vinyl: {
      economy: 4.50, // per sq ft
      standard: 6.50,
      premium: 8.50
    },
    'fiber-cement': {
      economy: 9.50,
      standard: 11.50,
      premium: 13.50
    },
    wood: {
      economy: 8.00,
      standard: 10.00,
      premium: 12.00
    },
    metal: {
      economy: 7.50,
      standard: 9.50,
      premium: 11.50
    },
    stucco: {
      economy: 8.50,
      standard: 10.50,
      premium: 12.50
    }
  };

  const laborRates = {
    vinyl: 3.50, // per sq ft
    'fiber-cement': 5.50,
    wood: 4.50,
    metal: 4.50,
    stucco: 6.50
  };

  const additionalCosts = {
    insulation: 2.25, // per sq ft
    gutters: 8.50, // per linear ft
    trim: {
      vinyl: 4.50, // per linear ft
      wood: 6.50,
      metal: 5.50
    },
    removal: 1.75, // per sq ft
    disposal: 450, // flat fee
    permit: 350,
    scaffolding: 2.50 // per sq ft
  };

  const calculateEstimate = () => {
    const length = parseFloat(houseLength);
    const width = parseFloat(houseWidth);
    const height = parseFloat(houseHeight);
    if (!length || !width || !height) return null;

    // Calculate total wall area
    const perimeter = 2 * (length + width);
    const storyCount = parseInt(stories);
    const totalHeight = height * storyCount;
    const wallArea = perimeter * totalHeight;
    
    // Subtract for openings (windows/doors) - typically 20%
    const netWallArea = wallArea * 0.8;

    // Calculate base siding and labor costs
    const sidingMaterialCost = netWallArea * sidingCosts[sidingType][qualityGrade];
    const laborCost = netWallArea * laborRates[sidingType];

    // Calculate additional feature costs
    const insulationCost = includeInsulation ? (netWallArea * additionalCosts.insulation) : 0;
    const gutterCost = includeGutters ? (perimeter * additionalCosts.gutters) : 0;
    const trimCost = includeTrim ? 
      (perimeter * additionalCosts.trim[sidingType === 'stucco' ? 'wood' : sidingType]) : 0;

    // Calculate removal and disposal costs
    const removalCost = needsRemoval ? 
      (netWallArea * additionalCosts.removal + additionalCosts.disposal) : 0;

    // Calculate scaffolding cost for multi-story
    const scaffoldingCost = storyCount > 1 ? (wallArea * additionalCosts.scaffolding) : 0;

    // Add permit fee if included
    const permitCost = includePermit ? additionalCosts.permit : 0;

    // Calculate totals
    const subtotal = sidingMaterialCost + laborCost + insulationCost + gutterCost +
                    trimCost + removalCost + scaffoldingCost + permitCost;
    const tax = sidingMaterialCost * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        wall: wallArea.toFixed(0),
        net: netWallArea.toFixed(0),
        perimeter: perimeter.toFixed(0)
      },
      costs: {
        materials: sidingMaterialCost.toFixed(2),
        labor: laborCost.toFixed(2),
        insulation: insulationCost.toFixed(2),
        gutters: gutterCost.toFixed(2),
        trim: trimCost.toFixed(2),
        removal: removalCost.toFixed(2),
        scaffolding: scaffoldingCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 to-indigo-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Layers className="w-8 h-8 text-indigo-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Siding Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Siding Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['vinyl', 'fiber-cement', 'wood', 'metal', 'stucco'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setSidingType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    sidingType === type
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'fiber-cement' ? 'Fiber Cement' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Quality Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setQualityGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    qualityGrade === grade
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={houseLength}
                onChange={(e) => setHouseLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                placeholder="Length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={houseWidth}
                onChange={(e) => setHouseWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                placeholder="Width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Wall Height (feet)</label>
              <input
                type="number"
                value={houseHeight}
                onChange={(e) => setHouseHeight(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                placeholder="Height"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Stories</label>
              <select
                value={stories}
                onChange={(e) => setStories(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200"
              >
                {['1', '2', '3'].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeInsulation}
                onChange={(e) => setIncludeInsulation(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Insulation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeGutters}
                onChange={(e) => setIncludeGutters(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include New Gutters</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeTrim}
                onChange={(e) => setIncludeTrim(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Trim Work</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsRemoval}
                onChange={(e) => setNeedsRemoval(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Old Siding Removal Needed</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Permit</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-indigo-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Wall Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.net} sq ft (net)</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Siding Materials:</span>
                    <span className="font-semibold">${results.costs.materials}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.costs.labor}</span>
                  </div>
                  {parseFloat(results.costs.insulation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Insulation:</span>
                      <span className="font-semibold">${results.costs.insulation}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.gutters) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Gutters:</span>
                      <span className="font-semibold">${results.costs.gutters}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.trim) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Trim Work:</span>
                      <span className="font-semibold">${results.costs.trim}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Removal & Disposal:</span>
                      <span className="font-semibold">${results.costs.removal}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.scaffolding) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Scaffolding:</span>
                      <span className="font-semibold">${results.costs.scaffolding}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-indigo-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-indigo-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-indigo-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-indigo-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-indigo-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs or special requirements.</p>
                <p>* Some siding projects may require permits depending on scope.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Siding Costs</h3>
            <p className="text-gray-700">
              Siding costs in the Lima/Dayton area vary based on several factors including material choice, home size, and additional features. Basic vinyl siding typically ranges from $4-9 per square foot installed, while premium materials like fiber cement can range from $9-14 per square foot.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Material durability and maintenance requirements</li>
              <li>Energy efficiency and insulation needs</li>
              <li>Home style and architectural features</li>
              <li>Local climate conditions</li>
              <li>Installation complexity</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term value when selecting materials.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}