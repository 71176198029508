import React, { useState } from 'react';
import { Building2, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type FinishType = 'standard' | 'premium' | 'luxury';
type WallType = 'drywall' | 'panel' | 'custom';
type FloorType = 'carpet' | 'vinyl' | 'tile' | 'hardwood';

export default function BasementCalculator() {
  // State for basement details
  const [finishType, setFinishType] = useState<FinishType>('standard');
  const [wallType, setWallType] = useState<WallType>('drywall');
  const [floorType, setFloorType] = useState<FloorType>('carpet');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('8');
  const [includeEgress, setIncludeEgress] = useState(true);
  const [includeBathroom, setIncludeBathroom] = useState(false);
  const [includeKitchenette, setIncludeKitchenette] = useState(false);
  const [needsWaterproofing, setNeedsWaterproofing] = useState(false);
  const [includeHVAC, setIncludeHVAC] = useState(true);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    standard: 32, // per sq ft
    premium: 45,
    luxury: 65
  };

  const wallCosts = {
    drywall: 4.50, // per sq ft
    panel: 6.00,
    custom: 8.50
  };

  const floorCosts = {
    carpet: 4.75,
    vinyl: 6.50,
    tile: 12.00,
    hardwood: 14.00
  };

  const additionalCosts = {
    egress: {
      window: 2500,
      installation: 1500
    },
    bathroom: {
      half: 5500,
      full: 8500
    },
    kitchenette: 7500,
    waterproofing: {
      basic: 15, // per linear ft
      advanced: 25
    },
    hvac: {
      extend: 2500,
      new: 4500
    },
    electrical: {
      basic: 2800,
      upgraded: 4200
    },
    permit: 850,
    inspection: 350
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const h = parseFloat(height);
    if (!l || !w) return null;

    // Calculate areas
    const floorArea = l * w;
    const wallArea = 2 * (l + w) * h;
    const perimeterLength = 2 * (l + w);
    
    // Calculate base finishing cost
    const baseCost = floorArea * baseCosts[finishType];
    
    // Calculate wall and flooring costs
    const wallCost = wallArea * wallCosts[wallType];
    const floorCost = floorArea * floorCosts[floorType];

    // Calculate additional features
    const egressCost = includeEgress ? 
      (additionalCosts.egress.window + additionalCosts.egress.installation) : 0;

    const bathroomCost = includeBathroom ?
      additionalCosts.bathroom.full : 0;

    const kitchenetteCost = includeKitchenette ?
      additionalCosts.kitchenette : 0;

    const waterproofingCost = needsWaterproofing ?
      (perimeterLength * additionalCosts.waterproofing.advanced) : 0;

    const hvacCost = includeHVAC ?
      additionalCosts.hvac.extend : 0;

    // Calculate electrical based on features
    const electricalCost = (includeBathroom || includeKitchenette) ?
      additionalCosts.electrical.upgraded : additionalCosts.electrical.basic;

    // Calculate permit and inspection costs
    const permitCost = includePermit ?
      (additionalCosts.permit + additionalCosts.inspection) : 0;

    // Calculate totals
    const subtotal = baseCost + wallCost + floorCost + egressCost + bathroomCost +
                    kitchenetteCost + waterproofingCost + hvacCost + electricalCost + permitCost;
    const tax = (wallCost + floorCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        floor: floorArea.toFixed(0),
        walls: wallArea.toFixed(0)
      },
      costs: {
        base: baseCost.toFixed(2),
        walls: wallCost.toFixed(2),
        flooring: floorCost.toFixed(2),
        egress: egressCost.toFixed(2),
        bathroom: bathroomCost.toFixed(2),
        kitchenette: kitchenetteCost.toFixed(2),
        waterproofing: waterproofingCost.toFixed(2),
        hvac: hvacCost.toFixed(2),
        electrical: electricalCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-stone-600 to-stone-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Building2 className="w-8 h-8 text-stone-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Basement Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Finish Level</label>
            <div className="grid grid-cols-3 gap-2">
              {(['standard', 'premium', 'luxury'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFinishType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    finishType === type
                      ? 'bg-stone-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Wall Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['drywall', 'panel', 'custom'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setWallType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    wallType === type
                      ? 'bg-stone-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Floor Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['carpet', 'vinyl', 'tile', 'hardwood'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFloorType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    floorType === type
                      ? 'bg-stone-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring focus:ring-stone-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring focus:ring-stone-200"
                placeholder="Enter width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Height (feet)</label>
              <select
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring focus:ring-stone-200"
              >
                {['7', '8', '9', '10'].map((h) => (
                  <option key={h} value={h}>{h} ft</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeEgress}
                onChange={(e) => setIncludeEgress(e.target.checked)}
                className="rounded text-stone-600 focus:ring-stone-500"
              />
              <span>Include Egress Window</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBathroom}
                onChange={(e) => setIncludeBathroom(e.target.checked)}
                className="rounded text-stone-600 focus:ring-stone-500"
              />
              <span>Include Full Bathroom</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeKitchenette}
                onChange={(e) => setIncludeKitchenette(e.target.checked)}
                className="rounded text-stone-600 focus:ring-stone-500"
              />
              <span>Include Kitchenette</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsWaterproofing}
                onChange={(e) => setNeedsWaterproofing(e.target.checked)}
                className="rounded text-stone-600 focus:ring-stone-500"
              />
              <span>Needs Waterproofing</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeHVAC}
                onChange={(e) => setIncludeHVAC(e.target.checked)}
                className="rounded text-stone-600 focus:ring-stone-500"
              />
              <span>Include HVAC</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-stone-600 focus:ring-stone-500"
              />
              <span>Include Permit & Inspection</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-stone-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Floor Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.floor} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Finishing:</span>
                    <span className="font-semibold">${results.costs.base}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Wall Finishing:</span>
                    <span className="font-semibold">${results.costs.walls}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Flooring:</span>
                    <span className="font-semibold">${results.costs.flooring}</span>
                  </div>
                  {parseFloat(results.costs.egress) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Egress Window:</span>
                      <span className="font-semibold">${results.costs.egress}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.bathroom) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Bathroom:</span>
                      <span className="font-semibold">${results.costs.bathroom}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.kitchenette) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Kitchenette:</span>
                      <span className="font-semibold">${results.costs.kitchenette}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.waterproofing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Waterproofing:</span>
                      <span className="font-semibold">${results.costs.waterproofing}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.hvac) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">HVAC:</span>
                      <span className="font-semibold">${results.costs.hvac}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Electrical:</span>
                    <span className="font-semibold">${results.costs.electrical}</span>
                  </div>
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permits & Inspection:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-stone-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-stone-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-stone-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-stone-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-stone-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs or special requirements.</p>
                <p>* All finished basements require proper permits and inspections.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Basement Finishing Costs</h3>
            <p className="text-gray-700">
              Basement finishing costs in the Lima/Dayton area vary based on several factors including size, finish level, and additional features. Basic finishing typically ranges from $25,000 to $75,000, with luxury finishes and additional rooms increasing costs.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Moisture control and waterproofing needs</li>
              <li>Local building codes and egress requirements</li>
              <li>HVAC and electrical system capacity</li>
              <li>Ceiling height and structural considerations</li>
              <li>Intended use and required amenities</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and having a professional inspection to identify any potential issues before starting the project.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}