import React, { useState } from 'react';
import { Construction, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ProjectType = 'driveway' | 'parking' | 'road' | 'patch';
type AsphaltType = 'standard' | 'premium' | 'recycled';
type BaseType = 'gravel' | 'concrete' | 'existing';

export default function AsphaltCalculator() {
  // State for asphalt details
  const [projectType, setProjectType] = useState<ProjectType>('driveway');
  const [asphaltType, setAsphaltType] = useState<AsphaltType>('standard');
  const [baseType, setBaseType] = useState<BaseType>('gravel');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [depth, setDepth] = useState('2');
  const [includeBase, setIncludeBase] = useState(true);
  const [includeGrading, setIncludeGrading] = useState(true);
  const [includeEdging, setIncludeEdging] = useState(true);
  const [needsRemoval, setNeedsRemoval] = useState(false);
  const [includeSealing, setIncludeSealing] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const asphaltCosts = {
    standard: 3.25, // per sq ft per inch depth
    premium: 4.50,
    recycled: 2.75
  };

  const baseCosts = {
    gravel: 1.75, // per sq ft per inch
    concrete: 2.25,
    existing: 0.50 // repair/preparation only
  };

  const additionalCosts = {
    grading: 1.25, // per sq ft
    edging: 4.50, // per linear ft
    removal: {
      asphalt: 2.50, // per sq ft
      concrete: 3.50
    },
    sealing: 0.45, // per sq ft
    disposal: 350, // flat fee
    mobilization: 450 // equipment setup
  };

  // Project type multipliers
  const projectMultipliers = {
    driveway: 1.0,
    parking: 0.9, // economies of scale
    road: 0.85,
    patch: 1.2 // smaller area premium
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const d = parseFloat(depth);
    if (!l || !w) return null;

    // Calculate areas
    const area = l * w;
    const perimeter = 2 * (l + w);
    
    // Calculate base asphalt cost
    const asphaltCost = area * d * asphaltCosts[asphaltType] * projectMultipliers[projectType];

    // Calculate base preparation costs
    const baseCost = includeBase ? (area * 4 * baseCosts[baseType]) : 0; // 4 inch base standard

    // Calculate additional feature costs
    const gradingCost = includeGrading ? (area * additionalCosts.grading) : 0;
    const edgingCost = includeEdging ? (perimeter * additionalCosts.edging) : 0;
    
    // Calculate removal costs if needed
    const removalCost = needsRemoval ? 
      (area * additionalCosts.removal[baseType === 'concrete' ? 'concrete' : 'asphalt'] + 
       additionalCosts.disposal) : 0;

    // Calculate sealing cost
    const sealingCost = includeSealing ? (area * additionalCosts.sealing) : 0;

    // Add mobilization cost
    const mobilizationCost = additionalCosts.mobilization;

    // Calculate totals
    const subtotal = asphaltCost + baseCost + gradingCost + edgingCost + 
                    removalCost + sealingCost + mobilizationCost;
    const tax = asphaltCost * 0.075; // 7.5% sales tax on materials only
    const total = subtotal + tax;

    return {
      dimensions: {
        area: area.toFixed(0),
        perimeter: perimeter.toFixed(0)
      },
      costs: {
        asphalt: asphaltCost.toFixed(2),
        base: baseCost.toFixed(2),
        grading: gradingCost.toFixed(2),
        edging: edgingCost.toFixed(2),
        removal: removalCost.toFixed(2),
        sealing: sealingCost.toFixed(2),
        mobilization: mobilizationCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      perSqFt: (total / area).toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-700 to-gray-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Construction className="w-8 h-8 text-gray-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Asphalt Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Project Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['driveway', 'parking', 'road', 'patch'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setProjectType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    projectType === type
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Asphalt Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['standard', 'premium', 'recycled'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setAsphaltType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    asphaltType === type
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Base Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['gravel', 'concrete', 'existing'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setBaseType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    baseType === type
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200"
                placeholder="Enter width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Depth (inches)</label>
              <select
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200"
              >
                {['2', '3', '4', '5', '6'].map((d) => (
                  <option key={d} value={d}>{d}"</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBase}
                onChange={(e) => setIncludeBase(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Base Preparation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeGrading}
                onChange={(e) => setIncludeGrading(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Grading</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeEdging}
                onChange={(e) => setIncludeEdging(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Edging</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsRemoval}
                onChange={(e) => setNeedsRemoval(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Needs Removal</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeSealing}
                onChange={(e) => setIncludeSealing(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Sealing</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-gray-700 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.area} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Asphalt:</span>
                    <span className="font-semibold">${results.costs.asphalt}</span>
                  </div>
                  {parseFloat(results.costs.base) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Base Preparation:</span>
                      <span className="font-semibold">${results.costs.base}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.grading) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Grading:</span>
                      <span className="font-semibold">${results.costs.grading}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.edging) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Edging:</span>
                      <span className="font-semibold">${results.costs.edging}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Removal & Disposal:</span>
                      <span className="font-semibold">${results.costs.removal}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.sealing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Sealing:</span>
                      <span className="font-semibold">${results.costs.sealing}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Mobilization:</span>
                    <span className="font-semibold">${results.costs.mobilization}</span>
                  </div>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <DollarSign className="w-5 h-5 text-gray-700 mr-2" />
                      <span className="font-bold">Total Estimate:</span>
                    </div>
                    <span className="text-2xl font-bold text-gray-700">${results.total}</span>
                  </div>
                  <div className="text-sm text-gray-600 text-center">
                    Cost per Square Foot: ${results.perSqFt}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-gray-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-gray-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-gray-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for drainage, permits, or special requirements.</p>
                <p>* Professional site evaluation recommended for accurate pricing.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}