import React, { useState } from 'react';
import { Wrench, Calculator, DollarSign, Clock, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ServiceCategory = 'plumbing' | 'electrical' | 'carpentry' | 'drywall' | 'painting' | 'general';
type TimeBlock = '1' | '2' | '4' | '8';
type ServiceLevel = 'basic' | 'intermediate' | 'complex';

export default function HandymanCalculator() {
  // State for service details
  const [category, setCategory] = useState<ServiceCategory>('general');
  const [timeNeeded, setTimeNeeded] = useState<TimeBlock>('2');
  const [complexity, setComplexity] = useState<ServiceLevel>('basic');
  const [isEmergency, setIsEmergency] = useState(false);
  const [isWeekend, setIsWeekend] = useState(false);
  const [includeMaterials, setIncludeMaterials] = useState(true);
  const [needsDisposal, setNeedsDisposal] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const hourlyRates = {
    plumbing: {
      basic: 75,
      intermediate: 85,
      complex: 95
    },
    electrical: {
      basic: 75,
      intermediate: 85,
      complex: 95
    },
    carpentry: {
      basic: 65,
      intermediate: 75,
      complex: 85
    },
    drywall: {
      basic: 60,
      intermediate: 70,
      complex: 80
    },
    painting: {
      basic: 55,
      intermediate: 65,
      complex: 75
    },
    general: {
      basic: 65,
      intermediate: 75,
      complex: 85
    }
  };

  // Common material costs
  const materialCosts = {
    plumbing: {
      basic: 35, // faucet repair kit, etc.
      intermediate: 85, // replacement parts
      complex: 150 // fixtures, etc.
    },
    electrical: {
      basic: 25, // outlets, switches
      intermediate: 75, // fixtures
      complex: 150 // panels, heavy duty
    },
    carpentry: {
      basic: 45, // hardware, small lumber
      intermediate: 95, // doors, trim
      complex: 175 // cabinets, built-ins
    },
    drywall: {
      basic: 35, // patch kits
      intermediate: 75, // sheets and mud
      complex: 125 // multiple sheets, texture
    },
    painting: {
      basic: 45, // single room paint
      intermediate: 95, // multiple colors
      complex: 175 // specialty finishes
    },
    general: {
      basic: 35,
      intermediate: 75,
      complex: 125
    }
  };

  // Additional fees
  const additionalFees = {
    emergency: 1.5, // 50% premium
    weekend: 1.25, // 25% premium
    disposal: 75, // flat fee
    trip: 45, // minimum trip charge
    supplies: 25 // basic supplies charge
  };

  const calculateEstimate = () => {
    // Get base hourly rate
    const baseRate = hourlyRates[category][complexity];
    const hours = parseInt(timeNeeded);
    
    // Apply emergency or weekend rates
    let adjustedRate = baseRate;
    if (isEmergency) {
      adjustedRate *= additionalFees.emergency;
    } else if (isWeekend) {
      adjustedRate *= additionalFees.weekend;
    }

    // Calculate labor cost
    const laborCost = Math.max(adjustedRate * hours, additionalFees.trip);

    // Calculate material costs if included
    const materialCost = includeMaterials ? materialCosts[category][complexity] : 0;

    // Add disposal fee if needed
    const disposalCost = needsDisposal ? additionalFees.disposal : 0;

    // Add basic supplies
    const suppliesCost = additionalFees.supplies;

    // Calculate totals
    const subtotal = laborCost + materialCost + disposalCost + suppliesCost;
    const tax = materialCost * 0.075; // 7.5% sales tax on materials only
    const total = subtotal + tax;

    return {
      labor: laborCost.toFixed(2),
      materials: materialCost.toFixed(2),
      disposal: disposalCost.toFixed(2),
      supplies: suppliesCost.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      hours: hours.toString()
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-600 to-indigo-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Wrench className="w-8 h-8 text-indigo-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Handyman Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['plumbing', 'electrical', 'carpentry', 'drywall', 'painting', 'general'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCategory(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    category === type
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Job Complexity</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'intermediate', 'complex'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setComplexity(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    complexity === level
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Estimated Time (hours)</label>
            <div className="grid grid-cols-4 gap-2">
              {(['1', '2', '4', '8'] as const).map((time) => (
                <button
                  key={time}
                  onClick={() => setTimeNeeded(time)}
                  className={`py-2 px-4 rounded-lg ${
                    timeNeeded === time
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {time}hr
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isEmergency}
                onChange={(e) => {
                  setIsEmergency(e.target.checked);
                  if (e.target.checked) setIsWeekend(false);
                }}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Emergency Service (50% Premium)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isWeekend}
                onChange={(e) => {
                  setIsWeekend(e.target.checked);
                  if (e.target.checked) setIsEmergency(false);
                }}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Weekend Service (25% Premium)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeMaterials}
                onChange={(e) => setIncludeMaterials(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Common Materials</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsDisposal}
                onChange={(e) => setNeedsDisposal(e.target.checked)}
                className="rounded text-indigo-600 focus:ring-indigo-500"
              />
              <span>Include Disposal Fee</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-indigo-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Time Estimate:</span>
                  </div>
                  <span className="font-semibold">{results.hours} hours</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  {parseFloat(results.materials) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Materials:</span>
                      <span className="font-semibold">${results.materials}</span>
                    </div>
                  )}
                  {parseFloat(results.disposal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Disposal:</span>
                      <span className="font-semibold">${results.disposal}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Basic Supplies:</span>
                    <span className="font-semibold">${results.supplies}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-indigo-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-indigo-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-indigo-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-indigo-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-indigo-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for specialty materials or complex repairs.</p>
                <p>* Some services may require licensed professionals by law.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Common Services by Category</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <h4 className="font-semibold text-gray-800">Plumbing</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Faucet repair/replacement</li>
                  <li>Toilet repair</li>
                  <li>Drain cleaning</li>
                  <li>Minor pipe repairs</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">Electrical</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Switch/outlet replacement</li>
                  <li>Light fixture installation</li>
                  <li>Fan installation</li>
                  <li>Minor wiring repairs</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">Carpentry</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Door repair/installation</li>
                  <li>Trim work</li>
                  <li>Cabinet repairs</li>
                  <li>Basic framing</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">General</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Furniture assembly</li>
                  <li>TV mounting</li>
                  <li>Weatherization</li>
                  <li>Basic repairs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}