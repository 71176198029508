import React, { useState } from 'react';
import { Calculator, DollarSign, TrendingUp, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

export default function MortgageStrategyCalculator() {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('30');
  const [extraPayment, setExtraPayment] = useState('0');
  const [currentPaymentsMade, setCurrentPaymentsMade] = useState('0');

  const calculateMortgage = () => {
    const principal = parseFloat(loanAmount);
    const annualRate = parseFloat(interestRate) / 100;
    const monthlyRate = annualRate / 12;
    const months = parseInt(loanTerm) * 12;
    const extra = parseFloat(extraPayment);
    const paymentsMade = parseInt(currentPaymentsMade);

    if (!principal || !annualRate) return null;

    // Calculate base monthly payment
    const monthlyPayment = principal * 
      (monthlyRate * Math.pow(1 + monthlyRate, months)) / 
      (Math.pow(1 + monthlyRate, months) - 1);

    // Calculate amortization schedule
    let balance = principal;
    let totalInterest = 0;
    let monthsToPayoff = months;
    let totalWithExtra = 0;
    let interestSaved = 0;
    let regularPaymentSchedule = [];
    let extraPaymentSchedule = [];

    // Regular payment schedule
    for (let i = 1; i <= months; i++) {
      const interestPayment = balance * monthlyRate;
      const principalPayment = monthlyPayment - interestPayment;
      balance -= principalPayment;
      totalInterest += interestPayment;

      if (i % 12 === 0) {
        regularPaymentSchedule.push({
          year: i / 12,
          balance: balance,
          totalInterest: totalInterest
        });
      }
    }

    // Reset for extra payment calculation
    balance = principal;
    let extraTotalInterest = 0;
    let extraMonthsToPayoff = 0;

    // Extra payment schedule
    while (balance > 0) {
      const interestPayment = balance * monthlyRate;
      const principalPayment = monthlyPayment - interestPayment + extra;
      balance = Math.max(0, balance - principalPayment);
      extraTotalInterest += interestPayment;
      extraMonthsToPayoff++;

      if (extraMonthsToPayoff % 12 === 0) {
        extraPaymentSchedule.push({
          year: extraMonthsToPayoff / 12,
          balance: balance,
          totalInterest: extraTotalInterest
        });
      }
    }

    interestSaved = totalInterest - extraTotalInterest;
    const yearsSaved = (months - extraMonthsToPayoff) / 12;

    // Calculate current progress
    const currentBalance = principal * 
      (Math.pow(1 + monthlyRate, months) - Math.pow(1 + monthlyRate, paymentsMade)) /
      (Math.pow(1 + monthlyRate, months) - 1);

    return {
      monthlyPayment: monthlyPayment.toFixed(2),
      totalInterest: totalInterest.toFixed(2),
      extraPaymentResults: {
        monthlyWithExtra: (monthlyPayment + extra).toFixed(2),
        totalWithExtra: extraTotalInterest.toFixed(2),
        interestSaved: interestSaved.toFixed(2),
        yearsSaved: yearsSaved.toFixed(1)
      },
      currentProgress: {
        originalBalance: principal.toFixed(2),
        currentBalance: currentBalance.toFixed(2),
        equityBuilt: (principal - currentBalance).toFixed(2),
        percentagePaid: ((principal - currentBalance) / principal * 100).toFixed(1)
      },
      schedules: {
        regular: regularPaymentSchedule,
        withExtra: extraPaymentSchedule
      }
    };
  };

  const results = calculateMortgage();

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-600 to-green-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <TrendingUp className="w-8 h-8 text-green-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Mortgage Strategy Calculator</h1>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Loan Amount</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(e.target.value)}
                  className="focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Interest Rate (%)</label>
              <input
                type="number"
                value={interestRate}
                onChange={(e) => setInterestRate(e.target.value)}
                step="0.01"
                className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="0.00"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Loan Term (Years)</label>
              <select
                value={loanTerm}
                onChange={(e) => setLoanTerm(e.target.value)}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option value="30">30 Years</option>
                <option value="20">20 Years</option>
                <option value="15">15 Years</option>
                <option value="10">10 Years</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Extra Monthly Payment</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  value={extraPayment}
                  onChange={(e) => setExtraPayment(e.target.value)}
                  className="focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  placeholder="0.00"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Payments Made</label>
              <input
                type="number"
                value={currentPaymentsMade}
                onChange={(e) => setCurrentPaymentsMade(e.target.value)}
                className="mt-1 focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="0"
              />
            </div>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-green-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Payment Analysis</h2>
              </div>

              <div className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">Standard Payment</h3>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Monthly Payment:</span>
                        <span className="font-semibold">${results.monthlyPayment}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Total Interest:</span>
                        <span className="font-semibold">${results.totalInterest}</span>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">With Extra Payment</h3>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Monthly Payment:</span>
                        <span className="font-semibold">${results.extraPaymentResults.monthlyWithExtra}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Total Interest:</span>
                        <span className="font-semibold">${results.extraPaymentResults.totalWithExtra}</span>
                      </div>
                      <div className="flex justify-between text-green-600">
                        <span>Interest Saved:</span>
                        <span className="font-semibold">${results.extraPaymentResults.interestSaved}</span>
                      </div>
                      <div className="flex justify-between text-green-600">
                        <span>Years Saved:</span>
                        <span className="font-semibold">{results.extraPaymentResults.yearsSaved}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 rounded-lg shadow">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">Current Progress</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Original Balance:</span>
                        <span className="font-semibold">${results.currentProgress.originalBalance}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Current Balance:</span>
                        <span className="font-semibold">${results.currentProgress.currentBalance}</span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-600">Equity Built:</span>
                        <span className="font-semibold">${results.currentProgress.equityBuilt}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Percentage Paid:</span>
                        <span className="font-semibold">{results.currentProgress.percentagePaid}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-green-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-green-800">
                <p>* Calculations are estimates based on fixed interest rates and monthly compounding.</p>
                <p>* Actual loan terms and conditions may vary by lender.</p>
                <p>* Consider consulting with a financial advisor for personalized advice.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}