import React, { useState } from 'react';
import { Building, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ProjectType = 'residential' | 'commercial';
type BuildingType = 'single-family' | 'multi-family' | 'retail' | 'office' | 'industrial' | 'mixed-use';
type ConstructionGrade = 'economy' | 'standard' | 'premium' | 'luxury';

export default function GeneralContractorCalculator() {
  // State for project details
  const [projectType, setProjectType] = useState<ProjectType>('residential');
  const [buildingType, setBuildingType] = useState<BuildingType>('single-family');
  const [constructionGrade, setConstructionGrade] = useState<ConstructionGrade>('standard');
  const [squareFeet, setSquareFeet] = useState('');
  const [stories, setStories] = useState('1');
  const [includeBasement, setIncludeBasement] = useState(false);
  const [includeGarage, setIncludeGarage] = useState(true);
  const [includeSiteWork, setIncludeSiteWork] = useState(true);
  const [includePermits, setIncludePermits] = useState(true);
  const [needsDemolition, setNeedsDemolition] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    residential: {
      'single-family': {
        economy: 125, // per sq ft
        standard: 165,
        premium: 225,
        luxury: 300
      },
      'multi-family': {
        economy: 135,
        standard: 175,
        premium: 235,
        luxury: 310
      }
    },
    commercial: {
      retail: {
        economy: 150,
        standard: 185,
        premium: 245,
        luxury: 325
      },
      office: {
        economy: 165,
        standard: 200,
        premium: 265,
        luxury: 350
      },
      industrial: {
        economy: 110,
        standard: 145,
        premium: 195,
        luxury: 275
      },
      'mixed-use': {
        economy: 155,
        standard: 195,
        premium: 255,
        luxury: 335
      }
    }
  };

  // Additional costs and multipliers
  const additionalCosts = {
    basement: {
      unfinished: 45, // per sq ft
      finished: 85
    },
    garage: {
      attached: 55,
      detached: 65
    },
    siteWork: {
      basic: 15000,
      extensive: 35000
    },
    permits: {
      residential: {
        base: 2500,
        perSqFt: 0.75
      },
      commercial: {
        base: 5000,
        perSqFt: 1.25
      }
    },
    demolition: {
      residential: 12, // per sq ft
      commercial: 15
    }
  };

  // Story multipliers
  const storyMultipliers = {
    '1': 1.0,
    '2': 0.9, // economies of scale
    '3': 0.85,
    '4': 0.82,
    '5': 0.80
  };

  const calculateEstimate = () => {
    const area = parseFloat(squareFeet);
    if (!area) return null;

    // Get base cost per square foot
    const baseRate = projectType === 'residential' 
      ? baseCosts.residential[buildingType as 'single-family' | 'multi-family'][constructionGrade]
      : baseCosts.commercial[buildingType as 'retail' | 'office' | 'industrial' | 'mixed-use'][constructionGrade];

    // Apply story multiplier
    const storyCount = parseInt(stories);
    const storyMultiplier = storyMultipliers[storyCount as keyof typeof storyMultipliers] || 0.8;
    
    // Calculate base building cost
    const baseBuildingCost = area * baseRate * storyMultiplier;

    // Calculate basement cost if included
    const basementCost = includeBasement ?
      (area * additionalCosts.basement[constructionGrade === 'economy' ? 'unfinished' : 'finished']) : 0;

    // Calculate garage cost if included (assume 400 sq ft for residential, 1000 for commercial)
    const garageArea = projectType === 'residential' ? 400 : 1000;
    const garageCost = includeGarage ?
      (garageArea * additionalCosts.garage[buildingType === 'single-family' ? 'attached' : 'detached']) : 0;

    // Calculate site work costs
    const siteWorkCost = includeSiteWork ?
      (area > 5000 ? additionalCosts.siteWork.extensive : additionalCosts.siteWork.basic) : 0;

    // Calculate permit costs
    const permitCosts = includePermits ?
      (additionalCosts.permits[projectType].base + 
       (area * additionalCosts.permits[projectType].perSqFt)) : 0;

    // Calculate demolition costs if needed
    const demolitionCost = needsDemolition ?
      (area * additionalCosts.demolition[projectType]) : 0;

    // Calculate soft costs (design, engineering, etc.) - typically 15-20% of construction costs
    const softCostsPercentage = projectType === 'commercial' ? 0.20 : 0.15;
    const constructionCost = baseBuildingCost + basementCost + garageCost + siteWorkCost;
    const softCosts = constructionCost * softCostsPercentage;

    // Calculate contingency - typically 10% of total costs
    const subtotal = constructionCost + softCosts + permitCosts + demolitionCost;
    const contingency = subtotal * 0.10;

    // Calculate total
    const total = subtotal + contingency;

    return {
      area: area.toFixed(0),
      costs: {
        building: baseBuildingCost.toFixed(2),
        basement: basementCost.toFixed(2),
        garage: garageCost.toFixed(2),
        siteWork: siteWorkCost.toFixed(2),
        permits: permitCosts.toFixed(2),
        demolition: demolitionCost.toFixed(2),
        soft: softCosts.toFixed(2),
        contingency: contingency.toFixed(2)
      },
      total: total.toFixed(2),
      perSqFt: (total / area).toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-700 to-slate-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Building className="w-8 h-8 text-slate-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">General Contractor Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Project Type</label>
            <div className="grid grid-cols-2 gap-2">
              {(['residential', 'commercial'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => {
                    setProjectType(type);
                    // Reset building type based on project type
                    setBuildingType(type === 'residential' ? 'single-family' : 'retail');
                  }}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    projectType === type
                      ? 'bg-slate-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Building Type</label>
            <div className="grid grid-cols-2 gap-2">
              {(projectType === 'residential' 
                ? ['single-family', 'multi-family']
                : ['retail', 'office', 'industrial', 'mixed-use']
              ).map((type) => (
                <button
                  key={type}
                  onClick={() => setBuildingType(type as BuildingType)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    buildingType === type
                      ? 'bg-slate-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type.replace('-', ' ')}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Construction Grade</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['economy', 'standard', 'premium', 'luxury'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setConstructionGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    constructionGrade === grade
                      ? 'bg-slate-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Square Feet</label>
              <input
                type="number"
                value={squareFeet}
                onChange={(e) => setSquareFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring focus:ring-slate-200"
                placeholder="Enter area"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Number of Stories</label>
              <select
                value={stories}
                onChange={(e) => setStories(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring focus:ring-slate-200"
              >
                {['1', '2', '3', '4', '5'].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBasement}
                onChange={(e) => setIncludeBasement(e.target.checked)}
                className="rounded text-slate-700 focus:ring-slate-500"
              />
              <span>Include Basement</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeGarage}
                onChange={(e) => setIncludeGarage(e.target.checked)}
                className="rounded text-slate-700 focus:ring-slate-500"
              />
              <span>Include Garage</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeSiteWork}
                onChange={(e) => setIncludeSiteWork(e.target.checked)}
                className="rounded text-slate-700 focus:ring-slate-500"
              />
              <span>Include Site Work</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermits}
                onChange={(e) => setIncludePermits(e.target.checked)}
                className="rounded text-slate-700 focus:ring-slate-500"
              />
              <span>Include Permits & Fees</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsDemolition}
                onChange={(e) => setNeedsDemolition(e.target.checked)}
                className="rounded text-slate-700 focus:ring-slate-500"
              />
              <span>Needs Demolition</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-slate-700 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Total Area:</span>
                  </div>
                  <span className="font-semibold">{results.area} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Building:</span>
                    <span className="font-semibold">${results.costs.building}</span>
                  </div>
                  {parseFloat(results.costs.basement) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Basement:</span>
                      <span className="font-semibold">${results.costs.basement}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.garage) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Garage:</span>
                      <span className="font-semibold">${results.costs.garage}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.siteWork) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Site Work:</span>
                      <span className="font-semibold">${results.costs.siteWork}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.demolition) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Demolition:</span>
                      <span className="font-semibold">${results.costs.demolition}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Soft Costs:</span>
                    <span className="font-semibold">${results.costs.soft}</span>
                  </div>
                  {parseFloat(results.costs.permits) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permits & Fees:</span>
                      <span className="font-semibold">${results.costs.permits}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Contingency:</span>
                    <span className="font-semibold">${results.costs.contingency}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <DollarSign className="w-5 h-5 text-slate-700 mr-2" />
                      <span className="font-bold">Total Estimate:</span>
                    </div>
                    <span className="text-2xl font-bold text-slate-700">${results.total}</span>
                  </div>
                  <div className="text-sm text-gray-600 text-center">
                    Cost per Square Foot: ${results.perSqFt}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-slate-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-slate-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-slate-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for complex designs or special requirements.</p>
                <p>* All construction projects require proper permits and inspections.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Construction Costs</h3>
            <p className="text-gray-700">
              Construction costs in the Lima/Dayton area vary significantly based on project type, size, and quality level. This calculator provides rough estimates based on current market rates and typical construction costs.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Cost Factors</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Building type and complexity</li>
              <li>Construction quality and finishes</li>
              <li>Site conditions and preparation</li>
              <li>Local building codes and requirements</li>
              <li>Material and labor market conditions</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend consulting with local contractors and architects to discuss specific project requirements and conditions.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}