import React, { useState } from 'react';
import { Bath, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type RemodelType = 'basic' | 'mid-range' | 'luxury';
type VanityType = 'single' | 'double';
type TileGrade = 'standard' | 'premium' | 'custom';

export default function BathroomCalculator() {
  // State for bathroom details
  const [remodelType, setRemodelType] = useState<RemodelType>('mid-range');
  const [vanityType, setVanityType] = useState<VanityType>('single');
  const [tileGrade, setTileGrade] = useState<TileGrade>('standard');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [includeShower, setIncludeShower] = useState(true);
  const [includeTub, setIncludeTub] = useState(false);
  const [includeToilet, setIncludeToilet] = useState(true);
  const [includeVentilation, setIncludeVentilation] = useState(true);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    basic: 85, // per sq ft
    'mid-range': 125,
    luxury: 200
  };

  const vanityCosts = {
    basic: {
      single: 600,
      double: 1200
    },
    'mid-range': {
      single: 1200,
      double: 2200
    },
    luxury: {
      single: 2500,
      double: 4500
    }
  };

  const tileCosts = {
    standard: {
      floor: 12, // per sq ft
      wall: 15
    },
    premium: {
      floor: 18,
      wall: 22
    },
    custom: {
      floor: 25,
      wall: 30
    }
  };

  const fixturesCosts = {
    basic: {
      toilet: 250,
      shower: 1500,
      tub: 800,
      faucet: 150
    },
    'mid-range': {
      toilet: 450,
      shower: 3000,
      tub: 1500,
      faucet: 300
    },
    luxury: {
      toilet: 800,
      shower: 5000,
      tub: 3000,
      faucet: 600
    }
  };

  const additionalCosts = {
    ventilation: 400,
    lighting: {
      basic: 300,
      'mid-range': 600,
      luxury: 1200
    },
    plumbing: {
      basic: 1500,
      'mid-range': 2500,
      luxury: 4000
    },
    electrical: {
      basic: 800,
      'mid-range': 1200,
      luxury: 2000
    },
    permit: 350,
    demolition: 1500,
    disposal: 500
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    if (!l || !w) return null;

    // Calculate areas
    const floorArea = l * w;
    const wallArea = 2 * (l + w) * 8; // Assuming 8ft ceiling height
    
    // Calculate base remodeling cost
    const baseCost = floorArea * baseCosts[remodelType];
    
    // Calculate vanity cost
    const vanityCost = vanityCosts[remodelType][vanityType];

    // Calculate tile costs
    const tileCost = (floorArea * tileCosts[tileGrade].floor) +
                    (wallArea * tileCosts[tileGrade].wall);

    // Calculate fixtures costs
    const fixtures = fixturesCosts[remodelType];
    const fixturesCost = (includeToilet ? fixtures.toilet : 0) +
                        (includeShower ? fixtures.shower : 0) +
                        (includeTub ? fixtures.tub : 0) +
                        fixtures.faucet;

    // Calculate additional costs
    const ventilationCost = includeVentilation ? additionalCosts.ventilation : 0;
    const lightingCost = additionalCosts.lighting[remodelType];
    const plumbingCost = additionalCosts.plumbing[remodelType];
    const electricalCost = additionalCosts.electrical[remodelType];
    const permitCost = includePermit ? additionalCosts.permit : 0;
    const demoCost = additionalCosts.demolition + additionalCosts.disposal;

    // Calculate totals
    const subtotal = baseCost + vanityCost + tileCost + fixturesCost +
                    ventilationCost + lightingCost + plumbingCost +
                    electricalCost + permitCost + demoCost;
    const tax = (vanityCost + tileCost + fixturesCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        floor: floorArea.toFixed(0),
        walls: wallArea.toFixed(0)
      },
      costs: {
        base: baseCost.toFixed(2),
        vanity: vanityCost.toFixed(2),
        tile: tileCost.toFixed(2),
        fixtures: fixturesCost.toFixed(2),
        ventilation: ventilationCost.toFixed(2),
        lighting: lightingCost.toFixed(2),
        plumbing: plumbingCost.toFixed(2),
        electrical: electricalCost.toFixed(2),
        demo: demoCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-teal-600 to-teal-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Bath className="w-8 h-8 text-teal-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Bathroom Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Remodel Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'mid-range', 'luxury'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setRemodelType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    remodelType === type
                      ? 'bg-teal-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Vanity Type</label>
            <div className="grid grid-cols-2 gap-2">
              {(['single', 'double'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setVanityType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    vanityType === type
                      ? 'bg-teal-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Tile Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['standard', 'premium', 'custom'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setTileGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    tileGrade === grade
                      ? 'bg-teal-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring focus:ring-teal-200"
                placeholder="Enter width"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeShower}
                onChange={(e) => setIncludeShower(e.target.checked)}
                className="rounded text-teal-600 focus:ring-teal-500"
              />
              <span>Include Shower</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeTub}
                onChange={(e) => setIncludeTub(e.target.checked)}
                className="rounded text-teal-600 focus:ring-teal-500"
              />
              <span>Include Tub</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeToilet}
                onChange={(e) => setIncludeToilet(e.target.checked)}
                className="rounded text-teal-600 focus:ring-teal-500"
              />
              <span>Include Toilet</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeVentilation}
                onChange={(e) => setIncludeVentilation(e.target.checked)}
                className="rounded text-teal-600 focus:ring-teal-500"
              />
              <span>Include Ventilation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-teal-600 focus:ring-teal-500"
              />
              <span>Include Permit</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-teal-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Bathroom Size:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.floor} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Remodel:</span>
                    <span className="font-semibold">${results.costs.base}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Vanity:</span>
                    <span className="font-semibold">${results.costs.vanity}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Tile Work:</span>
                    <span className="font-semibold">${results.costs.tile}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Fixtures:</span>
                    <span className="font-semibold">${results.costs.fixtures}</span>
                  </div>
                  {parseFloat(results.costs.ventilation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Ventilation:</span>
                      <span className="font-semibold">${results.costs.ventilation}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Lighting:</span>
                    <span className="font-semibold">${results.costs.lighting}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Plumbing:</span>
                    <span className="font-semibold">${results.costs.plumbing}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Electrical:</span>
                    <span className="font-semibold">${results.costs.electrical}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Demo & Disposal:</span>
                    <span className="font-semibold">${results.costs.demo}</span>
                  </div>
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-teal-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-teal-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-teal-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-teal-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-teal-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs or special requirements.</p>
                <p>* All bathroom remodels require proper permits and inspections.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Bathroom Remodel Costs</h3>
            <p className="text-gray-700">
              Bathroom remodeling costs in the Lima/Dayton area vary based on several factors including size, fixtures, and finish level. Basic remodels typically range from $8,000 to $15,000, with luxury finishes and custom features increasing costs significantly.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Fixture quality and features</li>
              <li>Tile selection and coverage</li>
              <li>Plumbing modifications</li>
              <li>Ventilation requirements</li>
              <li>Electrical updates</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term value when selecting materials and fixtures.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}