import React, { useState } from 'react';
import { Warehouse, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type GarageType = 'attached' | 'detached' | 'carport' | 'workshop';
type MaterialType = 'wood' | 'metal' | 'brick' | 'vinyl';
type RoofType = 'asphalt' | 'metal' | 'tile';

export default function GarageCalculator() {
  // State for garage details
  const [garageType, setGarageType] = useState<GarageType>('attached');
  const [materialType, setMaterialType] = useState<MaterialType>('wood');
  const [roofType, setRoofType] = useState<RoofType>('asphalt');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('10');
  const [carCount, setCarCount] = useState('2');
  const [includeElectrical, setIncludeElectrical] = useState(true);
  const [includeHVAC, setIncludeHVAC] = useState(false);
  const [includeInsulation, setIncludeInsulation] = useState(true);
  const [includeDoor, setIncludeDoor] = useState(true);
  const [includeWindows, setIncludeWindows] = useState(true);
  const [needsFoundation, setNeedsFoundation] = useState(true);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    attached: {
      wood: 45, // per sq ft
      metal: 40,
      brick: 65,
      vinyl: 42
    },
    detached: {
      wood: 50,
      metal: 45,
      brick: 70,
      vinyl: 47
    },
    carport: {
      wood: 25,
      metal: 20,
      brick: 35,
      vinyl: 28
    },
    workshop: {
      wood: 55,
      metal: 50,
      brick: 75,
      vinyl: 52
    }
  };

  const roofingCosts = {
    asphalt: 4.50, // per sq ft
    metal: 8.50,
    tile: 12.00
  };

  const additionalCosts = {
    foundation: {
      slab: 6.50, // per sq ft
      crawlSpace: 12.00,
      fullBasement: 25.00
    },
    electrical: {
      basic: 1500, // basic wiring and outlets
      advanced: 3500 // with 220V and extra circuits
    },
    hvac: {
      heating: 2500,
      cooling: 3500,
      both: 5500
    },
    insulation: {
      walls: 2.25, // per sq ft
      ceiling: 1.75
    },
    door: {
      standard: 1200,
      insulated: 1800,
      premium: 2500
    },
    windows: {
      basic: 350, // per window
      insulated: 500,
      premium: 750
    },
    permit: 750,
    survey: 500,
    plans: 1200
  };

  // Car count multipliers
  const carMultipliers = {
    '1': 1.0,
    '2': 0.9, // economies of scale
    '3': 0.85,
    '4': 0.8
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const h = parseFloat(height);
    if (!l || !w) return null;

    // Calculate areas
    const floorArea = l * w;
    const wallArea = 2 * (l + w) * h;
    const roofArea = l * w * 1.15; // 15% extra for overhang
    
    // Calculate base construction cost
    const baseCost = floorArea * baseCosts[garageType][materialType];
    const multiplier = carMultipliers[carCount as keyof typeof carMultipliers];
    const adjustedBaseCost = baseCost * multiplier;

    // Calculate roofing cost
    const roofCost = roofArea * roofingCosts[roofType];

    // Calculate foundation cost
    const foundationCost = needsFoundation ? 
      (floorArea * additionalCosts.foundation.slab) : 0;

    // Calculate additional features
    const electricalCost = includeElectrical ?
      (garageType === 'workshop' ? additionalCosts.electrical.advanced : additionalCosts.electrical.basic) : 0;

    const hvacCost = includeHVAC ?
      additionalCosts.hvac.both : 0;

    const insulationCost = includeInsulation ?
      ((wallArea * additionalCosts.insulation.walls) + 
       (floorArea * additionalCosts.insulation.ceiling)) : 0;

    const doorCost = includeDoor ?
      (additionalCosts.door.insulated * parseInt(carCount)) : 0;

    const windowCount = Math.ceil(l / 12); // One window per 12 feet
    const windowCost = includeWindows ?
      (windowCount * additionalCosts.windows.insulated) : 0;

    // Calculate permit and professional fees
    const permitCost = includePermit ?
      (additionalCosts.permit + additionalCosts.survey + additionalCosts.plans) : 0;

    // Calculate totals
    const subtotal = adjustedBaseCost + roofCost + foundationCost + electricalCost +
                    hvacCost + insulationCost + doorCost + windowCost + permitCost;
    const tax = (adjustedBaseCost + roofCost + doorCost + windowCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        floor: floorArea.toFixed(0),
        walls: wallArea.toFixed(0),
        roof: roofArea.toFixed(0)
      },
      costs: {
        base: adjustedBaseCost.toFixed(2),
        roof: roofCost.toFixed(2),
        foundation: foundationCost.toFixed(2),
        electrical: electricalCost.toFixed(2),
        hvac: hvacCost.toFixed(2),
        insulation: insulationCost.toFixed(2),
        doors: doorCost.toFixed(2),
        windows: windowCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-zinc-600 to-zinc-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Warehouse className="w-8 h-8 text-zinc-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Garage Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Garage Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['attached', 'detached', 'carport', 'workshop'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setGarageType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    garageType === type
                      ? 'bg-zinc-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Material Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['wood', 'metal', 'brick', 'vinyl'] as const).map((material) => (
                <button
                  key={material}
                  onClick={() => setMaterialType(material)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    materialType === material
                      ? 'bg-zinc-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {material}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Roof Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['asphalt', 'metal', 'tile'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setRoofType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    roofType === type
                      ? 'bg-zinc-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-zinc-500 focus:ring focus:ring-zinc-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-zinc-500 focus:ring focus:ring-zinc-200"
                placeholder="Enter width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Height (feet)</label>
              <select
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-zinc-500 focus:ring focus:ring-zinc-200"
              >
                {['8', '9', '10', '12', '14'].map((h) => (
                  <option key={h} value={h}>{h} ft</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Car Capacity</label>
              <select
                value={carCount}
                onChange={(e) => setCarCount(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-zinc-500 focus:ring focus:ring-zinc-200"
              >
                {['1', '2', '3', '4'].map((count) => (
                  <option key={count} value={count}>{count} Car</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsFoundation}
                onChange={(e) => setNeedsFoundation(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include Concrete Foundation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeElectrical}
                onChange={(e) => setIncludeElectrical(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include Electrical</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeHVAC}
                onChange={(e) => setIncludeHVAC(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include HVAC</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeInsulation}
                onChange={(e) => setIncludeInsulation(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include Insulation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDoor}
                onChange={(e) => setIncludeDoor(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include Garage Door(s)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeWindows}
                onChange={(e) => setIncludeWindows(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include Windows</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-zinc-600 focus:ring-zinc-500"
              />
              <span>Include Permit & Plans</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-zinc-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Floor Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.floor} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Construction:</span>
                    <span className="font-semibold">${results.costs.base}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Roofing:</span>
                    <span className="font-semibold">${results.costs.roof}</span>
                  </div>
                  {parseFloat(results.costs.foundation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Foundation:</span>
                      <span className="font-semibold">${results.costs.foundation}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.electrical) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Electrical:</span>
                      <span className="font-semibold">${results.costs.electrical}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.hvac) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">HVAC:</span>
                      <span className="font-semibold">${results.costs.hvac}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.insulation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Insulation:</span>
                      <span className="font-semibold">${results.costs.insulation}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.doors) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Garage Doors:</span>
                      <span className="font-semibold">${results.costs.doors}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.windows) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Windows:</span>
                      <span className="font-semibold">${results.costs.windows}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permits & Plans:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-zinc-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-zinc-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-zinc-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-zinc-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-zinc-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for site preparation or special requirements.</p>
                <p>* All structures require proper permits and inspections.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Garage Construction Costs</h3>
            <p className="text-gray-700">
              Garage construction costs in the Lima/Dayton area vary based on several factors including size, materials, and additional features. Basic garages typically range from $20,000 to $50,000, with custom workshops or larger structures costing more.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Site preparation and accessibility</li>
              <li>Local building codes and setback requirements</li>
              <li>Utility connections and requirements</li>
              <li>Climate control needs</li>
              <li>Storage and workspace requirements</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate construction costs and long-term utility and maintenance requirements.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}