import React, { useState } from 'react';
import { Waves, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type PoolType = 'inground' | 'aboveground' | 'semiinground' | 'lap' | 'plunge';
type MaterialType = 'vinyl' | 'fiberglass' | 'concrete' | 'gunite';
type ShapeType = 'rectangle' | 'oval' | 'kidney' | 'freeform' | 'custom';

export default function PoolCalculator() {
  // State for pool details
  const [poolType, setPoolType] = useState<PoolType>('inground');
  const [materialType, setMaterialType] = useState<MaterialType>('vinyl');
  const [shapeType, setShapeType] = useState<ShapeType>('rectangle');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [depth, setDepth] = useState('5');
  const [includeDeck, setIncludeDeck] = useState(true);
  const [deckSize, setDeckSize] = useState('300');
  const [includeHeater, setIncludeHeater] = useState(false);
  const [includeLighting, setIncludeLighting] = useState(true);
  const [includeCover, setIncludeCover] = useState(true);
  const [includePermit, setIncludePermit] = useState(true);
  const [needsExcavation, setNeedsExcavation] = useState(true);
  const [needsFencing, setNeedsFencing] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const poolCosts = {
    inground: {
      vinyl: { base: 35, installation: 15 }, // per sq ft
      fiberglass: { base: 50, installation: 20 },
      concrete: { base: 65, installation: 25 },
      gunite: { base: 75, installation: 30 }
    },
    aboveground: {
      vinyl: { base: 20, installation: 8 },
      fiberglass: { base: 30, installation: 12 }
    },
    semiinground: {
      vinyl: { base: 30, installation: 12 },
      fiberglass: { base: 40, installation: 15 }
    },
    lap: {
      vinyl: { base: 45, installation: 18 },
      fiberglass: { base: 60, installation: 22 },
      concrete: { base: 75, installation: 28 }
    },
    plunge: {
      vinyl: { base: 40, installation: 16 },
      fiberglass: { base: 55, installation: 20 },
      concrete: { base: 70, installation: 25 }
    }
  };

  // Additional features and costs
  const additionalCosts = {
    deck: {
      concrete: 12, // per sq ft
      pavers: 18,
      composite: 22
    },
    heater: {
      gas: 3500,
      electric: 2500,
      solar: 4500,
      installation: 800
    },
    lighting: {
      basic: 1200,
      led: 2000,
      fiber: 3000
    },
    cover: {
      manual: 800,
      automatic: 2500,
      safety: 1800
    },
    fencing: 35, // per linear ft
    excavation: 12, // per cubic yard
    permit: 500,
    inspection: 300
  };

  // Shape complexity multipliers
  const shapeMultipliers = {
    rectangle: 1.0,
    oval: 1.1,
    kidney: 1.2,
    freeform: 1.3,
    custom: 1.4
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const d = parseFloat(depth);
    if (!l || !w) return null;

    // Calculate pool area and volume
    const surfaceArea = l * w;
    const volume = surfaceArea * d;
    const perimeter = 2 * (l + w);
    
    // Calculate base pool costs
    const poolBase = poolCosts[poolType][materialType];
    const shapeMultiplier = shapeMultipliers[shapeType];
    const baseCost = surfaceArea * (poolBase.base + poolBase.installation) * shapeMultiplier;

    // Calculate excavation if needed
    const excavationVolume = needsExcavation ? (volume * 1.2) / 27 : 0; // Convert to cubic yards with 20% extra
    const excavationCost = excavationVolume * additionalCosts.excavation;

    // Calculate deck costs if included
    const deckArea = includeDeck ? parseFloat(deckSize) : 0;
    const deckCost = deckArea * additionalCosts.deck.concrete;

    // Calculate additional features
    const heaterCost = includeHeater ? 
      (additionalCosts.heater.gas + additionalCosts.heater.installation) : 0;
    
    const lightingCost = includeLighting ?
      additionalCosts.lighting.led : 0;
    
    const coverCost = includeCover ?
      additionalCosts.cover.safety : 0;

    // Calculate fencing if needed
    const fencingCost = needsFencing ? (perimeter * additionalCosts.fencing) : 0;

    // Calculate permit and inspection costs
    const permitCost = includePermit ? 
      (additionalCosts.permit + additionalCosts.inspection) : 0;

    // Calculate totals
    const subtotal = baseCost + excavationCost + deckCost + heaterCost + 
                    lightingCost + coverCost + fencingCost + permitCost;
    const tax = (baseCost + heaterCost + lightingCost + coverCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        area: surfaceArea.toFixed(0),
        volume: volume.toFixed(0),
        perimeter: perimeter.toFixed(0)
      },
      costs: {
        base: baseCost.toFixed(2),
        excavation: excavationCost.toFixed(2),
        deck: deckCost.toFixed(2),
        heater: heaterCost.toFixed(2),
        lighting: lightingCost.toFixed(2),
        cover: coverCost.toFixed(2),
        fencing: fencingCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-400 to-blue-600 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Waves className="w-8 h-8 text-blue-400 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Pool Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Pool Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['inground', 'aboveground', 'semiinground', 'lap', 'plunge'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => {
                    setPoolType(type);
                    // Reset material type if not available for selected pool type
                    if (type === 'aboveground' || type === 'semiinground') {
                      setMaterialType('vinyl');
                    }
                  }}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    poolType === type
                      ? 'bg-blue-400 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'semiinground' ? 'Semi-Inground' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Material</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['vinyl', 'fiberglass', 'concrete', 'gunite'] as const)
                .filter(material => {
                  // Filter available materials based on pool type
                  if (poolType === 'aboveground' || poolType === 'semiinground') {
                    return ['vinyl', 'fiberglass'].includes(material);
                  }
                  return true;
                })
                .map((material) => (
                  <button
                    key={material}
                    onClick={() => setMaterialType(material)}
                    className={`py-2 px-4 rounded-lg capitalize ${
                      materialType === material
                        ? 'bg-blue-400 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {material}
                  </button>
                ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Shape</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['rectangle', 'oval', 'kidney', 'freeform', 'custom'] as const).map((shape) => (
                <button
                  key={shape}
                  onClick={() => setShapeType(shape)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    shapeType === shape
                      ? 'bg-blue-400 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {shape}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Depth (feet)</label>
              <select
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              >
                {['3', '4', '5', '6', '8', '10'].map((d) => (
                  <option key={d} value={d}>{d} ft</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDeck}
                onChange={(e) => setIncludeDeck(e.target.checked)}
                className="rounded text-blue-400 focus:ring-blue-500"
              />
              <span>Include Pool Deck</span>
            </label>
            {includeDeck && (
              <div className="ml-6">
                <label className="block text-sm font-medium text-gray-700">Deck Size (sq ft)</label>
                <input
                  type="number"
                  value={deckSize}
                  onChange={(e) => setDeckSize(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
            )}
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeHeater}
                onChange={(e) => setIncludeHeater(e.target.checked)}
                className="rounded text-blue-400 focus:ring-blue-500"
              />
              <span>Include Pool Heater</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeLighting}
                onChange={(e) => setIncludeLighting(e.target.checked)}
                className="rounded text-blue-400 focus:ring-blue-500"
              />
              <span>Include LED Lighting</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeCover}
                onChange={(e) => setIncludeCover(e.target.checked)}
                className="rounded text-blue-400 focus:ring-blue-500"
              />
              <span>Include Safety Cover</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsFencing}
                onChange={(e) => setNeedsFencing(e.target.checked)}
                className="rounded text-blue-400 focus:ring-blue-500"
              />
              <span>Include Safety Fencing</span>
            </label>
            {poolType === 'inground' && (
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={needsExcavation}
                  onChange={(e) => setNeedsExcavation(e.target.checked)}
                  className="rounded text-blue-400 focus:ring-blue-500"
                />
                <span>Needs Excavation</span>
              </label>
            )}
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-blue-400 focus:ring-blue-500"
              />
              <span>Include Permit & Inspection</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-blue-400 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Pool Size:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.area} sq ft ({results.dimensions.volume} cu ft)</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Pool Construction:</span>
                    <span className="font-semibold">${results.costs.base}</span>
                  </div>
                  {parseFloat(results.costs.excavation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Excavation:</span>
                      <span className="font-semibold">${results.costs.excavation}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.deck) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Pool Deck:</span>
                      <span className="font-semibold">${results.costs.deck}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.heater) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Heater:</span>
                      <span className="font-semibold">${results.costs.heater}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.lighting) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Lighting:</span>
                      <span className="font-semibold">${results.costs.lighting}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.cover) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Safety Cover:</span>
                      <span className="font-semibold">${results.costs.cover}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.fencing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Fencing:</span>
                      <span className="font-semibold">${results.costs.fencing}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permits & Inspection:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-blue-400 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-blue-400">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-blue-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for difficult terrain or special requirements.</p>
                <p>* All pools require proper permits and safety features by law.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Pool Installation Costs</h3>
            <p className="text-gray-700">
              Pool installation costs in the Lima/Dayton area vary based on several factors including pool type, size, and additional features. Basic installations typically range from $30,000 to $100,000 depending on the chosen options and site conditions.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Site preparation and accessibility</li>
              <li>Local building codes and setback requirements</li>
              <li>Safety features and fencing requirements</li>
              <li>Utility locations and connections</li>
              <li>Maintenance costs and winterization</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local pool contractors and considering both immediate installation costs and long-term maintenance requirements.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}