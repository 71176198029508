import React from 'react';

interface LawnCareCalculatorProps {
  area: number;
  includeLabor: boolean;
  frequency: 'weekly' | 'biweekly';
  includeMulch: boolean;
  includeEdging: boolean;
  includeAeration: boolean;
  includeFertilization: boolean;
  includeOverseeding: boolean;
  onUpdate: (results: any) => void;
}

export default function LawnCareCalculator({
  area,
  includeLabor,
  frequency,
  includeMulch,
  includeEdging,
  includeAeration,
  includeFertilization,
  includeOverseeding,
  onUpdate
}: LawnCareCalculatorProps) {
  // Base rates per square foot
  const mowingRate = 0.015;
  const mulchRate = 0.75;
  const edgingRate = 4.50; // per linear foot
  
  // Additional service rates
  const aerationRate = 0.08; // per sq ft
  const fertilizationRate = 0.06; // per sq ft
  const overseedingRate = 0.10; // per sq ft

  React.useEffect(() => {
    const calculateCosts = () => {
      // Calculate perimeter for edging (approximate square)
      const perimeter = Math.sqrt(area) * 4;
      
      // Calculate base mowing cost
      const mowingCost = area * mowingRate;
      
      // Apply frequency discount for weekly service
      const frequencyMultiplier = frequency === 'weekly' ? 0.85 : 1.0;
      
      // Calculate service costs
      const baseMowingCost = mowingCost * frequencyMultiplier;
      const mulchCost = includeMulch ? (area * 0.2 * mulchRate) : 0; // Assume 20% of area needs mulch
      const edgingCost = includeEdging ? (perimeter * edgingRate) : 0;
      
      // Calculate additional service costs
      const aerationCost = includeAeration ? (area * aerationRate) : 0;
      const fertilizationCost = includeFertilization ? (area * fertilizationRate) : 0;
      const overseedingCost = includeOverseeding ? (area * overseedingRate) : 0;

      // Calculate labor costs if included
      const laborCost = includeLabor ? (baseMowingCost + mulchCost + edgingCost + 
                                      aerationCost + fertilizationCost + overseedingCost) : 0;

      const total = baseMowingCost + mulchCost + edgingCost + 
                   aerationCost + fertilizationCost + overseedingCost;
      const perService = baseMowingCost;

      onUpdate({
        costs: {
          mowing: baseMowingCost,
          mulch: mulchCost,
          edging: edgingCost,
          aeration: aerationCost,
          fertilization: fertilizationCost,
          overseeding: overseedingCost,
          labor: laborCost
        },
        total: total,
        perService: perService
      });
    };

    calculateCosts();
  }, [area, includeLabor, frequency, includeMulch, includeEdging, 
      includeAeration, includeFertilization, includeOverseeding]);

  return null;
}