import React, { useState } from 'react';
import { Shovel, Calculator, DollarSign, Ruler, AlertTriangle, Truck, Mountain, Droplet } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type SoilType = 'topsoil' | 'clay' | 'sand' | 'gravel' | 'rock';
type ProjectType = 'foundation' | 'pool' | 'driveway' | 'basement' | 'utilities' | 'grading' | 'pond';
type MaterialDisposal = 'haul' | 'spread' | 'stockpile';

export default function ExcavationCalculator() {
  // Project details
  const [projectType, setProjectType] = useState<ProjectType>('foundation');
  const [soilType, setSoilType] = useState<SoilType>('topsoil');
  const [disposalMethod, setDisposalMethod] = useState<MaterialDisposal>('haul');
  
  // Dimensions
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [depth, setDepth] = useState('');
  const [slope, setSlope] = useState('0');
  
  // Site conditions
  const [needsClearing, setNeedsClearing] = useState(false);
  const [hasUtilities, setHasUtilities] = useState(false);
  const [needsDewatering, setNeedsDewatering] = useState(false);
  const [requiresPermits, setRequiresPermits] = useState(true);
  const [needsCompaction, setNeedsCompaction] = useState(true);
  const [includeBackfill, setIncludeBackfill] = useState(false);

  // Equipment selection
  const [selectedEquipment, setSelectedEquipment] = useState<string[]>(['excavator']);

  // Current market rates (2024 Lima/Dayton area)
  const rates = {
    equipment: {
      excavator: 150, // per hour
      bulldozer: 175,
      backhoe: 125,
      skidsteer: 100,
      dump_truck: 85
    },
    materials: {
      topsoil: 15, // per cubic yard
      clay: 12,
      sand: 18,
      gravel: 22,
      rock: 35
    },
    labor: {
      operator: 75, // per hour
      laborer: 45,
      supervisor: 85
    },
    fuel: 4.25, // per gallon
    disposal: {
      haul: 45, // per cubic yard
      spread: 15,
      stockpile: 8
    }
  };

  // Additional costs
  const additionalCosts = {
    clearing: 2.50, // per sq ft
    utilities: 750, // flat fee for locating
    dewatering: 1200, // per day
    permits: {
      residential: 350,
      commercial: 750
    },
    compaction: 1.75, // per sq ft
    backfill: {
      material: 22, // per cubic yard
      labor: 35 // per cubic yard
    }
  };

  const calculate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const d = parseFloat(depth);
    const s = parseFloat(slope) / 100;
    
    if (!l || !w || !d) return null;

    // Calculate volumes
    const baseVolume = (l * w * d);
    const slopeVolume = (l * w * d) * s;
    const totalVolume = baseVolume + slopeVolume;
    const cubicYards = totalVolume / 27;

    // Calculate equipment costs
    const equipmentHours = Math.ceil(cubicYards / 25); // Estimate 25 cubic yards per hour
    const equipmentCost = selectedEquipment.reduce((acc, eq) => 
      acc + (rates.equipment[eq as keyof typeof rates.equipment] * equipmentHours), 0);

    // Calculate labor costs
    const laborHours = equipmentHours * selectedEquipment.length;
    const laborCost = (rates.labor.operator * equipmentHours) + 
                     (rates.labor.laborer * laborHours) +
                     (rates.labor.supervisor * (equipmentHours / 2));

    // Calculate fuel costs (estimate 5 gallons per hour per piece of equipment)
    const fuelCost = selectedEquipment.length * equipmentHours * 5 * rates.fuel;

    // Calculate disposal costs
    const disposalCost = cubicYards * rates.disposal[disposalMethod];

    // Calculate additional costs
    const clearingCost = needsClearing ? (l * w * additionalCosts.clearing) : 0;
    const utilitiesCost = hasUtilities ? additionalCosts.utilities : 0;
    const dewateringCost = needsDewatering ? (additionalCosts.dewatering * Math.ceil(equipmentHours / 8)) : 0;
    const permitCost = requiresPermits ? additionalCosts.permits.residential : 0;
    const compactionCost = needsCompaction ? (l * w * additionalCosts.compaction) : 0;
    const backfillCost = includeBackfill ? 
      (cubicYards * (additionalCosts.backfill.material + additionalCosts.backfill.labor)) : 0;

    // Calculate totals
    const subtotal = equipmentCost + laborCost + fuelCost + disposalCost + clearingCost +
                    utilitiesCost + dewateringCost + permitCost + compactionCost + backfillCost;
    const contingency = subtotal * 0.10; // 10% contingency
    const total = subtotal + contingency;

    return {
      dimensions: {
        area: (l * w).toFixed(0),
        volume: cubicYards.toFixed(2)
      },
      time: {
        hours: equipmentHours.toFixed(1),
        days: Math.ceil(equipmentHours / 8)
      },
      costs: {
        equipment: equipmentCost.toFixed(2),
        labor: laborCost.toFixed(2),
        fuel: fuelCost.toFixed(2),
        disposal: disposalCost.toFixed(2),
        clearing: clearingCost.toFixed(2),
        utilities: utilitiesCost.toFixed(2),
        dewatering: dewateringCost.toFixed(2),
        permits: permitCost.toFixed(2),
        compaction: compactionCost.toFixed(2),
        backfill: backfillCost.toFixed(2),
        contingency: contingency.toFixed(2)
      },
      total: total.toFixed(2),
      perCubicYard: (total / cubicYards).toFixed(2)
    };
  };

  const results = calculate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-yellow-600 to-yellow-800 p-6">
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Shovel className="w-8 h-8 text-yellow-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Excavation Calculator</h1>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Project Type</label>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {(['foundation', 'pool', 'driveway', 'basement', 'utilities', 'grading', 'pond'] as const).map((type) => (
                  <button
                    key={type}
                    onClick={() => setProjectType(type)}
                    className={`py-2 px-4 rounded-lg capitalize ${
                      projectType === type
                        ? 'bg-yellow-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Soil Type</label>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {(['topsoil', 'clay', 'sand', 'gravel', 'rock'] as const).map((type) => (
                  <button
                    key={type}
                    onClick={() => setSoilType(type)}
                    className={`py-2 px-4 rounded-lg capitalize ${
                      soilType === type
                        ? 'bg-yellow-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {type}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
                <input
                  type="number"
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
                <input
                  type="number"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Depth (feet)</label>
                <input
                  type="number"
                  value={depth}
                  onChange={(e) => setDepth(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Slope (%)</label>
                <input
                  type="number"
                  value={slope}
                  onChange={(e) => setSlope(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Equipment Needed</label>
              <div className="space-y-2">
                {Object.keys(rates.equipment).map((eq) => (
                  <label key={eq} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={selectedEquipment.includes(eq)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedEquipment([...selectedEquipment, eq]);
                        } else {
                          setSelectedEquipment(selectedEquipment.filter(x => x !== eq));
                        }
                      }}
                      className="rounded text-yellow-600 focus:ring-yellow-500"
                    />
                    <span className="capitalize">{eq.replace('_', ' ')}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Material Disposal</label>
              <div className="grid grid-cols-3 gap-2">
                {(['haul', 'spread', 'stockpile'] as const).map((method) => (
                  <button
                    key={method}
                    onClick={() => setDisposalMethod(method)}
                    className={`py-2 px-4 rounded-lg capitalize ${
                      disposalMethod === method
                        ? 'bg-yellow-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {method}
                  </button>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={needsClearing}
                  onChange={(e) => setNeedsClearing(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Site Clearing Required</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={hasUtilities}
                  onChange={(e) => setHasUtilities(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Utility Location Required</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={needsDewatering}
                  onChange={(e) => setNeedsDewatering(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Dewatering Required</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={requiresPermits}
                  onChange={(e) => setRequiresPermits(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Permits Required</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={needsCompaction}
                  onChange={(e) => setNeedsCompaction(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Soil Compaction Required</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={includeBackfill}
                  onChange={(e) => setIncludeBackfill(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Include Backfill</span>
              </label>
            </div>
          </div>

          <div className="space-y-6">
            {results && (
              <div className="bg-gray-50 rounded-lg p-6">
                <div className="flex items-center justify-center mb-4">
                  <Calculator className="w-6 h-6 text-yellow-600 mr-2" />
                  <h2 className="text-xl font-semibold text-gray-800">Estimate Results</h2>
                </div>

                <div className="space-y-6">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-4 rounded-lg shadow">
                      <div className="flex items-center space-x-2 mb-2">
                        <Mountain className="w-4 h-4 text-yellow-600" />
                        <h3 className="font-semibold text-gray-700">Dimensions</h3>
                      </div>
                      <div className="space-y-1 text-sm">
                        <div className="flex justify-between">
                          <span className="text-gray-600">Area:</span>
                          <span className="font-medium">{results.dimensions.area} sq ft</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-gray-600">Volume:</span>
                          <span className="font-medium">{results.dimensions.volume} cu yd</span>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow">
                      <div className="flex items-center space-x-2 mb-2">
                        <Clock className="w-4 h-4 text-yellow-600" />
                        <h3 className="font-semibold text-gray-700">Time Estimate</h3>
                      </div>
                      <div className="space-y-1 text-sm">
                        <div className="flex justify-between">
                          <span className="text-gray-600">Hours:</span>
                          <span className="font-medium">{results.time.hours}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-gray-600">Days:</span>
                          <span className="font-medium">{results.time.days}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-4 rounded-lg shadow">
                    <div className="flex items-center space-x-2 mb-4">
                      <DollarSign className="w-4 h-4 text-yellow-600" />
                      <h3 className="font-semibold text-gray-700">Cost Breakdown</h3>
                    </div>
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Equipment:</span>
                        <span className="font-medium">${results.costs.equipment}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Labor:</span>
                        <span className="font-medium">${results.costs.labor}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Fuel:</span>
                        <span className="font-medium">${results.costs.fuel}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Disposal:</span>
                        <span className="font-medium">${results.costs.disposal}</span>
                      </div>
                      {parseFloat(results.costs.clearing) > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Clearing:</span>
                          <span className="font-medium">${results.costs.clearing}</span>
                        </div>
                      )}
                      {parseFloat(results.costs.utilities) > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Utilities:</span>
                          <span className="font-medium">${results.costs.utilities}</span>
                        </div>
                      )}
                      {parseFloat(results.costs.dewatering) > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Dewatering:</span>
                          <span className="font-medium">${results.costs.dewatering}</span>
                        </div>
                      )}
                      {parseFloat(results.costs.permits) > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Permits:</span>
                          <span className="font-medium">${results.costs.permits}</span>
                        </div>
                      )}
                      {parseFloat(results.costs.compaction) > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Compaction:</span>
                          <span className="font-medium">${results.costs.compaction}</span>
                        </div>
                      )}
                      {parseFloat(results.costs.backfill) > 0 && (
                        <div className="flex justify-between text-sm">
                          <span className="text-gray-600">Backfill:</span>
                          <span className="font-medium">${results.costs.backfill}</span>
                        </div>
                      )}
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Contingency:</span>
                        <span className="font-medium">${results.costs.contingency}</span>
                      </div>
                      <div className="border-t pt-2 mt-2">
                        <div className="flex justify-between font-semibold">
                          <span>Total Estimate:</span>
                          <span className="text-yellow-600">${results.total}</span>
                        </div>
                        <div className="flex justify-between text-sm text-gray-600 mt-1">
                          <span>Per Cubic Yard:</span>
                          <span>${results.perCubicYard}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="bg-yellow-50 p-4 rounded-lg space-y-2">
              <div className="flex items-start space-x-2">
                <AlertTriangle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                <div className="text-sm text-yellow-800">
                  <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                  <p>* Additional costs may apply for difficult terrain or special requirements.</p>
                  <p>* Professional site evaluation recommended for accurate pricing.</p>
                </div>
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Local Excavation Contractors</h3>
              <div className="space-y-4">
                <div className="border-b pb-4">
                  <h4 className="font-semibold text-yellow-700">
                    <a href="https://bowmanexcavating.com" target="_blank" rel="noopener noreferrer" 
                       className="hover:text-yellow-600">
                      Bowman Excavating
                    </a>
                  </h4>
                  <p className="text-gray-600">(419) 628-3512</p>
                  <p className="text-sm text-gray-500 mt-1">Specializing in residential and commercial excavation</p>
                </div>
              </div>
            </div>

            <div className="prose prose-sm max-w-none">
              <h3 className="text-lg font-semibold text-gray-900">How to Use the Excavation Calculator</h3>
              
              <div className="space-y-4">
                <div>
                  <h4 className="font-semibold text-gray-800">1. Project Details</h4>
                  <ul className="list-disc pl-5 text-gray-700">
                    <li>Select your project type (foundation, pool, etc.)</li>
                    <li>Choose the soil type present at your site</li>
                    <li>Indicate if site clearing or utility location is needed</li>
                  </ul>
                </div>
                
                <div>
                  <h4 className="font-semibold text-gray-800">2. Measurements</h4>
                  <ul className="list-disc pl-5 text-gray-700">
                    <li>Enter length and width in feet</li>
                    <li>Specify depth in feet</li>
                    <li>Add slope percentage if applicable</li>
                  </ul>
                </div>
                
                <div>
                  <h4 className="font-semibold text-gray-800">3. Equipment & Services</h4>
                  <ul className="list-disc pl-5 text-gray-700">
                    <li>Select required equipment</li>
                    <li>Choose material disposal method</li>
                    <li>Add optional services like compaction or backfill</li>
                  </ul>
                </div>
                
                <div>
                  <h4 className="font-semibold text-gray-800">Understanding Results</h4>
                  <ul className="list-disc pl-5 text-gray-700">
                    <li>Review volume calculations</li>
                    <li>Check estimated timeline</li>
                    <li>Examine detailed cost breakdown</li>
                    <li>Note contingency for unexpected issues</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}