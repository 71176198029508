import type { WindowConfig, WindowEstimate } from '../types/windows';

// Base costs for different window types (2024 Lima/Dayton area rates)
const baseCosts = {
  single: {
    economy: { vinyl: 250, wood: 350, aluminum: 300, fiberglass: 400 },
    standard: { vinyl: 450, wood: 650, aluminum: 500, fiberglass: 750 },
    premium: { vinyl: 850, wood: 1200, aluminum: 900, fiberglass: 1400 }
  },
  double: {
    economy: { vinyl: 300, wood: 400, aluminum: 350, fiberglass: 450 },
    standard: { vinyl: 500, wood: 700, aluminum: 550, fiberglass: 800 },
    premium: { vinyl: 900, wood: 1300, aluminum: 950, fiberglass: 1500 }
  },
  casement: {
    economy: { vinyl: 350, wood: 450, aluminum: 400, fiberglass: 500 },
    standard: { vinyl: 550, wood: 750, aluminum: 600, fiberglass: 850 },
    premium: { vinyl: 950, wood: 1400, aluminum: 1000, fiberglass: 1600 }
  },
  sliding: {
    economy: { vinyl: 275, wood: 375, aluminum: 325, fiberglass: 425 },
    standard: { vinyl: 475, wood: 675, aluminum: 525, fiberglass: 775 },
    premium: { vinyl: 875, wood: 1250, aluminum: 925, fiberglass: 1450 }
  },
  bay: {
    economy: { vinyl: 1200, wood: 1500, aluminum: 1300, fiberglass: 1600 },
    standard: { vinyl: 1800, wood: 2200, aluminum: 1900, fiberglass: 2400 },
    premium: { vinyl: 2500, wood: 3000, aluminum: 2600, fiberglass: 3200 }
  },
  picture: {
    economy: { vinyl: 400, wood: 500, aluminum: 450, fiberglass: 550 },
    standard: { vinyl: 600, wood: 800, aluminum: 650, fiberglass: 900 },
    premium: { vinyl: 1000, wood: 1500, aluminum: 1100, fiberglass: 1600 }
  }
};

// Glass costs per square foot
const glassCosts = {
  standard: 12,
  lowE: 18,
  triple: 25,
  argon: 22
};

// Additional costs
const additionalCosts = {
  frame: {
    economy: 100,
    standard: 175,
    premium: 300
  },
  hardware: {
    economy: 50,
    standard: 125,
    premium: 250
  },
  screens: {
    economy: 35,
    standard: 50,
    premium: 75
  },
  grilles: {
    colonial: 75,
    prairie: 85,
    diamond: 95,
    custom: 125
  },
  structuralWork: {
    minor: 300,
    major: 800
  },
  finishing: {
    materials: {
      economy: 35,
      standard: 55,
      premium: 85
    },
    labor: {
      economy: 45,
      standard: 65,
      premium: 95
    }
  },
  installation: {
    basic: 150,
    complex: 250
  }
};

export function calculateWindowEstimate(configs: WindowConfig[]) {
  const estimates = configs.map(config => {
    // Get base cost for window type, material and grade
    const baseCost = baseCosts[config.type][config.grade][config.material];
    
    // Calculate size multiplier
    const standardSize = config.type === 'bay' ? 48 : 24; // Standard sizes in sq ft
    const actualSize = (parseFloat(config.details.width) * parseFloat(config.details.height)) / 144;
    const sizeMultiplier = Math.max(1, actualSize / standardSize);

    // Calculate quantity costs
    const quantity = parseInt(config.details.quantity) || 1;
    const windowCost = baseCost * sizeMultiplier * quantity;

    // Calculate glass costs
    const glassArea = actualSize * quantity;
    const glassCost = glassArea * glassCosts[config.glass];

    // Calculate frame cost if included
    const frameCost = config.details.includeFrame ? 
      additionalCosts.frame[config.grade] * quantity : 0;

    // Calculate hardware cost if included
    const hardwareCost = config.details.includeHardware ?
      additionalCosts.hardware[config.grade] * quantity : 0;

    // Calculate screen cost if included
    const screenCost = config.details.includeScreens ?
      additionalCosts.screens[config.grade] * quantity : 0;

    // Calculate grille cost if included
    const grilleCost = config.details.includeGrilles && config.details.grillPattern ?
      additionalCosts.grilles[config.details.grillPattern as keyof typeof additionalCosts.grilles] * quantity : 0;

    // Calculate structural work cost if needed
    const structuralCost = config.details.needsStructuralWork ?
      additionalCosts.structuralWork.major : 0;

    // Calculate finishing cost if included
    const finishingCost = config.details.includeFinishing ?
      ((additionalCosts.finishing.materials[config.details.paintGrade || 'standard'] +
        additionalCosts.finishing.labor[config.details.paintGrade || 'standard']) * quantity) : 0;

    // Calculate installation cost
    const installCost = additionalCosts.installation[config.type === 'bay' ? 'complex' : 'basic'] * quantity;

    // Calculate total for this window configuration
    const subtotal = windowCost + glassCost + frameCost + hardwareCost + 
                    screenCost + grilleCost + structuralCost + finishingCost + installCost;
    const tax = subtotal * 0.075; // 7.5% sales tax
    const total = subtotal + tax;

    return {
      windowCost,
      installCost,
      glassCost,
      frameCost,
      hardwareCost,
      structuralCost,
      finishingCost,
      tax,
      total,
      details: {
        type: config.type,
        material: config.material,
        grade: config.grade,
        quantity,
        size: {
          width: config.details.width,
          height: config.details.height,
          area: actualSize.toFixed(2)
        }
      }
    };
  });

  return {
    estimates,
    totalCost: estimates.reduce((sum, estimate) => sum + estimate.total, 0)
  };
}