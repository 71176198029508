import React, { useState } from 'react';
import { Shield, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type HomeType = 'single-family' | 'condo' | 'townhouse' | 'multi-family';
type Construction = 'frame' | 'masonry' | 'steel' | 'mixed';
type RoofType = 'asphalt' | 'metal' | 'tile' | 'slate';
type Coverage = 'basic' | 'standard' | 'premium';

export default function HomeInsuranceCalculator() {
  // Home details
  const [homeType, setHomeType] = useState<HomeType>('single-family');
  const [constructionType, setConstructionType] = useState<Construction>('frame');
  const [roofType, setRoofType] = useState<RoofType>('asphalt');
  const [homeValue, setHomeValue] = useState('');
  const [squareFeet, setSquareFeet] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [coverageLevel, setCoverageLevel] = useState<Coverage>('standard');
  
  // Safety features
  const [hasSecuritySystem, setHasSecuritySystem] = useState(false);
  const [hasSprinklers, setHasSprinklers] = useState(false);
  const [hasSmartDevices, setHasSmartDevices] = useState(false);
  const [hasGenerator, setHasGenerator] = useState(false);
  
  // Risk factors
  const [inFloodZone, setInFloodZone] = useState(false);
  const [distanceFromStation, setDistanceFromStation] = useState('5');
  const [claimHistory, setClaimHistory] = useState('0');
  const [creditScore, setCreditScore] = useState('good');

  // Additional coverage
  const [personalProperty, setPersonalProperty] = useState(true);
  const [liabilityLimit, setLiabilityLimit] = useState('300000');
  const [medicalPayments, setMedicalPayments] = useState(true);
  const [lossOfUse, setLossOfUse] = useState(true);
  const [additionalEndorsements, setAdditionalEndorsements] = useState<string[]>([]);

  // Base rates per $1000 of coverage (2024 Lima/Dayton area)
  const baseRates = {
    'single-family': 3.50,
    'condo': 2.75,
    'townhouse': 3.00,
    'multi-family': 4.00
  };

  // Construction type multipliers
  const constructionMultipliers = {
    frame: 1.0,
    masonry: 0.95,
    steel: 0.90,
    mixed: 0.97
  };

  // Roof type multipliers
  const roofMultipliers = {
    asphalt: 1.0,
    metal: 0.95,
    tile: 0.93,
    slate: 0.90
  };

  // Coverage level multipliers
  const coverageMultipliers = {
    basic: 0.85,
    standard: 1.0,
    premium: 1.25
  };

  // Safety feature discounts
  const safetyDiscounts = {
    securitySystem: 0.05,
    sprinklers: 0.07,
    smartDevices: 0.03,
    generator: 0.02
  };

  // Additional coverage costs
  const additionalCosts = {
    flood: {
      zoneA: 0.45,
      zoneX: 0.20
    },
    liability: {
      '100000': 50,
      '300000': 100,
      '500000': 175,
      '1000000': 300
    },
    personalProperty: 0.15, // per $1000 of coverage
    medicalPayments: 75, // flat fee
    lossOfUse: 0.10, // percentage of dwelling coverage
    endorsements: {
      waterBackup: 75,
      equipment: 50,
      jewelry: 125,
      business: 150
    }
  };

  const calculatePremium = () => {
    const value = parseFloat(homeValue);
    if (!value) return null;

    // Calculate base premium
    const baseRate = baseRates[homeType];
    const basePremium = (value / 1000) * baseRate;

    // Apply construction and roof multipliers
    const constructionFactor = constructionMultipliers[constructionType];
    const roofFactor = roofMultipliers[roofType];
    
    // Apply coverage level multiplier
    const coverageFactor = coverageMultipliers[coverageLevel];

    // Calculate age factor
    const year = parseInt(yearBuilt);
    const currentYear = new Date().getFullYear();
    const age = currentYear - year;
    const ageFactor = Math.min(1.5, Math.max(1.0, 1 + (age * 0.01))); // 1% increase per year, max 50%

    // Apply safety discounts
    let discountTotal = 0;
    if (hasSecuritySystem) discountTotal += safetyDiscounts.securitySystem;
    if (hasSprinklers) discountTotal += safetyDiscounts.sprinklers;
    if (hasSmartDevices) discountTotal += safetyDiscounts.smartDevices;
    if (hasGenerator) discountTotal += safetyDiscounts.generator;

    // Calculate risk adjustments
    const floodCost = inFloodZone ? ((value / 1000) * additionalCosts.flood.zoneA) : 0;
    
    const distanceFactor = Math.min(1.3, Math.max(1.0, 1 + 
      (parseFloat(distanceFromStation) - 5) * 0.02)); // 2% increase per mile over 5
    
    const claimsFactor = 1 + (parseInt(claimHistory) * 0.15); // 15% increase per claim
    
    const creditFactors = {
      excellent: 0.9,
      good: 1.0,
      fair: 1.1,
      poor: 1.25
    };
    const creditFactor = creditFactors[creditScore as keyof typeof creditFactors];

    // Calculate additional coverage costs
    const liabilityCost = additionalCosts.liability[liabilityLimit as keyof typeof additionalCosts.liability];
    const personalPropertyCost = personalProperty ? ((value * 0.5 / 1000) * additionalCosts.personalProperty) : 0;
    const medicalCost = medicalPayments ? additionalCosts.medicalPayments : 0;
    const lossOfUseCost = lossOfUse ? (value * additionalCosts.lossOfUse) : 0;
    
    const endorsementsCost = additionalEndorsements.reduce((total, endorsement) => 
      total + additionalCosts.endorsements[endorsement as keyof typeof additionalCosts.endorsements], 0);

    // Calculate final premium
    const adjustedPremium = basePremium * constructionFactor * roofFactor * 
                           coverageFactor * ageFactor * (1 - discountTotal) * 
                           distanceFactor * claimsFactor * creditFactor;

    const totalPremium = adjustedPremium + floodCost + liabilityCost + 
                        personalPropertyCost + medicalCost + lossOfUseCost + 
                        endorsementsCost;

    // Calculate monthly payment
    const monthlyPayment = totalPremium / 12;

    return {
      base: basePremium.toFixed(2),
      adjustments: {
        construction: (basePremium * (constructionFactor - 1)).toFixed(2),
        roof: (basePremium * (roofFactor - 1)).toFixed(2),
        age: (basePremium * (ageFactor - 1)).toFixed(2),
        discounts: (basePremium * discountTotal).toFixed(2),
        risk: (basePremium * (distanceFactor * claimsFactor * creditFactor - 1)).toFixed(2)
      },
      additional: {
        flood: floodCost.toFixed(2),
        liability: liabilityCost.toFixed(2),
        property: personalPropertyCost.toFixed(2),
        medical: medicalCost.toFixed(2),
        lossOfUse: lossOfUseCost.toFixed(2),
        endorsements: endorsementsCost.toFixed(2)
      },
      total: totalPremium.toFixed(2),
      monthly: monthlyPayment.toFixed(2)
    };
  };

  const results = calculatePremium();

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 to-blue-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Shield className="w-8 h-8 text-blue-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Home Insurance Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Home Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['single-family', 'condo', 'townhouse', 'multi-family'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setHomeType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    homeType === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type.replace('-', ' ')}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Construction Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['frame', 'masonry', 'steel', 'mixed'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setConstructionType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    constructionType === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Roof Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['asphalt', 'metal', 'tile', 'slate'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setRoofType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    roofType === type
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Home Value</label>
              <input
                type="number"
                value={homeValue}
                onChange={(e) => setHomeValue(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter value"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Square Feet</label>
              <input
                type="number"
                value={squareFeet}
                onChange={(e) => setSquareFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter area"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Year Built</label>
              <input
                type="number"
                value={yearBuilt}
                onChange={(e) => setYearBuilt(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter year"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Coverage Level</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'standard', 'premium'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setCoverageLevel(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    coverageLevel === level
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Distance from Fire Station (miles)</label>
              <input
                type="number"
                value={distanceFromStation}
                onChange={(e) => setDistanceFromStation(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Claims in Past 5 Years</label>
              <input
                type="number"
                value={claimHistory}
                onChange={(e) => setClaimHistory(e.target.value)}
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Credit Score Range</label>
            <select
              value={creditScore}
              onChange={(e) => setCreditScore(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
            >
              <option value="excellent">Excellent (750+)</option>
              <option value="good">Good (670-749)</option>
              <option value="fair">Fair (580-669)</option>
              <option value="poor">Poor (Below 580)</option>
            </select>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasSecuritySystem}
                onChange={(e) => setHasSecuritySystem(e.target.checked)}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <span>Security System Installed</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasSprinklers}
                onChange={(e) => setHasSprinklers(e.target.checked)}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <span>Fire Sprinkler System</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasSmartDevices}
                onChange={(e) => setHasSmartDevices(e.target.checked)}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <span>Smart Home Devices</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasGenerator}
                onChange={(e) => setHasGenerator(e.target.checked)}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <span>Backup Generator</span>
            </label>
          </div>

          <div className="border-t pt-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Additional Coverage Options</h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Liability Coverage</label>
                <select
                  value={liabilityLimit}
                  onChange={(e) => setLiabilityLimit(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                >
                  <option value="100000">$100,000</option>
                  <option value="300000">$300,000</option>
                  <option value="500000">$500,000</option>
                  <option value="1000000">$1,000,000</option>
                </select>
              </div>

              <div className="flex flex-col space-y-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={personalProperty}
                    onChange={(e) => setPersonalProperty(e.target.checked)}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span>Personal Property Coverage</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={medicalPayments}
                    onChange={(e) => setMedicalPayments(e.target.checked)}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span>Medical Payments Coverage</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={lossOfUse}
                    onChange={(e) => setLossOfUse(e.target.checked)}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span>Loss of Use Coverage</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={inFloodZone}
                    onChange={(e) => setInFloodZone(e.target.checked)}
                    className="rounded text-blue-600 focus:ring-blue-500"
                  />
                  <span>Property in Flood Zone</span>
                </label>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Additional Endorsements</label>
                <div className="space-y-2">
                  {Object.keys(additionalCosts.endorsements).map((endorsement) => (
                    <label key={endorsement} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={additionalEndorsements.includes(endorsement)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAdditionalEndorsements([...additionalEndorsements, endorsement]);
                          } else {
                            setAdditionalEndorsements(additionalEndorsements.filter(e => e !== endorsement));
                          }
                        }}
                        className="rounded text-blue-600 focus:ring-blue-500"
                      />
                      <span className="capitalize">{endorsement.replace(/([A-Z])/g, ' $1').trim()}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-blue-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Premium Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Premium:</span>
                    <span className="font-semibold">${results.base}</span>
                  </div>
                  {Object.entries(results.adjustments).map(([key, value]) => 
                    parseFloat(value) !== 0 && (
                      <div key={key} className="flex justify-between">
                        <span className="text-gray-600 capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
                        <span className={`font-semibold ${parseFloat(value) < 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {parseFloat(value) < 0 ? '-' : '+'}${Math.abs(parseFloat(value)).toFixed(2)}
                        </span>
                      </div>
                    )
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  {Object.entries(results.additional).map(([key, value]) => 
                    parseFloat(value) > 0 && (
                      <div key={key} className="flex justify-between">
                        <span className="text-gray-600 capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
                        <span className="font-semibold">${value}</span>
                      </div>
                    )
                  )}
                </div>

                <div className="border-t pt-4 flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <DollarSign className="w-5 h-5 text-blue-600 mr-2" />
                      <span className="font-bold">Annual Premium:</span>
                    </div>
                    <span className="text-2xl font-bold text-blue-600">${results.total}</span>
                  </div>
                  <div className="text-center text-gray-600">
                    Monthly Payment: ${results.monthly}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-blue-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-800">
                <p>* Estimates based on average Lima/Dayton area insurance rates.</p>
                <p>* Actual premiums may vary based on insurance provider and specific policy terms.</p>
                <p>* Additional discounts may be available based on bundling with other policies.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}