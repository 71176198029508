import React, { useState } from 'react';
import { ChefHat, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type CabinetType = 'stock' | 'semi-custom' | 'custom';
type CountertopType = 'laminate' | 'granite' | 'quartz' | 'marble' | 'butcher';
type ApplianceGrade = 'basic' | 'mid-range' | 'premium';

export default function KitchenCalculator() {
  // State for kitchen details
  const [cabinetType, setCabinetType] = useState<CabinetType>('semi-custom');
  const [countertopType, setCountertopType] = useState<CountertopType>('granite');
  const [applianceGrade, setApplianceGrade] = useState<ApplianceGrade>('mid-range');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [linearFeet, setLinearFeet] = useState('');
  const [includeIsland, setIncludeIsland] = useState(false);
  const [includeBacksplash, setIncludeBacksplash] = useState(true);
  const [includeLighting, setIncludeLighting] = useState(true);
  const [includeFlooring, setIncludeFlooring] = useState(true);
  const [includePlumbing, setIncludePlumbing] = useState(true);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const cabinetCosts = {
    stock: 200, // per linear foot
    'semi-custom': 350,
    custom: 650
  };

  const countertopCosts = {
    laminate: 35, // per square foot
    granite: 65,
    quartz: 85,
    marble: 95,
    butcher: 55
  };

  const applianceCosts = {
    basic: {
      refrigerator: 1200,
      range: 800,
      dishwasher: 500,
      microwave: 300
    },
    'mid-range': {
      refrigerator: 2500,
      range: 1500,
      dishwasher: 800,
      microwave: 500
    },
    premium: {
      refrigerator: 4500,
      range: 3500,
      dishwasher: 1200,
      microwave: 800
    }
  };

  const additionalCosts = {
    island: {
      base: 2500,
      plumbing: 800,
      electrical: 500
    },
    backsplash: {
      tile: 25, // per square foot
      installation: 15
    },
    lighting: {
      recessed: 150, // per light
      pendant: 250,
      under_cabinet: 45 // per foot
    },
    flooring: {
      material: 8, // per square foot
      installation: 6
    },
    plumbing: {
      sink: 400,
      faucet: 250,
      installation: 800
    },
    electrical: {
      basic: 1500,
      upgraded: 2500
    },
    permit: 450,
    demolition: 2500,
    disposal: 750
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const lf = parseFloat(linearFeet);
    if (!l || !w || !lf) return null;

    // Calculate areas
    const floorArea = l * w;
    const backsplashArea = lf * 1.5; // Assuming 18" backsplash height
    
    // Calculate cabinet costs
    const cabinetCost = lf * cabinetCosts[cabinetType];
    
    // Calculate countertop area and costs
    const countertopDepth = 25.5 / 12; // Convert 25.5 inches to feet
    const countertopArea = lf * countertopDepth;
    const islandCountertopArea = includeIsland ? 3 * 6 : 0; // Standard 3x6 island
    const totalCountertopArea = countertopArea + islandCountertopArea;
    const countertopCost = totalCountertopArea * countertopCosts[countertopType];

    // Calculate appliance costs
    const appliances = applianceCosts[applianceGrade];
    const applianceCost = Object.values(appliances).reduce((sum, cost) => sum + cost, 0);

    // Calculate additional features
    const islandCost = includeIsland ?
      (additionalCosts.island.base + additionalCosts.island.plumbing + additionalCosts.island.electrical) : 0;

    const backsplashCost = includeBacksplash ?
      (backsplashArea * (additionalCosts.backsplash.tile + additionalCosts.backsplash.installation)) : 0;

    const lightingCost = includeLighting ?
      ((Math.ceil(floorArea / 30) * additionalCosts.lighting.recessed) + // One light per 30 sq ft
       (lf * additionalCosts.lighting.under_cabinet)) : 0;

    const flooringCost = includeFlooring ?
      (floorArea * (additionalCosts.flooring.material + additionalCosts.flooring.installation)) : 0;

    const plumbingCost = includePlumbing ?
      (additionalCosts.plumbing.sink + additionalCosts.plumbing.faucet + additionalCosts.plumbing.installation) : 0;

    // Calculate electrical and permit costs
    const electricalCost = additionalCosts.electrical[includeIsland ? 'upgraded' : 'basic'];
    const permitCost = includePermit ? additionalCosts.permit : 0;

    // Calculate demolition and disposal
    const demoCost = additionalCosts.demolition + additionalCosts.disposal;

    // Calculate totals
    const subtotal = cabinetCost + countertopCost + applianceCost + islandCost +
                    backsplashCost + lightingCost + flooringCost + plumbingCost +
                    electricalCost + permitCost + demoCost;
    const tax = (cabinetCost + countertopCost + applianceCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        floor: floorArea.toFixed(0),
        countertop: totalCountertopArea.toFixed(1)
      },
      costs: {
        cabinets: cabinetCost.toFixed(2),
        countertops: countertopCost.toFixed(2),
        appliances: applianceCost.toFixed(2),
        island: islandCost.toFixed(2),
        backsplash: backsplashCost.toFixed(2),
        lighting: lightingCost.toFixed(2),
        flooring: flooringCost.toFixed(2),
        plumbing: plumbingCost.toFixed(2),
        electrical: electricalCost.toFixed(2),
        demo: demoCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-red-600 to-red-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <ChefHat className="w-8 h-8 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Kitchen Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Cabinet Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['stock', 'semi-custom', 'custom'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCabinetType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    cabinetType === type
                      ? 'bg-red-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Countertop Material</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['laminate', 'granite', 'quartz', 'marble', 'butcher'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCountertopType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    countertopType === type
                      ? 'bg-red-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'butcher' ? 'Butcher Block' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Appliance Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'mid-range', 'premium'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setApplianceGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    applianceGrade === grade
                      ? 'bg-red-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring focus:ring-red-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring focus:ring-red-200"
                placeholder="Enter width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Cabinet Run (feet)</label>
              <input
                type="number"
                value={linearFeet}
                onChange={(e) => setLinearFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring focus:ring-red-200"
                placeholder="Linear feet of cabinets"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeIsland}
                onChange={(e) => setIncludeIsland(e.target.checked)}
                className="rounded text-red-600 focus:ring-red-500"
              />
              <span>Include Kitchen Island</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBacksplash}
                onChange={(e) => setIncludeBacksplash(e.target.checked)}
                className="rounded text-red-600 focus:ring-red-500"
              />
              <span>Include Backsplash</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeLighting}
                onChange={(e) => setIncludeLighting(e.target.checked)}
                className="rounded text-red-600 focus:ring-red-500"
              />
              <span>Include New Lighting</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeFlooring}
                onChange={(e) => setIncludeFlooring(e.target.checked)}
                className="rounded text-red-600 focus:ring-red-500"
              />
              <span>Include New Flooring</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePlumbing}
                onChange={(e) => setIncludePlumbing(e.target.checked)}
                className="rounded text-red-600 focus:ring-red-500"
              />
              <span>Include Plumbing Updates</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-red-600 focus:ring-red-500"
              />
              <span>Include Permit</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-red-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Kitchen Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.floor} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Cabinets:</span>
                    <span className="font-semibold">${results.costs.cabinets}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Countertops:</span>
                    <span className="font-semibold">${results.costs.countertops}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Appliances:</span>
                    <span className="font-semibold">${results.costs.appliances}</span>
                  </div>
                  {parseFloat(results.costs.island) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Island:</span>
                      <span className="font-semibold">${results.costs.island}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.backsplash) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Backsplash:</span>
                      <span className="font-semibold">${results.costs.backsplash}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.lighting) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Lighting:</span>
                      <span className="font-semibold">${results.costs.lighting}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.flooring) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Flooring:</span>
                      <span className="font-semibold">${results.costs.flooring}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.plumbing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Plumbing:</span>
                      <span className="font-semibold">${results.costs.plumbing}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Electrical:</span>
                    <span className="font-semibold">${results.costs.electrical}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Demo & Disposal:</span>
                    <span className="font-semibold">${results.costs.demo}</span>
                  </div>
                </div>

                <div className="border-t pt-4 space-y-2">
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-red-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-red-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-red-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-red-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-red-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for structural changes or special features.</p>
                <p>* All kitchen remodels require proper permits and inspections.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Kitchen Remodel Costs</h3>
            <p className="text-gray-700">
              Kitchen remodeling costs in the Lima/Dayton area vary based on several factors including size, materials, and additional features. Basic remodels typically range from $30,000 to $75,000, with luxury finishes and custom features increasing costs.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Cabinet quality and construction</li>
              <li>Countertop material durability</li>
              <li>Appliance efficiency ratings</li>
              <li>Electrical and plumbing requirements</li>
              <li>Layout changes and structural needs</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term value when selecting materials and appliances.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}