import React, { useState } from 'react';
import { Home, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

export default function HomeValueCalculator() {
  // Base property details
  const [squareFeet, setSquareFeet] = useState('2000');
  const [bedrooms, setBedrooms] = useState('3');
  const [bathrooms, setBathrooms] = useState('2');
  const [yearBuilt, setYearBuilt] = useState('1990');
  const [condition, setCondition] = useState<'poor' | 'fair' | 'good' | 'excellent'>('good');
  const [lotSize, setLotSize] = useState('0.25');
  
  // Core features
  const [hasGarage, setHasGarage] = useState(true);
  const [basementType, setBasementType] = useState<'none' | 'unfinished' | 'finished'>('unfinished');
  const [poolType, setPoolType] = useState<'none' | 'inground' | 'aboveground'>('none');

  // High-ROI improvements
  const [hasNewDoors, setHasNewDoors] = useState<'none' | 'entry' | 'patio' | 'garage' | 'interior'>('none');
  const [windowCount, setWindowCount] = useState('0');
  const [windowType, setWindowType] = useState<'standard' | 'energyEfficient'>('standard');
  const [kitchenRemodel, setKitchenRemodel] = useState<'none' | 'minor' | 'major' | 'luxury'>('none');
  const [bathroomRemodel, setBathroomRemodel] = useState<'none' | 'minor' | 'major' | 'luxury'>('none');
  const [sidingType, setSidingType] = useState<'none' | 'vinyl' | 'fiber' | 'brick'>('none');
  const [roofingType, setRoofingType] = useState<'none' | 'asphalt' | 'metal' | 'slate'>('none');
  const [hvacType, setHvacType] = useState<'none' | 'standard' | 'highEfficiency'>('none');
  const [landscapingType, setLandscapingType] = useState<'none' | 'basic' | 'premium'>('none');

  const featurePremiums = {
    garage: 25000,
    basement: {
      unfinished: 15000,
      finished: 35000,
      none: 0
    },
    pool: {
      inground: 45000,
      aboveground: 15000,
      none: 0
    },
    doors: {
      entry: 2500,
      patio: 3500,
      garage: 2000,
      interior: 800,
      none: 0
    },
    windows: {
      standard: 600,
      energyEfficient: 850
    },
    kitchen: {
      minor: 15000,
      major: 45000,
      luxury: 75000,
      none: 0
    },
    bathroom: {
      minor: 12000,
      major: 25000,
      luxury: 40000,
      none: 0
    },
    siding: {
      vinyl: 12000,
      fiber: 15000,
      brick: 25000,
      none: 0
    },
    roofing: {
      asphalt: 8000,
      metal: 15000,
      slate: 25000,
      none: 0
    },
    hvac: {
      standard: 7500,
      highEfficiency: 12000,
      none: 0
    },
    landscaping: {
      basic: 5000,
      premium: 15000,
      none: 0
    }
  };

  const calculateBaseValue = () => {
    const sqft = parseFloat(squareFeet) || 0;
    const beds = parseInt(bedrooms) || 0;
    const baths = parseFloat(bathrooms) || 0;
    const year = parseInt(yearBuilt) || 0;
    const lot = parseFloat(lotSize) || 0;

    // Base calculation using square footage
    const baseValue = sqft * 150; // Base rate of $150 per sqft

    // Condition adjustments
    const conditionMultipliers = {
      poor: 0.8,
      fair: 0.9,
      good: 1.0,
      excellent: 1.1
    };
    const conditionValue = baseValue * conditionMultipliers[condition];

    // Age adjustment
    const currentYear = new Date().getFullYear();
    const age = currentYear - year;
    const ageAdjustment = age > 0 ? Math.max(0.7, 1 - (age * 0.005)) : 1;
    const adjustedBaseValue = conditionValue * ageAdjustment;

    // Feature premiums
    const garagePremium = hasGarage ? featurePremiums.garage : 0;
    const basementPremium = featurePremiums.basement[basementType];
    const poolPremium = featurePremiums.pool[poolType];
    
    // Calculate lot value
    const lotValue = lot * 58000; // Average value per acre

    // High-ROI improvements
    const doorPremium = featurePremiums.doors[hasNewDoors];
    const windowPremium = parseInt(windowCount) * featurePremiums.windows[windowType];
    const kitchenPremium = featurePremiums.kitchen[kitchenRemodel];
    const bathroomPremium = featurePremiums.bathroom[bathroomRemodel];
    const sidingPremium = featurePremiums.siding[sidingType] || 0;
    const roofingPremium = featurePremiums.roofing[roofingType] || 0;
    const hvacPremium = featurePremiums.hvac[hvacType];
    const landscapingPremium = featurePremiums.landscaping[landscapingType];

    // Calculate total value
    const totalValue = adjustedBaseValue + garagePremium + basementPremium + poolPremium + lotValue +
                      doorPremium + windowPremium + kitchenPremium + bathroomPremium +
                      sidingPremium + roofingPremium + hvacPremium + landscapingPremium;

    return {
      base: baseValue.toFixed(2),
      condition: conditionValue.toFixed(2),
      age: adjustedBaseValue.toFixed(2),
      features: {
        garage: garagePremium.toFixed(2),
        basement: basementPremium.toFixed(2),
        pool: poolPremium.toFixed(2),
        doors: doorPremium.toFixed(2),
        windows: windowPremium.toFixed(2),
        kitchen: kitchenPremium.toFixed(2),
        bathroom: bathroomPremium.toFixed(2),
        siding: sidingPremium.toFixed(2),
        roofing: roofingPremium.toFixed(2),
        hvac: hvacPremium.toFixed(2),
        landscaping: landscapingPremium.toFixed(2)
      },
      lot: lotValue.toFixed(2),
      total: totalValue.toFixed(2)
    };
  };

  const results = calculateBaseValue();

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-600 to-green-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Home className="w-8 h-8 text-green-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Home Value Calculator</h1>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Square Feet</label>
              <input
                type="number"
                value={squareFeet}
                onChange={(e) => setSquareFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Lot Size (acres)</label>
              <input
                type="number"
                value={lotSize}
                onChange={(e) => setLotSize(e.target.value)}
                step="0.01"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              />
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Bedrooms</label>
              <input
                type="number"
                value={bedrooms}
                onChange={(e) => setBedrooms(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Bathrooms</label>
              <input
                type="number"
                value={bathrooms}
                onChange={(e) => setBathrooms(e.target.value)}
                step="0.5"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Year Built</label>
              <input
                type="number"
                value={yearBuilt}
                onChange={(e) => setYearBuilt(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Condition</label>
            <div className="grid grid-cols-4 gap-2">
              {(['poor', 'fair', 'good', 'excellent'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCondition(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    condition === type
                      ? 'bg-green-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasGarage}
                onChange={(e) => setHasGarage(e.target.checked)}
                className="rounded text-green-600 focus:ring-green-500"
              />
              <span>Has Garage</span>
            </label>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Basement</label>
              <select
                value={basementType}
                onChange={(e) => setBasementType(e.target.value as typeof basementType)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              >
                <option value="none">No Basement</option>
                <option value="unfinished">Unfinished</option>
                <option value="finished">Finished</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Pool</label>
              <select
                value={poolType}
                onChange={(e) => setPoolType(e.target.value as typeof poolType)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
              >
                <option value="none">No Pool</option>
                <option value="aboveground">Above Ground</option>
                <option value="inground">In-Ground</option>
              </select>
            </div>
          </div>

          <div className="border-t pt-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">High-ROI Improvements</h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">New Doors</label>
                <select
                  value={hasNewDoors}
                  onChange={(e) => setHasNewDoors(e.target.value as typeof hasNewDoors)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                >
                  <option value="none">None</option>
                  <option value="entry">New Entry Door</option>
                  <option value="patio">New Patio Door</option>
                  <option value="garage">New Garage Door</option>
                  <option value="interior">New Interior Doors</option>
                </select>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Window Count</label>
                  <input
                    type="number"
                    value={windowCount}
                    onChange={(e) => setWindowCount(e.target.value)}
                    min="0"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Window Type</label>
                  <select
                    value={windowType}
                    onChange={(e) => setWindowType(e.target.value as typeof windowType)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="standard">Standard Windows</option>
                    <option value="energyEfficient">Energy Efficient Windows</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Kitchen Remodel</label>
                  <select
                    value={kitchenRemodel}
                    onChange={(e) => setKitchenRemodel(e.target.value as typeof kitchenRemodel)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="none">None</option>
                    <option value="minor">Minor Update</option>
                    <option value="major">Major Remodel</option>
                    <option value="luxury">Luxury Renovation</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Bathroom Remodel</label>
                  <select
                    value={bathroomRemodel}
                    onChange={(e) => setBathroomRemodel(e.target.value as typeof bathroomRemodel)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="none">None</option>
                    <option value="minor">Minor Update</option>
                    <option value="major">Major Remodel</option>
                    <option value="luxury">Luxury Renovation</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Siding</label>
                  <select
                    value={sidingType}
                    onChange={(e) => setSidingType(e.target.value as typeof sidingType)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="none">None</option>
                    <option value="vinyl">Vinyl Siding</option>
                    <option value="fiber">Fiber Cement</option>
                    <option value="brick">Brick Veneer</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Roofing</label>
                  <select
                    value={roofingType}
                    onChange={(e) => setRoofingType(e.target.value as typeof roofingType)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="none">None</option>
                    <option value="asphalt">Asphalt Shingles</option>
                    <option value="metal">Metal Roof</option>
                    <option value="slate">Slate Roof</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">HVAC System</label>
                  <select
                    value={hvacType}
                    onChange={(e) => setHvacType(e.target.value as typeof hvacType)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="none">None</option>
                    <option value="standard">Standard Efficiency</option>
                    <option value="highEfficiency">High Efficiency</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Landscaping</label>
                  <select
                    value={landscapingType}
                    onChange={(e) => setLandscapingType(e.target.value as typeof landscapingType)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-200"
                  >
                    <option value="none">None</option>
                    <option value="basic">Basic Landscaping</option>
                    <option value="premium">Premium Landscaping</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-6 mt-6">
            <div className="flex items-center justify-center mb-4">
              <Calculator className="w-6 h-6 text-green-600 mr-2" />
              <h2 className="text-xl font-semibold text-gray-800">Value Estimate</h2>
            </div>

            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="text-gray-600">Base Value:</div>
                <div className="font-semibold text-right">${results.base}</div>
                <div className="text-gray-600">Condition Adjusted:</div>
                <div className="font-semibold text-right">${results.condition}</div>
                <div className="text-gray-600">Age Adjusted:</div>
                <div className="font-semibold text-right">${results.age}</div>
              </div>

              <div className="border-t pt-4">
                <h3 className="font-semibold text-gray-800 mb-2">Features & Improvements</h3>
                {Object.entries(results.features).map(([key, value]) => 
                  parseFloat(value) > 0 && (
                    <div key={key} className="flex justify-between">
                      <span className="text-gray-600 capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
                      <span className="font-semibold">${value}</span>
                    </div>
                  )
                )}
              </div>

              <div className="border-t pt-4">
                <div className="flex justify-between">
                  <span className="text-gray-600">Lot Value:</span>
                  <span className="font-semibold">${results.lot}</span>
                </div>
              </div>

              <div className="border-t pt-4 flex justify-between items-center">
                <div className="flex items-center">
                  <DollarSign className="w-5 h-5 text-green-600 mr-2" />
                  <span className="font-bold">Estimated Value:</span>
                </div>
                <span className="text-2xl font-bold text-green-600">${results.total}</span>
              </div>
            </div>
          </div>

          <div className="bg-green-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-green-800">
                <p>* Estimates based on average Lima/Dayton area home values. Actual values may vary.</p>
                <p>* Additional factors like location, market conditions, and unique features may affect value.</p>
                <p>* For the most accurate valuation, consult a local real estate professional.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}