import React, { useState } from 'react';
import { Fence, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type FenceType = 'privacy' | 'picket' | 'chain-link' | 'vinyl' | 'aluminum' | 'split-rail';
type MaterialGrade = 'economy' | 'standard' | 'premium';

export default function FencingCalculator() {
  // State for fence details
  const [fenceType, setFenceType] = useState<FenceType>('privacy');
  const [materialGrade, setMaterialGrade] = useState<MaterialGrade>('standard');
  const [length, setLength] = useState('');
  const [height, setHeight] = useState('6');
  const [gateCount, setGateCount] = useState('1');
  const [postSpacing, setPostSpacing] = useState('8');
  const [includePermit, setIncludePermit] = useState(true);
  const [needsRemoval, setNeedsRemoval] = useState(false);
  const [isCornerLot, setIsCornerLot] = useState(false);
  const [hasSlopeCorrection, setHasSlopeCorrection] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const materialCosts = {
    privacy: {
      economy: { material: 15, post: 25 }, // per linear ft
      standard: { material: 22, post: 35 },
      premium: { material: 32, post: 45 }
    },
    picket: {
      economy: { material: 12, post: 25 },
      standard: { material: 18, post: 35 },
      premium: { material: 28, post: 45 }
    },
    'chain-link': {
      economy: { material: 8, post: 20 },
      standard: { material: 12, post: 30 },
      premium: { material: 18, post: 40 }
    },
    vinyl: {
      economy: { material: 25, post: 35 },
      standard: { material: 35, post: 45 },
      premium: { material: 45, post: 55 }
    },
    aluminum: {
      economy: { material: 30, post: 40 },
      standard: { material: 40, post: 50 },
      premium: { material: 50, post: 60 }
    },
    'split-rail': {
      economy: { material: 10, post: 25 },
      standard: { material: 15, post: 35 },
      premium: { material: 22, post: 45 }
    }
  };

  const additionalCosts = {
    gates: {
      privacy: 150,
      picket: 125,
      'chain-link': 100,
      vinyl: 200,
      aluminum: 250,
      'split-rail': 150
    },
    removal: 5, // per linear ft
    slopeCorrection: 8, // per linear ft
    cornerPosts: 25, // additional per corner
    permit: 150, // flat fee
    cleanup: 2 // per linear ft
  };

  // Height multipliers
  const heightMultipliers: { [key: string]: number } = {
    '4': 0.8,
    '5': 0.9,
    '6': 1.0,
    '7': 1.2,
    '8': 1.4
  };

  const calculateEstimate = () => {
    const fenceLength = parseFloat(length);
    if (!fenceLength) return null;

    // Calculate base material costs
    const material = materialCosts[fenceType][materialGrade];
    const heightMultiplier = heightMultipliers[height] || 1;
    
    // Calculate post requirements
    const spacing = parseFloat(postSpacing);
    const postCount = Math.ceil(fenceLength / spacing) + 1;
    const cornerCount = isCornerLot ? 2 : 0;
    
    // Calculate material costs
    const fenceMaterialCost = fenceLength * material.material * heightMultiplier;
    const postCost = (postCount * material.post) + (cornerCount * additionalCosts.cornerPosts);
    
    // Calculate gate costs
    const gates = parseInt(gateCount);
    const gateCost = gates * additionalCosts.gates[fenceType];

    // Calculate additional costs
    const removalCost = needsRemoval ? (fenceLength * additionalCosts.removal) : 0;
    const slopeCost = hasSlopeCorrection ? (fenceLength * additionalCosts.slopeCorrection) : 0;
    const cleanupCost = fenceLength * additionalCosts.cleanup;
    const permitFee = includePermit ? additionalCosts.permit : 0;

    // Calculate labor (varies by type and conditions)
    const baseLabor = fenceLength * 12; // $12 per linear ft base rate
    const laborMultiplier = (needsRemoval ? 1.2 : 1) * 
                           (hasSlopeCorrection ? 1.3 : 1) * 
                           heightMultiplier;
    const laborCost = baseLabor * laborMultiplier;

    // Calculate totals
    const materialTotal = fenceMaterialCost + postCost + gateCost;
    const tax = materialTotal * 0.075; // 7.5% sales tax
    const total = materialTotal + laborCost + removalCost + slopeCost + 
                 cleanupCost + permitFee + tax;

    return {
      length: fenceLength.toFixed(0),
      materials: {
        fencing: fenceMaterialCost.toFixed(2),
        posts: postCost.toFixed(2),
        gates: gateCost.toFixed(2)
      },
      labor: laborCost.toFixed(2),
      additional: {
        removal: removalCost.toFixed(2),
        slope: slopeCost.toFixed(2),
        cleanup: cleanupCost.toFixed(2)
      },
      permit: permitFee.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-600 to-orange-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Fence className="w-8 h-8 text-orange-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Fencing Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Fence Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['privacy', 'picket', 'chain-link', 'vinyl', 'aluminum', 'split-rail'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFenceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    fenceType === type
                      ? 'bg-orange-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'chain-link' ? 'Chain Link' :
                   type === 'split-rail' ? 'Split Rail' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Material Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setMaterialGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    materialGrade === grade
                      ? 'bg-orange-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Height (feet)</label>
              <select
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200"
              >
                {Object.keys(heightMultipliers).map((h) => (
                  <option key={h} value={h}>{h} ft</option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Number of Gates</label>
              <input
                type="number"
                value={gateCount}
                onChange={(e) => setGateCount(e.target.value)}
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Post Spacing (feet)</label>
              <select
                value={postSpacing}
                onChange={(e) => setPostSpacing(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring focus:ring-orange-200"
              >
                <option value="6">6 ft</option>
                <option value="8">8 ft</option>
                <option value="10">10 ft</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsRemoval}
                onChange={(e) => setNeedsRemoval(e.target.checked)}
                className="rounded text-orange-600 focus:ring-orange-500"
              />
              <span>Old Fence Removal Needed</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isCornerLot}
                onChange={(e) => setIsCornerLot(e.target.checked)}
                className="rounded text-orange-600 focus:ring-orange-500"
              />
              <span>Corner Lot (Additional Corner Posts)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasSlopeCorrection}
                onChange={(e) => setHasSlopeCorrection(e.target.checked)}
                className="rounded text-orange-600 focus:ring-orange-500"
              />
              <span>Slope Correction Needed</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-orange-600 focus:ring-orange-500"
              />
              <span>Include Permit Fee</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-orange-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Fence Length:</span>
                  </div>
                  <span className="font-semibold">{results.length} ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Fencing Materials:</span>
                    <span className="font-semibold">${results.materials.fencing}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Posts:</span>
                    <span className="font-semibold">${results.materials.posts}</span>
                  </div>
                  {parseFloat(results.materials.gates) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Gates:</span>
                      <span className="font-semibold">${results.materials.gates}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  {parseFloat(results.additional.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Removal:</span>
                      <span className="font-semibold">${results.additional.removal}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.slope) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Slope Correction:</span>
                      <span className="font-semibold">${results.additional.slope}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Cleanup:</span>
                    <span className="font-semibold">${results.additional.cleanup}</span>
                  </div>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                  {parseFloat(results.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit Fee:</span>
                      <span className="font-semibold">${results.permit}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-orange-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-orange-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-orange-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-orange-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-orange-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for difficult terrain or special requirements.</p>
                <p>* Permit requirements and fees vary by municipality.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Fencing Costs</h3>
            <p className="text-gray-700">
              Fencing costs in the Lima/Dayton area vary based on several factors including material type, height, and installation conditions. Labor rates typically range from $10-15 per linear foot for basic installation, with additional costs for removal, slope correction, and special features.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Material durability and maintenance requirements</li>
              <li>Local zoning laws and height restrictions</li>
              <li>Property line verification and surveys</li>
              <li>Underground utility locations</li>
              <li>Soil conditions and drainage</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term maintenance requirements when selecting materials.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}