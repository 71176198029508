import React from 'react';
import { DollarSign } from 'lucide-react';
import type { WindowEstimate } from '../../types/windows';

interface WindowBreakdownProps {
  results: {
    estimates: WindowEstimate[];
    totalCost: number;
  };
}

export function WindowBreakdown({ results }: WindowBreakdownProps) {
  return (
    <div className="bg-gray-50 rounded-lg p-6">
      <div className="flex items-center justify-center mb-4">
        <DollarSign className="w-6 h-6 text-cyan-600 mr-2" />
        <h2 className="text-xl font-semibold text-gray-800">Cost Breakdown</h2>
      </div>

      <div className="space-y-4">
        {results.estimates.map((estimate, index) => (
          <div key={index} className="border-t pt-4 space-y-2">
            <div className="flex justify-between text-gray-700 font-medium">
              <span>Window {index + 1}</span>
              <span>${estimate.total.toFixed(2)}</span>
            </div>
            <div className="pl-4 space-y-1 text-sm">
              <div className="flex justify-between text-gray-600">
                <span>Window Unit:</span>
                <span>${estimate.windowCost.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-gray-600">
                <span>Installation:</span>
                <span>${estimate.installCost.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-gray-600">
                <span>Glass:</span>
                <span>${estimate.glassCost.toFixed(2)}</span>
              </div>
              {estimate.frameCost > 0 && (
                <div className="flex justify-between text-gray-600">
                  <span>Frame:</span>
                  <span>${estimate.frameCost.toFixed(2)}</span>
                </div>
              )}
              {estimate.hardwareCost > 0 && (
                <div className="flex justify-between text-gray-600">
                  <span>Hardware:</span>
                  <span>${estimate.hardwareCost.toFixed(2)}</span>
                </div>
              )}
              {estimate.structuralCost > 0 && (
                <div className="flex justify-between text-gray-600">
                  <span>Structural Work:</span>
                  <span>${estimate.structuralCost.toFixed(2)}</span>
                </div>
              )}
              {estimate.finishingCost > 0 && (
                <div className="flex justify-between text-gray-600">
                  <span>Finishing:</span>
                  <span>${estimate.finishingCost.toFixed(2)}</span>
                </div>
              )}
            </div>
          </div>
        ))}

        <div className="border-t pt-4 flex justify-between items-center">
          <div className="flex items-center">
            <DollarSign className="w-5 h-5 text-cyan-600 mr-2" />
            <span className="font-bold">Total Estimate:</span>
          </div>
          <span className="text-2xl font-bold text-cyan-600">
            ${results.totalCost.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
}