import React, { useState } from 'react';
import { CircleDot, Medal, Plus, Minus } from 'lucide-react';
import Footer from './Footer';

interface ScoreCardProps {
  players: { name: string; scores: number[] }[];
  holePars: number[];
  onScoreUpdate: (playerIndex: number, holeIndex: number, increment: boolean) => void;
}

export default function ScoreCard({ players, holePars, onScoreUpdate }: ScoreCardProps) {
  const [showingFrontNine, setShowingFrontNine] = useState(true);
  
  const getTotalScore = (scores: number[]) => scores.reduce((a, b) => a + b, 0);
  const getFrontNineScore = (scores: number[]) => scores.slice(0, 9).reduce((a, b) => a + b, 0);
  const getBackNineScore = (scores: number[]) => scores.slice(9, 18).reduce((a, b) => a + b, 0);
  const getTotalPar = () => holePars.reduce((a, b) => a + b, 0);
  
  const currentHoles = showingFrontNine ? holePars.slice(0, 9) : holePars.slice(9, 18);
  const baseHoleNumber = showingFrontNine ? 0 : 9;

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-800 to-green-600 p-6">
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <CircleDot className="w-8 h-8 text-green-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Golf Score</h1>
        </div>

        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => setShowingFrontNine(true)}
            className={`px-4 py-2 rounded-lg transition ${
              showingFrontNine
                ? 'bg-green-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Front Nine
          </button>
          <button
            onClick={() => setShowingFrontNine(false)}
            className={`px-4 py-2 rounded-lg transition ${
              !showingFrontNine
                ? 'bg-green-600 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Back Nine
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2">Player</th>
                {currentHoles.map((par, index) => (
                  <th key={index} className="px-4 py-2">
                    <div className="text-sm">Hole {index + baseHoleNumber + 1}</div>
                    <div className="text-xs text-gray-500">Par {par}</div>
                  </th>
                ))}
                <th className="px-4 py-2">Nine</th>
                <th className="px-4 py-2">Total</th>
              </tr>
            </thead>
            <tbody>
              {players.map((player, playerIndex) => (
                <tr key={playerIndex} className="border-t">
                  <td className="px-4 py-2 font-semibold">{player.name}</td>
                  {currentHoles.map((_, holeIndex) => {
                    const actualHoleIndex = baseHoleNumber + holeIndex;
                    return (
                      <td key={holeIndex} className="px-4 py-2">
                        <div className="flex flex-col items-center">
                          <button
                            onClick={() => onScoreUpdate(playerIndex, actualHoleIndex, true)}
                            className="text-green-600 hover:text-green-800"
                          >
                            <Plus className="w-4 h-4" />
                          </button>
                          <span className="my-1">{player.scores[actualHoleIndex]}</span>
                          <button
                            onClick={() => onScoreUpdate(playerIndex, actualHoleIndex, false)}
                            className="text-red-600 hover:text-red-800"
                          >
                            <Minus className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    );
                  })}
                  <td className="px-4 py-2 font-bold">
                    {showingFrontNine
                      ? getFrontNineScore(player.scores)
                      : getBackNineScore(player.scores)}
                  </td>
                  <td className="px-4 py-2 font-bold">
                    {getTotalScore(player.scores)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
          <div className="flex items-center justify-center space-x-4">
            <Medal className="w-6 h-6 text-yellow-600" />
            <div className="text-lg font-semibold space-x-4">
              <span>Front Nine: {holePars.slice(0, 9).reduce((a, b) => a + b, 0)}</span>
              <span>Back Nine: {holePars.slice(9, 18).reduce((a, b) => a + b, 0)}</span>
              <span>Total Par: {getTotalPar()}</span>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}