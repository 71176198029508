import React, { useState } from 'react';
import { Paintbrush, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type SurfaceType = 'interior' | 'exterior' | 'trim' | 'cabinet';
type RoomType = 'bedroom' | 'livingRoom' | 'kitchen' | 'bathroom' | 'custom';
type PaintQuality = 'economy' | 'standard' | 'premium';

export default function PaintingEstimator() {
  // State for painting details
  const [surfaceType, setSurfaceType] = useState<SurfaceType>('interior');
  const [roomType, setRoomType] = useState<RoomType>('bedroom');
  const [paintQuality, setPaintQuality] = useState<PaintQuality>('standard');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('8'); // Standard ceiling height
  const [windows, setWindows] = useState('1');
  const [doors, setDoors] = useState('1');
  const [coats, setCoats] = useState('2');
  const [includeCeilings, setIncludeCeilings] = useState(false);
  const [includePrimer, setIncludePrimer] = useState(false);
  const [wallCondition, setWallCondition] = useState<'good' | 'fair' | 'poor'>('good');

  // Standard room sizes in square feet
  const roomSizes = {
    bedroom: { length: 12, width: 12, height: 8 },
    livingRoom: { length: 16, width: 20, height: 8 },
    kitchen: { length: 12, width: 15, height: 8 },
    bathroom: { length: 8, width: 10, height: 8 },
    custom: { length: 0, width: 0, height: 8 }
  };

  // Current market rates (2024 Lima/Dayton area)
  const rates = {
    labor: {
      interior: 2.50, // per sq ft
      exterior: 3.00,
      trim: 3.50,
      cabinet: 85.00 // per door/drawer
    },
    paint: {
      economy: { cost: 25, coverage: 400 }, // cost per gallon, coverage in sq ft
      standard: { cost: 35, coverage: 400 },
      premium: { cost: 50, coverage: 400 }
    },
    primer: {
      cost: 30,
      coverage: 300
    },
    preparation: {
      good: 0.50, // additional cost per sq ft
      fair: 1.00,
      poor: 2.00
    }
  };

  // Standard deductions for openings
  const openings = {
    window: 15, // sq ft
    door: 21 // sq ft
  };

  const calculateWallArea = () => {
    if (roomType !== 'custom' && !length && !width) {
      const size = roomSizes[roomType];
      return {
        length: size.length,
        width: size.width,
        height: size.height,
        area: (2 * size.length * size.height) + (2 * size.width * size.height)
      };
    }

    const l = parseFloat(length) || 0;
    const w = parseFloat(width) || 0;
    const h = parseFloat(height) || 8;

    if (!l || !w) return null;

    const wallArea = (2 * l * h) + (2 * w * h);
    return { length: l, width: w, height: h, area: wallArea };
  };

  const calculateEstimate = () => {
    const dimensions = calculateWallArea();
    if (!dimensions) return null;

    // Calculate total wall area minus openings
    const windowDeductions = parseInt(windows) * openings.window;
    const doorDeductions = parseInt(doors) * openings.door;
    let totalArea = dimensions.area - windowDeductions - doorDeductions;

    // Add ceiling if included
    if (includeCeilings) {
      totalArea += dimensions.length * dimensions.width;
    }

    // Calculate paint needed
    const coatsCount = parseInt(coats);
    const paintPerCoat = totalArea / rates.paint[paintQuality].coverage;
    const totalPaintNeeded = Math.ceil(paintPerCoat * coatsCount);
    const primerNeeded = includePrimer ? Math.ceil(totalArea / rates.primer.coverage) : 0;

    // Calculate costs
    const paintCost = totalPaintNeeded * rates.paint[paintQuality].cost;
    const primerCost = primerNeeded * rates.primer.cost;
    const laborRate = rates.labor[surfaceType];
    const prepRate = rates.preparation[wallCondition];
    const laborCost = totalArea * laborRate;
    const prepCost = totalArea * prepRate;

    // Calculate totals
    const materialCost = paintCost + primerCost;
    const totalCost = materialCost + laborCost + prepCost;

    return {
      area: totalArea.toFixed(0),
      paint: {
        gallons: totalPaintNeeded,
        cost: paintCost.toFixed(2)
      },
      primer: {
        gallons: primerNeeded,
        cost: primerCost.toFixed(2)
      },
      labor: laborCost.toFixed(2),
      prep: prepCost.toFixed(2),
      total: totalCost.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-rose-600 to-rose-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Paintbrush className="w-8 h-8 text-rose-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Painting Estimator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Surface Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['interior', 'exterior', 'trim', 'cabinet'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setSurfaceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    surfaceType === type
                      ? 'bg-rose-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          {surfaceType === 'interior' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Room Type</label>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {(['bedroom', 'livingRoom', 'kitchen', 'bathroom', 'custom'] as const).map((type) => (
                  <button
                    key={type}
                    onClick={() => setRoomType(type)}
                    className={`py-2 px-4 rounded-lg capitalize ${
                      roomType === type
                        ? 'bg-rose-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {type === 'livingRoom' ? 'Living Room' : type}
                  </button>
                ))}
              </div>
            </div>
          )}

          {(roomType === 'custom' || surfaceType !== 'interior') && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
                <input
                  type="number"
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring focus:ring-rose-200"
                  placeholder="Enter length"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
                <input
                  type="number"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring focus:ring-rose-200"
                  placeholder="Enter width"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Height (feet)</label>
                <input
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring focus:ring-rose-200"
                  placeholder="Enter height"
                />
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Paint Quality</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((quality) => (
                <button
                  key={quality}
                  onClick={() => setPaintQuality(quality)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    paintQuality === quality
                      ? 'bg-rose-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {quality}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Surface Condition</label>
            <div className="grid grid-cols-3 gap-2">
              {(['good', 'fair', 'poor'] as const).map((condition) => (
                <button
                  key={condition}
                  onClick={() => setWallCondition(condition)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    wallCondition === condition
                      ? 'bg-rose-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {condition}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Windows</label>
              <input
                type="number"
                value={windows}
                onChange={(e) => setWindows(e.target.value)}
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring focus:ring-rose-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Doors</label>
              <input
                type="number"
                value={doors}
                onChange={(e) => setDoors(e.target.value)}
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring focus:ring-rose-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Coats</label>
              <select
                value={coats}
                onChange={(e) => setCoats(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring focus:ring-rose-200"
              >
                <option value="1">1 Coat</option>
                <option value="2">2 Coats</option>
                <option value="3">3 Coats</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeCeilings}
                onChange={(e) => setIncludeCeilings(e.target.checked)}
                className="rounded text-rose-600 focus:ring-rose-500"
              />
              <span>Include Ceilings</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePrimer}
                onChange={(e) => setIncludePrimer(e.target.checked)}
                className="rounded text-rose-600 focus:ring-rose-500"
              />
              <span>Include Primer</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-rose-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Total Area:</span>
                  </div>
                  <span className="font-semibold">{results.area} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Paint ({results.paint.gallons} gal):</span>
                    <span className="font-semibold">${results.paint.cost}</span>
                  </div>
                  {results.primer.gallons > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Primer ({results.primer.gallons} gal):</span>
                      <span className="font-semibold">${results.primer.cost}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Preparation:</span>
                    <span className="font-semibold">${results.prep}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-rose-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-rose-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-rose-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-rose-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-rose-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs, special finishes, or complex designs.</p>
                <p>* Paint prices based on standard colors. Custom colors may cost more.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Painting Costs</h3>
            <p className="text-gray-700">
              Painting costs in the Lima/Dayton area vary based on several factors including surface type, paint quality, and preparation requirements. Labor rates typically range from $2.50 to $3.50 per square foot for standard painting work.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Surface preparation requirements</li>
              <li>Paint quality and coverage</li>
              <li>Number of coats needed</li>
              <li>Trim and detail work</li>
              <li>Ceiling and primer requirements</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local painters and considering both paint quality and preparation requirements for your specific project.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}