import React, { useState } from 'react';
import { Shovel, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type WellType = 'drilled' | 'artesian' | 'shallow' | 'geothermal';
type CasingType = 'steel' | 'pvc' | 'stainless';
type PumpType = 'submersible' | 'jet' | 'solar';

export default function WellDrillingCalculator() {
  // State for well details
  const [wellType, setWellType] = useState<WellType>('drilled');
  const [casingType, setCasingType] = useState<CasingType>('steel');
  const [pumpType, setPumpType] = useState<PumpType>('submersible');
  const [depth, setDepth] = useState('');
  const [diameter, setDiameter] = useState('6');
  const [includeWaterTreatment, setIncludeWaterTreatment] = useState(false);
  const [includePressureTank, setIncludePressureTank] = useState(true);
  const [includePermit, setIncludePermit] = useState(true);
  const [needsGrouting, setNeedsGrouting] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const drillingCosts = {
    drilled: 25, // per foot
    artesian: 35,
    shallow: 20,
    geothermal: 40
  };

  const casingCosts = {
    steel: { cost: 35, minDepth: 20 }, // per foot
    pvc: { cost: 25, minDepth: 20 },
    stainless: { cost: 75, minDepth: 20 }
  };

  const pumpSystems = {
    submersible: {
      base: 1200,
      depthMultiplier: 0.5 // additional cost per foot
    },
    jet: {
      base: 900,
      depthMultiplier: 0.4
    },
    solar: {
      base: 2500,
      depthMultiplier: 0.6
    }
  };

  // Additional components and services
  const additionalCosts = {
    waterTreatment: {
      basic: 1500, // filtration only
      advanced: 3000 // with softening
    },
    pressureTank: {
      standard: 800,
      large: 1200
    },
    permit: 350,
    grouting: 12, // per foot
    siteSurvey: 500,
    waterTest: 250
  };

  // Diameter multipliers
  const diameterMultipliers: { [key: string]: number } = {
    '4': 0.8,
    '6': 1.0,
    '8': 1.3,
    '10': 1.6,
    '12': 2.0
  };

  const calculateEstimate = () => {
    const wellDepth = parseFloat(depth);
    if (!wellDepth) return null;

    // Calculate drilling costs
    const diameterMultiplier = diameterMultipliers[diameter] || 1.0;
    const drillingCost = wellDepth * drillingCosts[wellType] * diameterMultiplier;

    // Calculate casing costs
    const casing = casingCosts[casingType];
    const casingDepth = Math.max(casing.minDepth, wellDepth * 0.8); // Typically 80% of well depth
    const casingCost = casingDepth * casing.cost;

    // Calculate pump system costs
    const pump = pumpSystems[pumpType];
    const pumpCost = pump.base + (wellDepth * pump.depthMultiplier);

    // Calculate additional components
    const treatmentCost = includeWaterTreatment ? 
      (wellDepth > 200 ? additionalCosts.waterTreatment.advanced : additionalCosts.waterTreatment.basic) : 0;
    
    const tankCost = includePressureTank ?
      (wellDepth > 200 ? additionalCosts.pressureTank.large : additionalCosts.pressureTank.standard) : 0;

    const groutingCost = needsGrouting ? (wellDepth * additionalCosts.grouting) : 0;
    const permitCost = includePermit ? additionalCosts.permit : 0;

    // Always include site survey and water testing
    const surveyTestingCost = additionalCosts.siteSurvey + additionalCosts.waterTest;

    // Calculate totals
    const subtotal = drillingCost + casingCost + pumpCost + treatmentCost + 
                    tankCost + groutingCost + permitCost + surveyTestingCost;
    const tax = (casingCost + pumpCost + treatmentCost + tankCost) * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      drilling: drillingCost.toFixed(2),
      casing: casingCost.toFixed(2),
      pump: pumpCost.toFixed(2),
      treatment: treatmentCost.toFixed(2),
      tank: tankCost.toFixed(2),
      grouting: groutingCost.toFixed(2),
      permit: permitCost.toFixed(2),
      surveyTesting: surveyTestingCost.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      depth: wellDepth.toFixed(0)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-800 to-blue-950 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Shovel className="w-8 h-8 text-blue-800 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Well Drilling Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Well Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['drilled', 'artesian', 'shallow', 'geothermal'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setWellType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    wellType === type
                      ? 'bg-blue-800 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Casing Material</label>
            <div className="grid grid-cols-3 gap-2">
              {(['steel', 'pvc', 'stainless'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setCasingType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    casingType === type
                      ? 'bg-blue-800 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'pvc' ? 'PVC' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Pump Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['submersible', 'jet', 'solar'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setPumpType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    pumpType === type
                      ? 'bg-blue-800 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Well Depth (feet)</label>
              <input
                type="number"
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                placeholder="Enter depth"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Well Diameter (inches)</label>
              <select
                value={diameter}
                onChange={(e) => setDiameter(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
              >
                {Object.keys(diameterMultipliers).map((size) => (
                  <option key={size} value={size}>{size}"</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeWaterTreatment}
                onChange={(e) => setIncludeWaterTreatment(e.target.checked)}
                className="rounded text-blue-800 focus:ring-blue-500"
              />
              <span>Include Water Treatment System</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePressureTank}
                onChange={(e) => setIncludePressureTank(e.target.checked)}
                className="rounded text-blue-800 focus:ring-blue-500"
              />
              <span>Include Pressure Tank</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsGrouting}
                onChange={(e) => setNeedsGrouting(e.target.checked)}
                className="rounded text-blue-800 focus:ring-blue-500"
              />
              <span>Include Well Grouting</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-blue-800 focus:ring-blue-500"
              />
              <span>Include Permit Fee</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-blue-800 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Well Depth:</span>
                  </div>
                  <span className="font-semibold">{results.depth} feet</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Drilling:</span>
                    <span className="font-semibold">${results.drilling}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Casing:</span>
                    <span className="font-semibold">${results.casing}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Pump System:</span>
                    <span className="font-semibold">${results.pump}</span>
                  </div>
                  {parseFloat(results.treatment) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Water Treatment:</span>
                      <span className="font-semibold">${results.treatment}</span>
                    </div>
                  )}
                  {parseFloat(results.tank) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Pressure Tank:</span>
                      <span className="font-semibold">${results.tank}</span>
                    </div>
                  )}
                  {parseFloat(results.grouting) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Grouting:</span>
                      <span className="font-semibold">${results.grouting}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Survey & Testing:</span>
                    <span className="font-semibold">${results.surveyTesting}</span>
                  </div>
                  {parseFloat(results.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit Fee:</span>
                      <span className="font-semibold">${results.permit}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-blue-800 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-blue-800">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-blue-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-blue-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for difficult terrain or special requirements.</p>
                <p>* All wells require proper permits and water quality testing.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Well Drilling Costs</h3>
            <p className="text-gray-700">
              Well drilling costs in the Lima/Dayton area vary based on several factors including depth, soil conditions, and system requirements. Basic well installations typically range from $5,000 to $15,000, with deeper or more complex systems costing more.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Geological conditions and depth to water table</li>
              <li>Local health department requirements</li>
              <li>Water quality and treatment needs</li>
              <li>System capacity requirements</li>
              <li>Power source availability</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and having a proper site survey performed before selecting a well system.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}