import React, { useState } from 'react';
import { Sparkles, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ServiceType = 'standard' | 'deep' | 'move' | 'recurring';
type RoomType = 'bedroom' | 'livingRoom' | 'kitchen' | 'bathroom' | 'custom';
type ServiceLevel = 'basic' | 'standard' | 'premium';

export default function CleaningCalculator() {
  // State for cleaning details
  const [serviceType, setServiceType] = useState<ServiceType>('standard');
  const [roomType, setRoomType] = useState<RoomType>('bedroom');
  const [serviceLevel, setServiceLevel] = useState<ServiceLevel>('standard');
  const [squareFeet, setSquareFeet] = useState('');
  const [bedrooms, setBedrooms] = useState('3');
  const [bathrooms, setBathrooms] = useState('2');
  const [includeWindows, setIncludeWindows] = useState(false);
  const [includeLaundry, setIncludeLaundry] = useState(false);
  const [includeOrganizing, setIncludeOrganizing] = useState(false);
  const [includeAppliances, setIncludeAppliances] = useState(false);
  const [hasStairs, setHasStairs] = useState(false);
  const [hasPets, setHasPets] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const baseCosts = {
    standard: 0.12, // per sq ft
    deep: 0.20,
    move: 0.25,
    recurring: 0.10
  };

  // Room-based rates
  const roomRates = {
    bedroom: 35,
    bathroom: 45
  };

  // Frequency discounts
  const frequencyDiscounts = {
    once: 1.0,
    weekly: 0.8,
    biweekly: 0.85,
    monthly: 0.9
  };

  // Additional services
  const additionalCosts = {
    windows: {
      base: 5, // per window
      minimum: 50
    },
    laundry: {
      load: 15, // per load
      minimum: 30
    },
    organizing: {
      hour: 40,
      minimum: 80
    },
    appliances: {
      small: 20,
      large: 35
    },
    stairs: 25,
    pets: 30
  };

  const calculateEstimate = () => {
    const area = parseFloat(squareFeet);
    if (!area) return null;

    // Calculate base cleaning cost
    const baseCost = area * baseCosts[serviceType];

    // Add room-based costs
    const bedroomCount = parseInt(bedrooms);
    const bathroomCount = parseInt(bathrooms);
    const roomCost = (bedroomCount * roomRates.bedroom) + 
                    (bathroomCount * roomRates.bathroom);

    // Calculate additional services
    const windowCost = includeWindows ? 
      Math.max(additionalCosts.windows.minimum, (area / 250) * additionalCosts.windows.base) : 0;

    const laundryCost = includeLaundry ?
      Math.max(additionalCosts.laundry.minimum, bedroomCount * additionalCosts.laundry.load) : 0;

    const organizingCost = includeOrganizing ?
      Math.max(additionalCosts.organizing.minimum, (area / 500) * additionalCosts.organizing.hour) : 0;

    const applianceCost = includeAppliances ?
      (2 * additionalCosts.appliances.large + 3 * additionalCosts.appliances.small) : 0;

    const stairsCost = hasStairs ? additionalCosts.stairs : 0;
    const petsCost = hasPets ? additionalCosts.pets : 0;

    // Calculate totals
    const subtotal = baseCost + roomCost + windowCost + laundryCost + organizingCost + 
                    applianceCost + stairsCost + petsCost;
    const tax = subtotal * 0.075; // 7.5% sales tax
    const total = subtotal + tax;

    return {
      base: baseCost.toFixed(2),
      rooms: roomCost.toFixed(2),
      additional: {
        windows: windowCost.toFixed(2),
        laundry: laundryCost.toFixed(2),
        organizing: organizingCost.toFixed(2),
        appliances: applianceCost.toFixed(2),
        stairs: stairsCost.toFixed(2),
        pets: petsCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-emerald-500 to-emerald-700 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Sparkles className="w-8 h-8 text-emerald-500 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Cleaning Service Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['standard', 'deep', 'move', 'recurring'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setServiceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceType === type
                      ? 'bg-emerald-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'move' ? 'Move In/Out' : type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Level</label>
            <div className="grid grid-cols-3 gap-2">
              {(['basic', 'standard', 'premium'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setServiceLevel(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceLevel === level
                      ? 'bg-emerald-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Square Feet</label>
              <input
                type="number"
                value={squareFeet}
                onChange={(e) => setSquareFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
                placeholder="Enter area"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Bedrooms</label>
              <select
                value={bedrooms}
                onChange={(e) => setBedrooms(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              >
                {['1', '2', '3', '4', '5', '6'].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Bathrooms</label>
              <select
                value={bathrooms}
                onChange={(e) => setBathrooms(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              >
                {['1', '1.5', '2', '2.5', '3', '3.5', '4'].map((num) => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeWindows}
                onChange={(e) => setIncludeWindows(e.target.checked)}
                className="rounded text-emerald-500 focus:ring-emerald-500"
              />
              <span>Include Window Cleaning</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeLaundry}
                onChange={(e) => setIncludeLaundry(e.target.checked)}
                className="rounded text-emerald-500 focus:ring-emerald-500"
              />
              <span>Include Laundry Service</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeOrganizing}
                onChange={(e) => setIncludeOrganizing(e.target.checked)}
                className="rounded text-emerald-500 focus:ring-emerald-500"
              />
              <span>Include Organizing Service</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeAppliances}
                onChange={(e) => setIncludeAppliances(e.target.checked)}
                className="rounded text-emerald-500 focus:ring-emerald-500"
              />
              <span>Include Appliance Cleaning</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasStairs}
                onChange={(e) => setHasStairs(e.target.checked)}
                className="rounded text-emerald-500 focus:ring-emerald-500"
              />
              <span>Has Stairs</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={hasPets}
                onChange={(e) => setHasPets(e.target.checked)}
                className="rounded text-emerald-500 focus:ring-emerald-500"
              />
              <span>Has Pets</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-emerald-500 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Base Cleaning:</span>
                    <span className="font-semibold">${results.base}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Room Charges:</span>
                    <span className="font-semibold">${results.rooms}</span>
                  </div>
                  {parseFloat(results.additional.windows) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Window Cleaning:</span>
                      <span className="font-semibold">${results.additional.windows}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.laundry) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Laundry Service:</span>
                      <span className="font-semibold">${results.additional.laundry}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.organizing) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Organizing:</span>
                      <span className="font-semibold">${results.additional.organizing}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.appliances) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Appliance Cleaning:</span>
                      <span className="font-semibold">${results.additional.appliances}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.stairs) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Stairs Fee:</span>
                      <span className="font-semibold">${results.additional.stairs}</span>
                    </div>
                  )}
                  {parseFloat(results.additional.pets) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Pet Fee:</span>
                      <span className="font-semibold">${results.additional.pets}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-emerald-500 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-emerald-500">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-emerald-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-emerald-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-emerald-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional fees may apply for excessive dirt or special cleaning requirements.</p>
                <p>* Minimum service charges may apply for smaller spaces.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}