import React from 'react';
import { DollarSign } from 'lucide-react';
import type { DoorEstimate } from '../../types/doors';

interface DoorBreakdownProps {
  results: DoorEstimate;
}

export function DoorBreakdown({ results }: DoorBreakdownProps) {
  return (
    <div className="bg-gray-50 rounded-lg p-6">
      <div className="flex items-center justify-center mb-4">
        <DollarSign className="w-6 h-6 text-amber-600 mr-2" />
        <h2 className="text-xl font-semibold text-gray-800">Cost Breakdown</h2>
      </div>

      <div className="space-y-4">
        {Object.entries(results.breakdown).map(([doorId, details]) => (
          <div key={doorId} className="border-t pt-4 space-y-2">
            <div className="flex justify-between text-gray-700 font-medium">
              <span>Door {doorId}</span>
              <span>${details.subtotal.toFixed(2)}</span>
            </div>
            <div className="pl-4 space-y-1 text-sm">
              <div className="flex justify-between text-gray-600">
                <span>Materials:</span>
                <span>${details.details.materials.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-gray-600">
                <span>Labor:</span>
                <span>${details.details.labor.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-gray-600">
                <span>Extras:</span>
                <span>${details.details.extras.toFixed(2)}</span>
              </div>
              {details.details.painting && details.details.painting > 0 && (
                <div className="flex justify-between text-gray-600">
                  <span>Painting:</span>
                  <span>${details.details.painting.toFixed(2)}</span>
                </div>
              )}
            </div>
          </div>
        ))}

        <div className="border-t pt-4 space-y-2">
          <div className="flex justify-between">
            <span className="text-gray-600">Materials Total:</span>
            <span className="font-semibold">${results.materials.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-600">Labor Total:</span>
            <span className="font-semibold">${results.labor.toFixed(2)}</span>
          </div>
          {results.hardware > 0 && (
            <div className="flex justify-between">
              <span className="text-gray-600">Hardware:</span>
              <span className="font-semibold">${results.hardware.toFixed(2)}</span>
            </div>
          )}
          {results.finishing > 0 && (
            <div className="flex justify-between">
              <span className="text-gray-600">Finishing:</span>
              <span className="font-semibold">${results.finishing.toFixed(2)}</span>
            </div>
          )}
          {results.structural > 0 && (
            <div className="flex justify-between">
              <span className="text-gray-600">Structural Work:</span>
              <span className="font-semibold">${results.structural.toFixed(2)}</span>
            </div>
          )}
          {results.painting > 0 && (
            <div className="flex justify-between">
              <span className="text-gray-600">Painting:</span>
              <span className="font-semibold">${results.painting.toFixed(2)}</span>
            </div>
          )}
        </div>

        <div className="border-t pt-4 flex justify-between items-center">
          <div className="flex items-center">
            <DollarSign className="w-5 h-5 text-amber-600 mr-2" />
            <span className="font-bold">Total Estimate:</span>
          </div>
          <span className="text-2xl font-bold text-amber-600">
            ${results.total.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
}