import React, { useState } from 'react';
import { Wrench, Calculator, DollarSign, Clock, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ToolCategory = 'power' | 'lawn' | 'concrete' | 'flooring' | 'plumbing' | 'lifting';
type RentalPeriod = 'hour' | '4hour' | 'day' | 'week' | 'month';

export default function ToolRentalCalculator() {
  // State for rental details
  const [category, setCategory] = useState<ToolCategory>('power');
  const [selectedTool, setSelectedTool] = useState('');
  const [rentalPeriod, setRentalPeriod] = useState<RentalPeriod>('day');
  const [quantity, setQuantity] = useState('1');
  const [includeDeposit, setIncludeDeposit] = useState(true);
  const [includeDelivery, setIncludeDelivery] = useState(false);
  const [needsOperator, setNeedsOperator] = useState(false);

  // Tool categories and their items with rates
  const toolInventory = {
    power: {
      'Concrete Saw': { hour: 35, '4hour': 85, day: 125, week: 375, month: 1125, deposit: 200 },
      'Generator (5000W)': { hour: 45, '4hour': 95, day: 145, week: 435, month: 1305, deposit: 300 },
      'Air Compressor': { hour: 30, '4hour': 75, day: 115, week: 345, month: 1035, deposit: 150 },
      'Jackhammer': { hour: 40, '4hour': 90, day: 135, week: 405, month: 1215, deposit: 250 }
    },
    lawn: {
      'Lawn Aerator': { hour: 25, '4hour': 65, day: 95, week: 285, month: 855, deposit: 150 },
      'Stump Grinder': { hour: 50, '4hour': 125, day: 185, week: 555, month: 1665, deposit: 300 },
      'Tiller': { hour: 30, '4hour': 75, day: 115, week: 345, month: 1035, deposit: 200 },
      'Chipper/Shredder': { hour: 45, '4hour': 115, day: 175, week: 525, month: 1575, deposit: 250 }
    },
    concrete: {
      'Concrete Mixer': { hour: 35, '4hour': 85, day: 125, week: 375, month: 1125, deposit: 200 },
      'Power Trowel': { hour: 40, '4hour': 95, day: 145, week: 435, month: 1305, deposit: 250 },
      'Concrete Vibrator': { hour: 25, '4hour': 65, day: 95, week: 285, month: 855, deposit: 150 },
      'Bull Float': { hour: 20, '4hour': 55, day: 85, week: 255, month: 765, deposit: 100 }
    },
    flooring: {
      'Floor Sander': { hour: 35, '4hour': 85, day: 125, week: 375, month: 1125, deposit: 200 },
      'Floor Edger': { hour: 30, '4hour': 75, day: 115, week: 345, month: 1035, deposit: 150 },
      'Floor Buffer': { hour: 25, '4hour': 65, day: 95, week: 285, month: 855, deposit: 150 },
      'Tile Saw': { hour: 30, '4hour': 75, day: 115, week: 345, month: 1035, deposit: 200 }
    },
    plumbing: {
      'Pipe Snake': { hour: 25, '4hour': 65, day: 95, week: 285, month: 855, deposit: 150 },
      'Pipe Threader': { hour: 35, '4hour': 85, day: 125, week: 375, month: 1125, deposit: 200 },
      'Pipe Cutter': { hour: 20, '4hour': 55, day: 85, week: 255, month: 765, deposit: 100 },
      'Drain Camera': { hour: 45, '4hour': 115, day: 175, week: 525, month: 1575, deposit: 300 }
    },
    lifting: {
      'Forklift': { hour: 75, '4hour': 185, day: 275, week: 825, month: 2475, deposit: 500, operator: true },
      'Scissor Lift': { hour: 65, '4hour': 165, day: 245, week: 735, month: 2205, deposit: 400, operator: true },
      'Boom Lift': { hour: 85, '4hour': 215, day: 325, week: 975, month: 2925, deposit: 600, operator: true },
      'Pallet Jack': { hour: 25, '4hour': 65, day: 95, week: 285, month: 855, deposit: 150 }
    }
  };

  // Additional fees
  const additionalFees = {
    delivery: {
      base: 75,
      perMile: 2.50
    },
    operator: {
      hourly: 65
    },
    insurance: {
      daily: 15,
      weekly: 75,
      monthly: 250
    }
  };

  const calculateEstimate = () => {
    if (!selectedTool) return null;

    const qty = parseInt(quantity);
    if (!qty) return null;

    // Get base rental rate
    const tool = toolInventory[category][selectedTool];
    const baseRate = tool[rentalPeriod];
    
    // Calculate rental cost
    const rentalCost = baseRate * qty;

    // Calculate deposit if included
    const depositCost = includeDeposit ? tool.deposit * qty : 0;

    // Calculate delivery if included (assume 10 miles average)
    const deliveryCost = includeDelivery ? 
      (additionalFees.delivery.base + (additionalFees.delivery.perMile * 10)) : 0;

    // Calculate operator cost if needed (assume full rental period)
    let operatorCost = 0;
    if (needsOperator && tool.operator) {
      switch (rentalPeriod) {
        case 'hour':
          operatorCost = additionalFees.operator.hourly;
          break;
        case '4hour':
          operatorCost = additionalFees.operator.hourly * 4;
          break;
        case 'day':
          operatorCost = additionalFees.operator.hourly * 8;
          break;
        case 'week':
          operatorCost = additionalFees.operator.hourly * 40;
          break;
        case 'month':
          operatorCost = additionalFees.operator.hourly * 160;
          break;
      }
    }

    // Calculate insurance cost
    let insuranceCost = 0;
    switch (rentalPeriod) {
      case 'hour':
      case '4hour':
      case 'day':
        insuranceCost = additionalFees.insurance.daily;
        break;
      case 'week':
        insuranceCost = additionalFees.insurance.weekly;
        break;
      case 'month':
        insuranceCost = additionalFees.insurance.monthly;
        break;
    }

    // Calculate totals
    const subtotal = rentalCost + depositCost + deliveryCost + operatorCost + insuranceCost;
    const tax = rentalCost * 0.075; // 7.5% sales tax on rental only
    const total = subtotal + tax;

    return {
      rental: rentalCost.toFixed(2),
      deposit: depositCost.toFixed(2),
      delivery: deliveryCost.toFixed(2),
      operator: operatorCost.toFixed(2),
      insurance: insuranceCost.toFixed(2),
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-yellow-600 to-yellow-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Wrench className="w-8 h-8 text-yellow-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Tool Rental Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Tool Category</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['power', 'lawn', 'concrete', 'flooring', 'plumbing', 'lifting'] as const).map((cat) => (
                <button
                  key={cat}
                  onClick={() => {
                    setCategory(cat);
                    setSelectedTool('');
                  }}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    category === cat
                      ? 'bg-yellow-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {cat}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Select Tool</label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {Object.keys(toolInventory[category]).map((tool) => (
                <button
                  key={tool}
                  onClick={() => setSelectedTool(tool)}
                  className={`py-2 px-4 rounded-lg ${
                    selectedTool === tool
                      ? 'bg-yellow-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {tool}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Rental Period</label>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
              {(['hour', '4hour', 'day', 'week', 'month'] as const).map((period) => (
                <button
                  key={period}
                  onClick={() => setRentalPeriod(period)}
                  className={`py-2 px-4 rounded-lg ${
                    rentalPeriod === period
                      ? 'bg-yellow-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {period === '4hour' ? '4 Hours' : 
                   period === 'hour' ? '1 Hour' : period}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Quantity</label>
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              min="1"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDeposit}
                onChange={(e) => setIncludeDeposit(e.target.checked)}
                className="rounded text-yellow-600 focus:ring-yellow-500"
              />
              <span>Include Security Deposit</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDelivery}
                onChange={(e) => setIncludeDelivery(e.target.checked)}
                className="rounded text-yellow-600 focus:ring-yellow-500"
              />
              <span>Include Delivery</span>
            </label>
            {selectedTool && toolInventory[category][selectedTool].operator && (
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={needsOperator}
                  onChange={(e) => setNeedsOperator(e.target.checked)}
                  className="rounded text-yellow-600 focus:ring-yellow-500"
                />
                <span>Include Certified Operator</span>
              </label>
            )}
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-yellow-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Rental Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Rental Period:</span>
                  </div>
                  <span className="font-semibold capitalize">
                    {rentalPeriod === '4hour' ? '4 Hours' : 
                     rentalPeriod === 'hour' ? '1 Hour' : rentalPeriod}
                  </span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Rental Fee:</span>
                    <span className="font-semibold">${results.rental}</span>
                  </div>
                  {parseFloat(results.deposit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Security Deposit:</span>
                      <span className="font-semibold">${results.deposit}</span>
                    </div>
                  )}
                  {parseFloat(results.delivery) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Delivery:</span>
                      <span className="font-semibold">${results.delivery}</span>
                    </div>
                  )}
                  {parseFloat(results.operator) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Operator:</span>
                      <span className="font-semibold">${results.operator}</span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span className="text-gray-600">Insurance:</span>
                    <span className="font-semibold">${results.insurance}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-yellow-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-yellow-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-yellow-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-yellow-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary by location.</p>
                <p>* Security deposits are fully refundable upon return of equipment in good condition.</p>
                <p>* Some equipment requires certification or training for operation.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Rental Guidelines</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-semibold text-gray-800">Requirements</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Valid government-issued ID</li>
                  <li>Credit card for deposit</li>
                  <li>Proof of insurance (for certain equipment)</li>
                  <li>Signed rental agreement</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">Policies</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>24-hour notice for cancellation</li>
                  <li>Equipment must be returned clean</li>
                  <li>Fuel tanks must be full upon return</li>
                  <li>Late returns subject to additional charges</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}