import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  MapPin, Home, Calculator, Wrench, Zap, Construction, 
  Paintbrush, Bath, ChefHat, Thermometer, Car, Droplet, 
  Sun, Building2, Square, Waves, Warehouse, Fence, Flower2, 
  Shovel, Heart, DollarSign, Layers, Sparkles, TrendingUp, 
  Shield, CircleDot, Club, Building, Grid, Search, DoorOpen
} from 'lucide-react';
import BusinessDirectory from '../components/BusinessDirectory';
import { localBusinesses } from '../data/localBusinesses';

// Tool categories for filtering
const categories = {
  financial: 'Financial & ROI',
  home: 'Home Improvement',
  outdoor: 'Outdoor & Landscaping',
  construction: 'Construction',
  services: 'Services',
  utilities: 'Utilities & Systems'
};

// Complete tools array based on all available pages
const tools = [
  // Financial & ROI Category
  {
    title: 'Home Value Calculator',
    description: 'Calculate your home value and ROI for improvements',
    icon: TrendingUp,
    path: '/home-value',
    color: 'bg-green-600',
    category: 'financial',
    tags: ['value', 'roi', 'investment', 'equity', 'property']
  },
  {
    title: 'Home Insurance Calculator',
    description: 'Estimate home insurance costs and coverage needs',
    icon: Shield,
    path: '/home-insurance',
    color: 'bg-blue-600',
    category: 'financial',
    tags: ['insurance', 'coverage', 'protection', 'policy']
  },
  {
    title: 'Mortgage Strategy Calculator',
    description: 'Calculate mortgage payments and refinancing options',
    icon: DollarSign,
    path: '/mortgage-strategy',
    color: 'bg-purple-600',
    category: 'financial',
    tags: ['mortgage', 'loan', 'refinance', 'payments', 'interest']
  },

  // Home Improvement Category
  {
    title: 'Door Calculator',
    description: 'Calculate costs for door installation and replacement',
    icon: DoorOpen,
    path: '/door-calculator',
    color: 'bg-amber-600',
    category: 'home',
    tags: ['doors', 'entry', 'interior', 'garage', 'installation']
  },
  {
    title: 'Kitchen Remodel Calculator',
    description: 'Calculate costs for kitchen renovation including cabinets, countertops, and appliances',
    icon: ChefHat,
    path: '/kitchen',
    color: 'bg-red-600',
    category: 'home',
    tags: ['kitchen', 'remodel', 'renovation', 'cabinets', 'countertops']
  },
  {
    title: 'Bathroom Calculator',
    description: 'Estimate bathroom remodeling and renovation costs',
    icon: Bath,
    path: '/bathroom',
    color: 'bg-teal-600',
    category: 'home',
    tags: ['bathroom', 'remodel', 'plumbing', 'fixtures', 'renovation']
  },
  {
    title: 'Basement Calculator',
    description: 'Calculate basement finishing and remodeling costs',
    icon: Building2,
    path: '/basement',
    color: 'bg-stone-600',
    category: 'home',
    tags: ['basement', 'finishing', 'remodel', 'renovation']
  },
  {
    title: 'Cabinet Calculator',
    description: 'Estimate costs for cabinet installation and materials',
    icon: Grid,
    path: '/cabinet',
    color: 'bg-amber-800',
    category: 'home',
    tags: ['cabinets', 'storage', 'kitchen', 'bathroom', 'installation']
  },
  {
    title: 'Flooring Calculator',
    description: 'Calculate flooring installation and material costs',
    icon: Square,
    path: '/flooring',
    color: 'bg-amber-700',
    category: 'home',
    tags: ['flooring', 'hardwood', 'tile', 'carpet', 'installation']
  },
  {
    title: 'Window Calculator',
    description: 'Estimate window replacement and installation costs',
    icon: Square,
    path: '/windows',
    color: 'bg-cyan-600',
    category: 'home',
    tags: ['windows', 'replacement', 'installation', 'energy efficiency']
  },
  {
    title: 'Painting Estimator',
    description: 'Calculate interior and exterior painting costs',
    icon: Paintbrush,
    path: '/painting',
    color: 'bg-rose-600',
    category: 'home',
    tags: ['painting', 'interior', 'exterior', 'decoration']
  },
  {
    title: 'Insulation Calculator',
    description: 'Estimate insulation costs and energy savings',
    icon: Layers,
    path: '/insulation',
    color: 'bg-orange-500',
    category: 'home',
    tags: ['insulation', 'energy efficiency', 'temperature control']
  },

  // Construction Category
  {
    title: 'General Contractor Calculator',
    description: 'Estimate costs for new construction and major renovation projects',
    icon: Building,
    path: '/general-contractor',
    color: 'bg-gray-700',
    category: 'construction',
    tags: ['construction', 'contractor', 'building', 'renovation']
  },
  {
    title: 'Concrete Calculator',
    description: 'Calculate concrete volumes and costs',
    icon: Construction,
    path: '/concrete',
    color: 'bg-gray-600',
    category: 'construction',
    tags: ['concrete', 'foundation', 'construction', 'materials']
  },
  {
    title: 'Excavation Calculator',
    description: 'Calculate material volumes and excavation costs',
    icon: Shovel,
    path: '/excavation',
    color: 'bg-yellow-600',
    category: 'construction',
    tags: ['excavation', 'digging', 'site preparation']
  },
  {
    title: 'Asphalt Calculator',
    description: 'Calculate asphalt paving and maintenance costs',
    icon: Construction,
    path: '/asphalt',
    color: 'bg-gray-800',
    category: 'construction',
    tags: ['asphalt', 'paving', 'driveway', 'maintenance']
  },
  {
    title: 'Roofing Calculator',
    description: 'Calculate roofing costs and materials',
    icon: Home,
    path: '/roofing',
    color: 'bg-slate-600',
    category: 'construction',
    tags: ['roofing', 'shingles', 'repair', 'installation']
  },
  {
    title: 'Siding Calculator',
    description: 'Calculate siding installation and material costs',
    icon: Layers,
    path: '/siding',
    color: 'bg-indigo-600',
    category: 'construction',
    tags: ['siding', 'exterior', 'installation']
  },

  // Outdoor & Landscaping Category
  {
    title: 'Landscaping Estimator',
    description: 'Calculate landscaping and hardscaping costs',
    icon: Flower2,
    path: '/landscaping',
    color: 'bg-emerald-500',
    category: 'outdoor',
    tags: ['landscaping', 'garden', 'lawn', 'hardscape']
  },
  {
    title: 'Pool Calculator',
    description: 'Estimate pool installation and maintenance costs',
    icon: Waves,
    path: '/pool',
    color: 'bg-blue-400',
    category: 'outdoor',
    tags: ['pool', 'swimming', 'installation', 'maintenance']
  },
  {
    title: 'Deck Calculator',
    description: 'Calculate deck construction and material costs',
    icon: Construction,
    path: '/deck',
    color: 'bg-amber-600',
    category: 'outdoor',
    tags: ['deck', 'patio', 'outdoor living', 'construction']
  },
  {
    title: 'Fencing Calculator',
    description: 'Calculate fencing installation and material costs',
    icon: Fence,
    path: '/fencing',
    color: 'bg-orange-600',
    category: 'outdoor',
    tags: ['fence', 'privacy', 'security', 'installation']
  },
  {
    title: 'Garage Calculator',
    description: 'Calculate garage construction and renovation costs',
    icon: Warehouse,
    path: '/garage',
    color: 'bg-zinc-600',
    category: 'outdoor',
    tags: ['garage', 'storage', 'construction', 'renovation']
  },

  // Utilities & Systems Category
  {
    title: 'HVAC Estimator',
    description: 'Calculate heating and cooling system costs',
    icon: Thermometer,
    path: '/hvac',
    color: 'bg-sky-500',
    category: 'utilities',
    tags: ['hvac', 'heating', 'cooling', 'air conditioning']
  },
  {
    title: 'Plumbing Estimator',
    description: 'Calculate plumbing repair and installation costs',
    icon: Wrench,
    path: '/plumbing',
    color: 'bg-blue-500',
    category: 'utilities',
    tags: ['plumbing', 'pipes', 'water', 'repair']
  },
  {
    title: 'Electrical Estimator',
    description: 'Calculate electrical work and installation costs',
    icon: Zap,
    path: '/electrical',
    color: 'bg-amber-500',
    category: 'utilities',
    tags: ['electrical', 'wiring', 'installation', 'repair']
  },
  {
    title: 'Solar Calculator',
    description: 'Calculate solar panel installation costs and savings',
    icon: Sun,
    path: '/solar',
    color: 'bg-yellow-500',
    category: 'utilities',
    tags: ['solar', 'energy', 'renewable', 'installation']
  },
  {
    title: 'Well Drilling Calculator',
    description: 'Estimate well drilling and water system costs',
    icon: Shovel,
    path: '/well-drilling',
    color: 'bg-blue-800',
    category: 'utilities',
    tags: ['well', 'water', 'drilling', 'installation']
  },
  {
    title: 'Septic Calculator',
    description: 'Calculate septic system installation costs',
    icon: Droplet,
    path: '/septic',
    color: 'bg-blue-700',
    category: 'utilities',
    tags: ['septic', 'waste', 'installation', 'plumbing']
  },

  // Services Category
  {
    title: 'Handyman Calculator',
    description: 'Calculate costs for common repairs and maintenance',
    icon: Wrench,
    path: '/handyman',
    color: 'bg-orange-600',
    category: 'services',
    tags: ['handyman', 'repair', 'maintenance', 'installation']
  },
  {
    title: 'Cleaning Calculator',
    description: 'Calculate professional cleaning service costs',
    icon: Sparkles,
    path: '/cleaning',
    color: 'bg-emerald-400',
    category: 'services',
    tags: ['cleaning', 'maintenance', 'services']
  },
  {
    title: 'Auto Repair Calculator',
    description: 'Calculate auto repair and maintenance costs',
    icon: Car,
    path: '/auto-repair',
    color: 'bg-purple-500',
    category: 'services',
    tags: ['auto', 'car', 'repair', 'maintenance']
  },
  {
    title: 'Tool Rental Calculator',
    description: 'Calculate tool rental costs and durations',
    icon: Wrench,
    path: '/tool-rental',
    color: 'bg-yellow-600',
    category: 'services',
    tags: ['tools', 'rental', 'equipment']
  },
  {
    title: 'Wedding Calculator',
    description: 'Plan wedding budgets and estimate costs',
    icon: Heart,
    path: '/wedding',
    color: 'bg-pink-500',
    category: 'services',
    tags: ['wedding', 'event', 'planning', 'budget']
  },
  {
    title: 'Golf Score Calculator',
    description: 'Track golf scores and calculate handicaps',
    icon: CircleDot,
    path: '/golf-score',
    color: 'bg-green-700',
    category: 'services',
    tags: ['golf', 'sports', 'score', 'tracking']
  }
];

export default function ToolIndex() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  // Filter tools based on search term and category
  const filteredTools = tools.filter(tool => {
    const matchesSearch = 
      tool.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tool.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      tool.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesCategory = selectedCategory === 'all' || tool.category === selectedCategory;
    
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-12">
        {/* Header Section */}
        <div className="text-center mb-12">
          <div className="flex items-center justify-center mb-4">
            <MapPin className="w-8 h-8 text-blue-600 mr-2" />
            <h1 className="text-4xl font-bold text-gray-900">Local Business Tools</h1>
          </div>
          <p className="text-xl text-gray-600">Free calculators and tools for businesses in the 45865 area</p>
        </div>

        {/* Search and Filter Section */}
        <div className="mb-8 space-y-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search tools..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedCategory('all')}
              className={`px-4 py-2 rounded-full text-sm font-medium ${
                selectedCategory === 'all'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              All Tools
            </button>
            {Object.entries(categories).map(([key, label]) => (
              <button
                key={key}
                onClick={() => setSelectedCategory(key)}
                className={`px-4 py-2 rounded-full text-sm font-medium ${
                  selectedCategory === key
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Tools Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredTools.map((tool) => {
            const Icon = tool.icon;
            const businessCategory = tool.path.replace('/', '');
            const localProviders = localBusinesses[businessCategory as keyof typeof localBusinesses];
            
            return (
              <div key={tool.path} className="space-y-4">
                <Link
                  to={tool.path}
                  className="block group"
                >
                  <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow p-6 h-full">
                    <div className={`${tool.color} text-white p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4`}>
                      <Icon className="w-6 h-6" />
                    </div>
                    <h2 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-blue-600 transition-colors">
                      {tool.title}
                    </h2>
                    <p className="text-gray-600">
                      {tool.description}
                    </p>
                    <div className="mt-4 flex flex-wrap gap-2">
                      {tool.tags.map((tag, index) => (
                        <span
                          key={index}
                          className="inline-block bg-gray-100 rounded-full px-3 py-1 text-xs font-semibold text-gray-600"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </Link>

                {localProviders && (
                  <BusinessDirectory businesses={localProviders} />
                )}
              </div>
            );
          })}
        </div>

        {/* No Results Message */}
        {filteredTools.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500 text-lg">No tools found matching your search criteria.</p>
          </div>
        )}

        {/* SEO Footer */}
        <footer className="mt-16 pt-8 border-t border-gray-200">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-4">About Our Tools</h3>
              <p className="text-gray-600">Free, accurate calculators and estimators for home improvement, construction, and business projects in the Lima/Dayton area. Get reliable cost estimates for your next project.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Service Area</h3>
              <p className="text-gray-600">Serving Lima, Dayton, and surrounding areas in Ohio with local market rates and accurate estimations for all your project needs.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Updates</h3>
              <p className="text-gray-600">Our calculators are regularly updated with current market rates and prices specific to the Lima/Dayton region.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}