import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Share2, QrCode } from 'lucide-react';

interface ShareSectionProps {
  currentUrl: string;
}

export default function ShareSection({ currentUrl }: ShareSectionProps) {
  const [showQR, setShowQR] = useState(false);

  return (
    <div className="mt-8 border-t border-gray-200 pt-6">
      <div className="flex flex-col items-center space-y-4">
        <div className="flex items-center space-x-2">
          <Share2 className="w-4 h-4 text-gray-500" />
          <span className="text-sm text-gray-600">Share this calculator:</span>
        </div>
        
        <button
          onClick={() => setShowQR(!showQR)}
          className="flex items-center space-x-2 text-sm text-gray-600 hover:text-gray-800 transition"
        >
          <QrCode className="w-4 h-4" />
          <span>{showQR ? 'Hide QR Code' : 'Show QR Code'}</span>
        </button>

        {showQR && (
          <div className="p-4 bg-white rounded-lg shadow-md">
            <QRCodeSVG value={currentUrl} size={128} />
          </div>
        )}

        <a
          href={currentUrl}
          className="text-sm text-blue-600 hover:text-blue-800 transition"
        >
          Direct Link to Calculator
        </a>
      </div>
    </div>
  );
}