import React from 'react';
import type { WindowConfig, WindowDetails } from '../../types/windows';
import { PaintOptions } from '../doors/PaintOptions';

interface WindowConfigPanelProps {
  config: WindowConfig;
  onChange: (updates: Partial<WindowConfig>) => void;
}

export function WindowConfigPanel({ config, onChange }: WindowConfigPanelProps) {
  const updateDetails = (updates: Partial<WindowDetails>) => {
    onChange({
      ...config,
      details: {
        ...config.details,
        ...updates
      }
    });
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Window Type</label>
          <select
            value={config.type}
            onChange={(e) => onChange({ ...config, type: e.target.value as WindowConfig['type'] })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          >
            <option value="single">Single Hung</option>
            <option value="double">Double Hung</option>
            <option value="casement">Casement</option>
            <option value="sliding">Sliding</option>
            <option value="bay">Bay Window</option>
            <option value="picture">Picture Window</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Material</label>
          <select
            value={config.material}
            onChange={(e) => onChange({ ...config, material: e.target.value as WindowConfig['material'] })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          >
            <option value="vinyl">Vinyl</option>
            <option value="wood">Wood</option>
            <option value="aluminum">Aluminum</option>
            <option value="fiberglass">Fiberglass</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Glass Type</label>
          <select
            value={config.glass}
            onChange={(e) => onChange({ ...config, glass: e.target.value as WindowConfig['glass'] })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          >
            <option value="standard">Standard</option>
            <option value="lowE">Low-E</option>
            <option value="triple">Triple Pane</option>
            <option value="argon">Argon Gas</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Grade</label>
          <select
            value={config.grade}
            onChange={(e) => onChange({ ...config, grade: e.target.value as WindowConfig['grade'] })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          >
            <option value="economy">Economy</option>
            <option value="standard">Standard</option>
            <option value="premium">Premium</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Width (inches)</label>
          <input
            type="number"
            value={config.details.width}
            onChange={(e) => updateDetails({ width: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Height (inches)</label>
          <input
            type="number"
            value={config.details.height}
            onChange={(e) => updateDetails({ height: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Quantity</label>
          <input
            type="number"
            value={config.details.quantity}
            onChange={(e) => updateDetails({ quantity: e.target.value })}
            min="1"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeFrame}
              onChange={(e) => updateDetails({ includeFrame: e.target.checked })}
              className="rounded text-cyan-600 focus:ring-cyan-500"
            />
            <span>Include Frame</span>
          </label>

          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeHardware}
              onChange={(e) => updateDetails({ includeHardware: e.target.checked })}
              className="rounded text-cyan-600 focus:ring-cyan-500"
            />
            <span>Include Hardware</span>
          </label>

          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeScreens}
              onChange={(e) => updateDetails({ includeScreens: e.target.checked })}
              className="rounded text-cyan-600 focus:ring-cyan-500"
            />
            <span>Include Screens</span>
          </label>
        </div>

        <div className="space-y-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeGrilles}
              onChange={(e) => updateDetails({ includeGrilles: e.target.checked })}
              className="rounded text-cyan-600 focus:ring-cyan-500"
            />
            <span>Include Grilles</span>
          </label>

          {config.details.includeGrilles && (
            <select
              value={config.details.grillPattern || 'colonial'}
              onChange={(e) => updateDetails({ grillPattern: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring focus:ring-cyan-200"
            >
              <option value="colonial">Colonial</option>
              <option value="prairie">Prairie</option>
              <option value="diamond">Diamond</option>
              <option value="custom">Custom</option>
            </select>
          )}

          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.needsStructuralWork}
              onChange={(e) => updateDetails({ needsStructuralWork: e.target.checked })}
              className="rounded text-cyan-600 focus:ring-cyan-500"
            />
            <span>Needs Structural Work</span>
          </label>
        </div>
      </div>

      <div className="border-t pt-4">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">Finishing Options</h3>
        <PaintOptions
          details={config.details}
          onChange={updateDetails}
        />
      </div>
    </div>
  );
}