import React from 'react';
import type { DoorConfig, DoorDetails } from '../../types/doors';
import { PaintOptions } from './PaintOptions';

interface DoorConfigPanelProps {
  config: DoorConfig;
  onChange: (updates: Partial<DoorConfig>) => void;
}

export function DoorConfigPanel({ config, onChange }: DoorConfigPanelProps) {
  const updateDetails = (updates: Partial<DoorDetails>) => {
    onChange({
      ...config,
      details: {
        ...config.details,
        ...updates
      }
    });
  };

  const handleTypeChange = (type: DoorConfig['type']) => {
    onChange({ ...config, type });
  };

  const handleMaterialChange = (material: DoorConfig['material']) => {
    onChange({ ...config, material });
  };

  const handleStyleChange = (style: DoorConfig['style']) => {
    onChange({ ...config, style });
  };

  const handleGradeChange = (grade: DoorConfig['grade']) => {
    onChange({ ...config, grade });
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Door Type</label>
          <select
            value={config.type}
            onChange={(e) => handleTypeChange(e.target.value as DoorConfig['type'])}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          >
            <option value="entry">Entry Door</option>
            <option value="interior">Interior Door</option>
            <option value="garage">Garage Door</option>
            <option value="storm">Storm Door</option>
            <option value="patio">Patio Door</option>
            <option value="custom">Custom Door</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Material</label>
          <select
            value={config.material}
            onChange={(e) => handleMaterialChange(e.target.value as DoorConfig['material'])}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          >
            <option value="steel">Steel</option>
            <option value="wood">Wood</option>
            <option value="fiberglass">Fiberglass</option>
            <option value="aluminum">Aluminum</option>
            <option value="vinyl">Vinyl</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Style</label>
          <select
            value={config.style}
            onChange={(e) => handleStyleChange(e.target.value as DoorConfig['style'])}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          >
            <option value="panel">Panel</option>
            <option value="flush">Flush</option>
            <option value="glass">Glass</option>
            <option value="craftsman">Craftsman</option>
            <option value="french">French</option>
            <option value="barn">Barn</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Grade</label>
          <select
            value={config.grade}
            onChange={(e) => handleGradeChange(e.target.value as DoorConfig['grade'])}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          >
            <option value="economy">Economy</option>
            <option value="standard">Standard</option>
            <option value="premium">Premium</option>
            <option value="custom">Custom</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Width (inches)</label>
          <input
            type="number"
            value={config.details.width}
            onChange={(e) => updateDetails({ width: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Height (inches)</label>
          <input
            type="number"
            value={config.details.height}
            onChange={(e) => updateDetails({ height: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Quantity</label>
          <input
            type="number"
            value={config.details.quantity}
            onChange={(e) => updateDetails({ quantity: e.target.value })}
            min="1"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeFrame}
              onChange={(e) => updateDetails({ includeFrame: e.target.checked })}
              className="rounded text-amber-600 focus:ring-amber-500"
            />
            <span>Include Frame</span>
          </label>

          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeHardware}
              onChange={(e) => updateDetails({ includeHardware: e.target.checked })}
              className="rounded text-amber-600 focus:ring-amber-500"
            />
            <span>Include Hardware</span>
          </label>
        </div>

        <div className="space-y-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.includeFinishing}
              onChange={(e) => updateDetails({ includeFinishing: e.target.checked })}
              className="rounded text-amber-600 focus:ring-amber-500"
            />
            <span>Include Finishing</span>
          </label>

          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={config.details.needsStructuralWork}
              onChange={(e) => updateDetails({ needsStructuralWork: e.target.checked })}
              className="rounded text-amber-600 focus:ring-amber-500"
            />
            <span>Needs Structural Work</span>
          </label>
        </div>
      </div>

      <div className="border-t pt-4">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">Paint Options</h3>
        <PaintOptions
          details={config.details}
          onChange={updateDetails}
        />
      </div>
    </div>
  );
}