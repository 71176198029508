import React from 'react';
import { Construction, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type TooltipProps = {
  text: string;
  children: React.ReactNode;
};

const Tooltip = ({ text, children }: TooltipProps) => (
  <div className="group relative inline-block">
    {children}
    <div className="invisible group-hover:visible absolute z-50 w-48 p-2 mt-1 text-sm text-white bg-gray-900 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
      {text}
    </div>
  </div>
);

export default function RoofingCalculator() {
  // Add your state and calculation logic here
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-700 to-slate-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Construction className="w-8 h-8 text-slate-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Roofing Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Roof Style</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {([
                { type: 'gable', desc: 'The most common roof type - looks like an upside-down V' },
                { type: 'hip', desc: 'Slopes on all four sides, like a pyramid' },
                { type: 'flat', desc: 'Horizontal or nearly level roof surface' },
                { type: 'mansard', desc: 'Two slopes on each side with the lower slope being steeper' },
                { type: 'gambrel', desc: 'Barn-style roof with two different slopes' }
              ] as const).map(({ type, desc }) => (
                <Tooltip key={type} text={desc}>
                  <button
                    className="py-2 px-4 rounded-lg capitalize bg-gray-100 text-gray-700 hover:bg-gray-200"
                  >
                    {type}
                  </button>
                </Tooltip>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              <Tooltip text="Roof pitch measures how steep your roof is. A 4/12 pitch means the roof rises 4 inches for every 12 inches of horizontal distance">
                <span className="flex items-center">
                  Pitch (Rise per 12 inches)
                  <span className="ml-1 text-gray-400">(Hover for info)</span>
                </span>
              </Tooltip>
            </label>
            {/* Add pitch selection input here */}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              <Tooltip text="Valleys are where two roof slopes meet, forming a V-shaped channel">
                <span className="flex items-center">
                  Number of Valleys
                  <span className="ml-1 text-gray-400">(Hover for info)</span>
                </span>
              </Tooltip>
            </label>
            {/* Add valleys input here */}
          </div>

          {/* Add more form elements and calculations here */}

          <div className="bg-slate-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-slate-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-slate-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs or special requirements.</p>
                <p>* Professional inspection recommended for accurate assessment.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}