import React, { useState } from 'react';
import { Tractor, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type CalculatorType = 'seed' | 'fertilizer' | 'yield' | 'irrigation' | 'equipment' | 'roi';

export default function FarmCalculator() {
  const [calculatorType, setCalculatorType] = useState<CalculatorType>('seed');
  const [fieldSize, setFieldSize] = useState('');
  const [cropType, setCropType] = useState('corn');
  const [irrigationType, setIrrigationType] = useState('none');
  const [soilType, setSoilType] = useState('loam');
  const [previousCrop, setPreviousCrop] = useState('none');
  const [equipmentHours, setEquipmentHours] = useState('');
  
  // Van Wert County typical data
  const crops = {
    // Traditional row crops
    corn: {
      seedRate: 32000,
      seedCost: 120,
      fertilizerRate: 180,
      expectedYield: 175,
      waterNeeds: 22,
      pricePerUnit: 4.25,
      unit: 'bushel',
      nitrogenCredit: {
        soybeans: 45,
        alfalfa: 150,
        clover: 100,
        none: 0
      }
    },
    soybeans: {
      seedRate: 140000,
      seedCost: 60,
      fertilizerRate: 45,
      expectedYield: 52,
      waterNeeds: 18,
      pricePerUnit: 12.50,
      unit: 'bushel',
      nitrogenCredit: {
        corn: 0,
        alfalfa: 0,
        none: 0
      }
    },
    wheat: {
      seedRate: 1600000,
      seedCost: 25,
      fertilizerRate: 90,
      expectedYield: 75,
      waterNeeds: 16,
      pricePerUnit: 6.75,
      unit: 'bushel',
      nitrogenCredit: {
        soybeans: 20,
        alfalfa: 100,
        none: 0
      }
    },
    // Forage crops
    alfalfa: {
      seedRate: 12000000,
      seedCost: 85,
      fertilizerRate: 15,
      expectedYield: 4.5,
      waterNeeds: 30,
      pricePerUnit: 200.00,
      unit: 'ton',
      nitrogenCredit: {
        corn: 0,
        soybeans: 0,
        none: 0
      }
    },
    clover: {
      seedRate: 9000000,
      seedCost: 65,
      fertilizerRate: 10,
      expectedYield: 3.0,
      waterNeeds: 25,
      pricePerUnit: 180.00,
      unit: 'ton',
      nitrogenCredit: {
        corn: 0,
        soybeans: 0,
        none: 0
      }
    },
    // Specialty crops
    hemp: {
      seedRate: 400000,
      seedCost: 350,
      fertilizerRate: 150,
      expectedYield: 1000, // pounds per acre
      waterNeeds: 20,
      pricePerUnit: 0.45, // per pound (fiber)
      unit: 'pound',
      nitrogenCredit: {
        corn: 0,
        soybeans: 0,
        none: 0
      }
    },
    oats: {
      seedRate: 1300000,
      seedCost: 20,
      fertilizerRate: 60,
      expectedYield: 85,
      waterNeeds: 15,
      pricePerUnit: 4.00,
      unit: 'bushel',
      nitrogenCredit: {
        soybeans: 15,
        alfalfa: 80,
        none: 0
      }
    },
    rye: {
      seedRate: 1100000,
      seedCost: 22,
      fertilizerRate: 70,
      expectedYield: 65,
      waterNeeds: 14,
      pricePerUnit: 5.50,
      unit: 'bushel',
      nitrogenCredit: {
        soybeans: 15,
        alfalfa: 75,
        none: 0
      }
    },
    barley: {
      seedRate: 1250000,
      seedCost: 24,
      fertilizerRate: 75,
      expectedYield: 70,
      waterNeeds: 15,
      pricePerUnit: 5.00,
      unit: 'bushel',
      nitrogenCredit: {
        soybeans: 15,
        alfalfa: 80,
        none: 0
      }
    },
    sunflowers: {
      seedRate: 25000,
      seedCost: 75,
      fertilizerRate: 90,
      expectedYield: 1800, // pounds per acre
      waterNeeds: 20,
      pricePerUnit: 0.20,
      unit: 'pound',
      nitrogenCredit: {
        soybeans: 0,
        alfalfa: 0,
        none: 0
      }
    }
  };

  const soilTypes = {
    sand: { waterHoldingCapacity: 0.5, nutrientHoldingCapacity: 0.7 },
    loam: { waterHoldingCapacity: 1.0, nutrientHoldingCapacity: 1.0 },
    clay: { waterHoldingCapacity: 1.3, nutrientHoldingCapacity: 1.2 },
    siltLoam: { waterHoldingCapacity: 1.2, nutrientHoldingCapacity: 1.1 },
    sandyLoam: { waterHoldingCapacity: 0.8, nutrientHoldingCapacity: 0.9 }
  };

  const irrigationSystems = {
    none: { efficiency: 0, cost: 0, maintenanceCost: 0 },
    drip: { efficiency: 0.95, cost: 1200, maintenanceCost: 100 },
    sprinkler: { efficiency: 0.75, cost: 800, maintenanceCost: 75 },
    pivot: { efficiency: 0.85, cost: 1500, maintenanceCost: 150 },
    subsurface: { efficiency: 0.90, cost: 1800, maintenanceCost: 120 }
  };

  const equipmentCosts = {
    tractor: { hourly: 45, maintenance: 15 },
    combine: { hourly: 85, maintenance: 25 },
    planter: { hourly: 35, maintenance: 10 },
    sprayer: { hourly: 30, maintenance: 8 },
    cultivator: { hourly: 25, maintenance: 7 },
    drill: { hourly: 40, maintenance: 12 },
    baler: { hourly: 50, maintenance: 15 }
  };

  const calculateSeedNeeds = () => {
    const acres = parseFloat(fieldSize);
    if (!acres) return null;
    
    const crop = crops[cropType as keyof typeof crops];
    const totalSeeds = crop.seedRate * acres;
    const totalCost = crop.seedCost * acres;
    
    return {
      seeds: Math.round(totalSeeds).toLocaleString(),
      cost: totalCost.toFixed(2),
      acres: acres.toFixed(1)
    };
  };

  const calculateFertilizer = () => {
    const acres = parseFloat(fieldSize);
    if (!acres) return null;

    const crop = crops[cropType as keyof typeof crops];
    const nitrogenCredit = crop.nitrogenCredit[previousCrop as keyof typeof crop.nitrogenCredit] || 0;
    const soilFactor = soilTypes[soilType as keyof typeof soilTypes].nutrientHoldingCapacity;
    
    const adjustedRate = Math.max(0, crop.fertilizerRate - nitrogenCredit) * soilFactor;
    const totalN = adjustedRate * acres;
    const ureaNeeded = totalN * 2.17;
    const estimatedCost = ureaNeeded * 0.35;
    
    return {
      nitrogen: Math.round(totalN),
      urea: Math.round(ureaNeeded),
      cost: estimatedCost.toFixed(2),
      acres: acres.toFixed(1),
      creditApplied: nitrogenCredit
    };
  };

  const calculateIrrigation = () => {
    const acres = parseFloat(fieldSize);
    if (!acres || irrigationType === 'none') return null;

    const crop = crops[cropType as keyof typeof crops];
    const system = irrigationSystems[irrigationType as keyof typeof irrigationSystems];
    const soil = soilTypes[soilType as keyof typeof soilTypes];

    const waterNeeded = (crop.waterNeeds / soil.waterHoldingCapacity) * acres;
    const adjustedWater = waterNeeded / system.efficiency;
    const installCost = system.cost * acres;
    const annualCost = (system.maintenanceCost * acres) + (adjustedWater * 2.5);

    return {
      waterNeeded: Math.round(adjustedWater),
      installCost: installCost.toFixed(2),
      annualCost: annualCost.toFixed(2),
      efficiency: (system.efficiency * 100).toFixed(0)
    };
  };

  const calculateEquipment = () => {
    const hours = parseFloat(equipmentHours);
    if (!hours) return null;

    const totalHourlyCost = Object.values(equipmentCosts).reduce((sum, item) => sum + item.hourly, 0);
    const totalMaintenanceCost = Object.values(equipmentCosts).reduce((sum, item) => sum + item.maintenance, 0);
    
    const operatingCost = totalHourlyCost * hours;
    const maintenanceCost = totalMaintenanceCost * hours;
    const fuelCost = hours * 8 * 4.25; // 8 gallons per hour at $4.25/gallon

    return {
      operating: operatingCost.toFixed(2),
      maintenance: maintenanceCost.toFixed(2),
      fuel: fuelCost.toFixed(2),
      total: (operatingCost + maintenanceCost + fuelCost).toFixed(2),
      hours: hours.toFixed(1)
    };
  };

  const calculateYield = () => {
    const acres = parseFloat(fieldSize);
    if (!acres) return null;

    const crop = crops[cropType as keyof typeof crops];
    const soil = soilTypes[soilType as keyof typeof soilTypes];
    const irrigation = irrigationSystems[irrigationType as keyof typeof irrigationSystems];
    
    let yieldMultiplier = soil.nutrientHoldingCapacity;
    if (irrigationType !== 'none') {
      yieldMultiplier *= (1 + (irrigation.efficiency * 0.2));
    }
    
    const expectedYield = crop.expectedYield * acres * yieldMultiplier;
    const potentialRevenue = expectedYield * crop.pricePerUnit;
    
    const seedCost = parseFloat(calculateSeedNeeds()?.cost || '0');
    const fertilizerCost = parseFloat(calculateFertilizer()?.cost || '0');
    const irrigationCost = parseFloat(calculateIrrigation()?.annualCost || '0');
    const equipmentCost = acres * 55;
    
    const totalCosts = seedCost + fertilizerCost + irrigationCost + equipmentCost;
    const netReturn = potentialRevenue - totalCosts;
    const roi = (netReturn / totalCosts) * 100;

    return {
      yield: Math.round(expectedYield).toLocaleString(),
      unit: crop.unit,
      revenue: potentialRevenue.toFixed(2),
      costs: totalCosts.toFixed(2),
      netReturn: netReturn.toFixed(2),
      roi: roi.toFixed(1),
      acres: acres.toFixed(1)
    };
  };

  const results = {
    seed: calculateSeedNeeds(),
    fertilizer: calculateFertilizer(),
    irrigation: calculateIrrigation(),
    equipment: calculateEquipment(),
    yield: calculateYield()
  }[calculatorType];

  return (
    <div className="min-h-screen bg-gradient-to-b from-emerald-800 to-emerald-600 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Tractor className="w-8 h-8 text-emerald-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Farm Calculator</h1>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-6">
            {(['seed', 'fertilizer', 'irrigation', 'equipment', 'yield', 'roi'] as const).map((type) => (
              <button
                key={type}
                onClick={() => setCalculatorType(type)}
                className={`py-2 px-4 rounded-lg capitalize ${
                  calculatorType === type
                    ? 'bg-emerald-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {type}
              </button>
            ))}
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Crop Type</label>
              <select
                value={cropType}
                onChange={(e) => setCropType(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              >
                <optgroup label="Row Crops">
                  <option value="corn">Corn</option>
                  <option value="soybeans">Soybeans</option>
                  <option value="wheat">Wheat</option>
                </optgroup>
                <optgroup label="Forage Crops">
                  <option value="alfalfa">Alfalfa</option>
                  <option value="clover">Clover</option>
                </optgroup>
                <optgroup label="Specialty Crops">
                  <option value="hemp">Hemp</option>
                  <option value="oats">Oats</option>
                  <option value="rye">Rye</option>
                  <option value="barley">Barley</option>
                  <option value="sunflowers">Sunflowers</option>
                </optgroup>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Field Size (acres)</label>
              <input
                type="number"
                value={fieldSize}
                onChange={(e) => setFieldSize(e.target.value)}
                placeholder="Enter acres"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Soil Type</label>
              <select
                value={soilType}
                onChange={(e) => setSoilType(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              >
                <option value="sand">Sandy</option>
                <option value="loam">Loam</option>
                <option value="clay">Clay</option>
                <option value="siltLoam">Silt Loam</option>
                <option value="sandyLoam">Sandy Loam</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Previous Crop</label>
              <select
                value={previousCrop}
                onChange={(e) => setPreviousCrop(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              >
                <option value="none">None</option>
                <option value="corn">Corn</option>
                <option value="soybeans">Soybeans</option>
                <option value="alfalfa">Alfalfa</option>
                <option value="clover">Clover</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Irrigation System</label>
              <select
                value={irrigationType}
                onChange={(e) => setIrrigationType(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
              >
                <option value="none">None</option>
                <option value="drip">Drip Irrigation</option>
                <option value="sprinkler">Sprinkler System</option>
                <option value="pivot">Center Pivot</option>
                <option value="subsurface">Subsurface Drip</option>
              </select>
            </div>

            {calculatorType === 'equipment' && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Equipment Hours</label>
                <input
                  type="number"
                  value={equipmentHours}
                  onChange={(e) => setEquipmentHours(e.target.value)}
                  placeholder="Enter hours"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
                />
              </div>
            )}
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-emerald-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Results</h2>
              </div>
              
              {calculatorType === 'seed' && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600">Field Size:</div>
                  <div className="font-semibold">{results.acres} acres</div>
                  <div className="text-gray-600">Seeds Needed:</div>
                  <div className="font-semibold">{results.seeds} seeds</div>
                  <div className="text-gray-600">Estimated Cost:</div>
                  <div className="font-bold text-xl text-emerald-600">${results.cost}</div>
                </div>
              )}

              {calculatorType === 'fertilizer' && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600">Field Size:</div>
                  <div className="font-semibold">{results.acres} acres</div>
                  <div className="text-gray-600">Nitrogen Credit:</div>
                  <div className="font-semibold">{results.creditApplied} lbs/acre</div>
                  <div className="text-gray-600">Nitrogen Needed:</div>
                  <div className="font-semibold">{results.nitrogen} lbs N</div>
                  <div className="text-gray-600">Urea (46-0-0):</div>
                  <div className="font-semibold">{results.urea} lbs</div>
                  <div className="text-gray-600">Estimated Cost:</div>
                  <div className="font-bold text-xl text-emerald-600">${results.cost}</div>
                </div>
              )}

              {calculatorType === 'irrigation' && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600">System Efficiency:</div>
                  <div className="font-semibold">{results.efficiency}%</div>
                  <div className="text-gray-600">Water Needed:</div>
                  <div className="font-semibold">{results.waterNeeded} acre-inches</div>
                  <div className="text-gray-600">Installation Cost:</div>
                  <div className="font-semibold">${results.installCost}</div>
                  <div className="text-gray-600">Annual Cost:</div>
                  <div className="font-bold text-xl text-emerald-600">${results.annualCost}</div>
                </div>
              )}

              {calculatorType === 'equipment' && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600">Operating Hours:</div>
                  <div className="font-semibold">{results.hours} hours</div>
                  <div className="text-gray-600">Operating Cost:</div>
                  <div className="font-semibold">${results.operating}</div>
                  <div className="text-gray-600">Maintenance:</div>
                  <div className="font-semibold">${results.maintenance}</div>
                  <div className="text-gray-600">Fuel Cost:</div>
                  <div className="font-semibold">${results.fuel}</div>
                  <div className="text-gray-600">Total Cost:</div>
                  <div className="font-bold text-xl text-emerald-600">${results.total}</div>
                </div>
              )}

              {calculatorType === 'yield' && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600">Field Size:</div>
                  <div className="font-semibold">{results.acres} acres</div>
                  <div className="text-gray-600">Expected Yield:</div>
                  <div className="font-semibold">{results.yield} {results.unit}s</div>
                  <div className="text-gray-600">Gross Revenue:</div>
                  <div className="font-semibold">${results.revenue}</div>
                  <div className="text-gray-600">Total Costs:</div>
                  <div className="font-semibold">${results.costs}</div>
                  <div className="text-gray-600">Net Return:</div>
                  <div className="font-semibold">${results.netReturn}</div>
                  <div className="text-gray-600">ROI:</div>
                  <div className="font-bold text-xl text-emerald-600">{results.roi}%</div>
                </div>
              )}
            </div>
          )}

          <div className="bg-emerald-50 p-4 rounded-lg">
            <div className="flex items-center space-x-2 text-sm text-emerald-800">
              <AlertTriangle className="w-4 h-4" />
              <span>Based on Van Wert County typical yields and current market prices</span>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}