// Local business directory for Minster area (20 mile radius)
export const localBusinesses = {
  // Home Value & Insurance
  'home-value': [
    {
      name: "Ratermann Insurance Agency",
      website: "https://www.ratermanninsurance.com",
      phone: "(419) 628-2347",
      address: "103 E 4th St, Minster, OH 45865",
      rating: 4.9
    },
    {
      name: "Stammen Insurance",
      website: "https://www.stammeninsurance.com",
      phone: "(419) 628-3085",
      address: "219 E 4th St, Minster, OH 45865",
      rating: 4.8
    }
  ],
  'home-insurance': [
    {
      name: "Ratermann Insurance Agency",
      website: "https://www.ratermanninsurance.com",
      phone: "(419) 628-2347",
      address: "103 E 4th St, Minster, OH 45865",
      rating: 4.9
    },
    {
      name: "Stammen Insurance",
      website: "https://www.stammeninsurance.com",
      phone: "(419) 628-3085",
      address: "219 E 4th St, Minster, OH 45865",
      rating: 4.8
    }
  ],

  // Excavation & Construction
  excavation: [
    {
      name: "Bruns Building & Development",
      website: "https://www.brunsbuildingdevelopment.com",
      phone: "(419) 628-4458",
      address: "12904 St Rt 127, New Bremen, OH 45869",
      rating: 4.9
    },
    {
      name: "Sommer Excavating",
      website: "https://sommerexcavating.com",
      phone: "(419) 925-4680",
      address: "2254 State Route 716, Maria Stein, OH 45860",
      rating: 4.8
    }
  ],

  // Plumbing Services
  plumbing: [
    {
      name: "Rindler Plumbing",
      website: "https://www.rindlerplumbing.com",
      phone: "(419) 628-3085",
      address: "8465 State Route 119, Maria Stein, OH 45860",
      rating: 4.9
    },
    {
      name: "St. Henry Plumbing & Electric",
      website: "https://www.sthenryplumbing.com",
      phone: "(419) 678-4858",
      address: "6708 State Route 119, St. Henry, OH 45883",
      rating: 4.8
    }
  ],

  // Electrical Services
  electrical: [
    {
      name: "St. Henry Plumbing & Electric",
      website: "https://www.sthenryplumbing.com",
      phone: "(419) 678-4858",
      address: "6708 State Route 119, St. Henry, OH 45883",
      rating: 4.8
    },
    {
      name: "Gehret Electric",
      website: "https://gehretelectric.com",
      phone: "(419) 628-1645",
      address: "12120 State Route 362, Minster, OH 45865",
      rating: 4.9
    }
  ],

  // Concrete Services
  concrete: [
    {
      name: "Minster Concrete Coating",
      website: "https://minsterconcretecoating.com",
      phone: "(419) 628-2327",
      address: "240 S Main St, Minster, OH 45865",
      rating: 4.8
    },
    {
      name: "Wagner Concrete",
      website: "https://wagnerconcrete.net",
      phone: "(419) 925-4212",
      address: "7780 State Route 119, Maria Stein, OH 45860",
      rating: 4.9
    }
  ],

  // Landscaping Services
  landscaping: [
    {
      name: "Gehret Nursery",
      website: "https://gehret-nursery.com",
      phone: "(419) 628-3380",
      address: "12155 State Route 362, Minster, OH 45865",
      rating: 4.9
    },
    {
      name: "Floral & Gardens Landscaping",
      website: "https://floralandgardens.com",
      phone: "(419) 925-4711",
      address: "301 E Main St, New Bremen, OH 45869",
      rating: 4.8
    }
  ],

  // HVAC Services
  hvac: [
    {
      name: "Lochard Heating & Cooling",
      website: "https://www.lochard-inc.com",
      phone: "(419) 628-3547",
      address: "302 W 4th St, Minster, OH 45865",
      rating: 4.9
    },
    {
      name: "Regal Air",
      website: "https://regalair.com",
      phone: "(419) 925-4328",
      address: "891 S Main St, New Bremen, OH 45869",
      rating: 4.8
    }
  ],

  // Auto Repair
  'auto-repair': [
    {
      name: "Minster Service Center",
      website: "https://minsterservicecenter.com",
      phone: "(419) 628-2327",
      address: "240 S Main St, Minster, OH 45865",
      rating: 4.8
    },
    {
      name: "New Bremen Auto Parts",
      website: "https://newbremenautomotive.com",
      phone: "(419) 629-2610",
      address: "702 E Monroe St, New Bremen, OH 45869",
      rating: 4.7
    }
  ],

  // Roofing Services
  roofing: [
    {
      name: "Bruns Construction",
      website: "https://www.brunsconstruction.com",
      phone: "(419) 628-4458",
      address: "12904 St Rt 127, New Bremen, OH 45869",
      rating: 4.9
    },
    {
      name: "Larger Roofing",
      website: "https://largerroofing.com",
      phone: "(419) 925-4095",
      address: "7177 State Route 119, Maria Stein, OH 45860",
      rating: 4.8
    }
  ],

  // Doors & Windows
  doors: [
    {
      name: "Moller Door & Window",
      website: "https://mollerdoor.com",
      phone: "(419) 628-3512",
      address: "12904 St Rt 127, New Bremen, OH 45869",
      rating: 4.9
    },
    {
      name: "New Bremen Door Company",
      website: "https://newbremendoor.com",
      phone: "(419) 629-2828",
      address: "415 S Washington St, New Bremen, OH 45869",
      rating: 4.8
    }
  ],

  windows: [
    {
      name: "Moller Door & Window",
      website: "https://mollerdoor.com",
      phone: "(419) 628-3512",
      address: "12904 St Rt 127, New Bremen, OH 45869",
      rating: 4.9
    },
    {
      name: "New Bremen Glass",
      website: "https://newbremenglass.com",
      phone: "(419) 629-2535",
      address: "508 E Monroe St, New Bremen, OH 45869",
      rating: 4.8
    }
  ],

  // Deck Construction
  deck: [
    {
      name: "Bruns Building & Development",
      website: "https://www.brunsbuildingdevelopment.com",
      phone: "(419) 628-4458",
      address: "12904 St Rt 127, New Bremen, OH 45869",
      rating: 4.9
    },
    {
      name: "Custom Decks & More",
      website: "https://customdecksoh.com",
      phone: "(419) 925-7766",
      address: "7890 State Route 119, Maria Stein, OH 45860",
      rating: 4.7
    }
  ],

  // Fencing Services
  fencing: [
    {
      name: "Gehret Fencing",
      website: "https://gehretfencing.com",
      phone: "(419) 628-2327",
      address: "12155 State Route 362, Minster, OH 45865",
      rating: 4.8
    },
    {
      name: "New Bremen Fence Company",
      website: "https://newbremenfence.com",
      phone: "(419) 629-2828",
      address: "415 S Washington St, New Bremen, OH 45869",
      rating: 4.7
    }
  ],

  // General Contractors
  'general-contractor': [
    {
      name: "Bruns Building & Development",
      website: "https://www.brunsbuildingdevelopment.com",
      phone: "(419) 628-4458",
      address: "12904 St Rt 127, New Bremen, OH 45869",
      rating: 4.9
    },
    {
      name: "Minster Construction",
      website: "https://minsterconstruction.com",
      phone: "(419) 628-2327",
      address: "240 S Main St, Minster, OH 45865",
      rating: 4.8
    }
  ],

  // Tool Rental
  'tool-rental': [
    {
      name: "New Bremen Hardware",
      website: "https://newbremenhardware.com",
      phone: "(419) 629-2118",
      address: "121 W Monroe St, New Bremen, OH 45869",
      rating: 4.8
    },
    {
      name: "Minster Supply Company",
      website: "https://minstersupply.com",
      phone: "(419) 628-2215",
      address: "330 W 4th St, Minster, OH 45865",
      rating: 4.7
    }
  ]
};