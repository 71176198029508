import React, { useState } from 'react';
import { Flower2, Calculator, DollarSign, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';
import TreeServiceCalculator from '../components/landscaping/TreeServiceCalculator';
import MulchCalculator from '../components/landscaping/MulchCalculator';
import SnowRemovalCalculator from '../components/landscaping/SnowRemovalCalculator';
import LawnCareCalculator from '../components/landscaping/LawnCareCalculator';

type ServiceType = 'lawn' | 'trees' | 'mulch' | 'snow' | 'hardscape' | 'irrigation';
type LaborRate = 'market' | 'custom' | 'diy';
type AreaUnit = 'sqft' | 'acres';

export default function LandscapingEstimator() {
  // Service selection
  const [serviceType, setServiceType] = useState<ServiceType>('lawn');
  const [laborRate, setLaborRate] = useState<LaborRate>('market');
  const [customRate, setCustomRate] = useState('45');

  // Area inputs
  const [area, setArea] = useState('');
  const [areaUnit, setAreaUnit] = useState<AreaUnit>('sqft');
  const [includeLabor, setIncludeLabor] = useState(true);
  const [includeDelivery, setIncludeDelivery] = useState(true);

  // Tree service inputs
  const [treeType, setTreeType] = useState('deciduous');
  const [treeSize, setTreeSize] = useState('medium');
  const [treeQuantity, setTreeQuantity] = useState('1');

  // Mulch inputs
  const [mulchType, setMulchType] = useState('hardwood');
  const [mulchDepth, setMulchDepth] = useState('3');

  // Lawn care inputs
  const [frequency, setFrequency] = useState<'weekly' | 'biweekly'>('weekly');
  const [includeMulch, setIncludeMulch] = useState(true);
  const [includeEdging, setIncludeEdging] = useState(true);
  const [includeAeration, setIncludeAeration] = useState(false);
  const [includeFertilization, setIncludeFertilization] = useState(false);
  const [includeOverseeding, setIncludeOverseeding] = useState(false);

  // Snow removal inputs
  const [includeSalting, setIncludeSalting] = useState(true);
  const [includeIceMelt, setIncludeIceMelt] = useState(false);
  const [includePretreatment, setIncludePretreatment] = useState(false);

  // State for calculator results
  const [calculationResults, setCalculationResults] = useState<any>(null);

  // Convert area based on unit
  const getAreaInSqFt = () => {
    const areaValue = parseFloat(area);
    if (!areaValue) return 0;
    return areaUnit === 'acres' ? areaValue * 43560 : areaValue;
  };

  const handleCalculatorUpdate = (results: any) => {
    // Apply labor rate adjustments if using custom or DIY
    if (laborRate !== 'market') {
      const laborMultiplier = laborRate === 'diy' ? 0 : (parseFloat(customRate) / 45);
      
      if (results.costs?.labor) {
        results.costs.labor *= laborMultiplier;
      }
      if (results.costs?.installation) {
        results.costs.installation *= laborMultiplier;
      }
      
      // Recalculate total
      results.total = Object.values(results.costs).reduce((sum: number, cost: number) => sum + cost, 0);
    }

    // Add area conversion to results
    if (areaUnit === 'acres') {
      results.areaDisplay = {
        acres: parseFloat(area),
        sqft: getAreaInSqFt()
      };
    }

    setCalculationResults(results);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-emerald-600 to-emerald-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        {/* Header section remains the same */}
        
        <div className="space-y-6">
          {/* Service Type selection remains the same */}

          {/* Labor Rate section remains the same */}

          {serviceType !== 'trees' && (
            <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">Area</label>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="number"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
                  placeholder={`Enter ${areaUnit === 'acres' ? 'acres' : 'square feet'}`}
                />
                <select
                  value={areaUnit}
                  onChange={(e) => setAreaUnit(e.target.value as AreaUnit)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring focus:ring-emerald-200"
                >
                  <option value="sqft">Square Feet</option>
                  <option value="acres">Acres</option>
                </select>
              </div>
            </div>
          )}

          {/* Service-specific inputs */}
          {serviceType === 'lawn' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Service Frequency</label>
                <div className="grid grid-cols-2 gap-2">
                  {(['weekly', 'biweekly'] as const).map((freq) => (
                    <button
                      key={freq}
                      onClick={() => setFrequency(freq)}
                      className={`py-2 px-4 rounded-lg capitalize ${
                        frequency === freq
                          ? 'bg-emerald-600 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }`}
                    >
                      {freq}
                    </button>
                  ))}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Basic Services</label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={includeMulch}
                      onChange={(e) => setIncludeMulch(e.target.checked)}
                      className="rounded text-emerald-600 focus:ring-emerald-500"
                    />
                    <span>Mulch Installation</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={includeEdging}
                      onChange={(e) => setIncludeEdging(e.target.checked)}
                      className="rounded text-emerald-600 focus:ring-emerald-500"
                    />
                    <span>Edging</span>
                  </label>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Additional Services</label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={includeAeration}
                      onChange={(e) => setIncludeAeration(e.target.checked)}
                      className="rounded text-emerald-600 focus:ring-emerald-500"
                    />
                    <span>Aeration</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={includeFertilization}
                      onChange={(e) => setIncludeFertilization(e.target.checked)}
                      className="rounded text-emerald-600 focus:ring-emerald-500"
                    />
                    <span>Fertilization</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={includeOverseeding}
                      onChange={(e) => setIncludeOverseeding(e.target.checked)}
                      className="rounded text-emerald-600 focus:ring-emerald-500"
                    />
                    <span>Overseeding</span>
                  </label>
                </div>
              </div>
            </div>
          )}

          {/* Snow removal section */}
          {serviceType === 'snow' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Basic Services</label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={includeSalting}
                    onChange={(e) => setIncludeSalting(e.target.checked)}
                    className="rounded text-emerald-600 focus:ring-emerald-500"
                  />
                  <span>Salt Application</span>
                </label>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Additional Services</label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={includeIceMelt}
                    onChange={(e) => setIncludeIceMelt(e.target.checked)}
                    className="rounded text-emerald-600 focus:ring-emerald-500"
                  />
                  <span>Ice Melt Application</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={includePretreatment}
                    onChange={(e) => setIncludePretreatment(e.target.checked)}
                    className="rounded text-emerald-600 focus:ring-emerald-500"
                  />
                  <span>Pre-treatment Service</span>
                </label>
              </div>
            </div>
          )}

          {/* Service-specific calculators */}
          <div className="hidden">
            {serviceType === 'lawn' && (
              <LawnCareCalculator
                area={getAreaInSqFt()}
                includeLabor={laborRate !== 'diy'}
                frequency={frequency}
                includeMulch={includeMulch}
                includeEdging={includeEdging}
                includeAeration={includeAeration}
                includeFertilization={includeFertilization}
                includeOverseeding={includeOverseeding}
                onUpdate={handleCalculatorUpdate}
              />
            )}
            {/* Other calculator components remain the same */}
          </div>

          {/* Results section */}
          {calculationResults && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-emerald-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              {calculationResults.areaDisplay && (
                <div className="mb-4 text-sm text-gray-600">
                  <p>Area: {calculationResults.areaDisplay.acres.toFixed(2)} acres</p>
                  <p>({calculationResults.areaDisplay.sqft.toFixed(0)} square feet)</p>
                </div>
              )}

              <div className="space-y-4">
                {Object.entries(calculationResults.costs).map(([key, value]) => 
                  parseFloat(value as string) > 0 && (
                    <div key={key} className="flex justify-between">
                      <span className="text-gray-600 capitalize">{key.replace(/([A-Z])/g, ' $1').trim()}:</span>
                      <span className="font-semibold">${(value as number).toFixed(2)}</span>
                    </div>
                  )
                )}

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-emerald-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-emerald-600">
                    ${calculationResults.total.toFixed(2)}
                  </span>
                </div>

                {calculationResults.perService && (
                  <div className="text-center text-gray-600">
                    Cost per Service: ${calculationResults.perService.toFixed(2)}
                  </div>
                )}

                {laborRate === 'diy' && (
                  <div className="mt-4 text-sm text-gray-600">
                    <p>* DIY cost estimate excludes labor. Professional installation recommended for best results.</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Info section remains the same */}
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}