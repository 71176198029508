import React, { useState } from 'react';
import { Wind, Calculator, DollarSign, Thermometer, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ServiceType = 'repair' | 'replacement' | 'maintenance' | 'installation';
type SystemType = 'ac' | 'furnace' | 'heatPump' | 'ductwork' | 'thermostat';
type SystemSize = '1.5' | '2' | '2.5' | '3' | '3.5' | '4' | '5';

export default function HVACEstimator() {
  const [serviceType, setServiceType] = useState<ServiceType>('repair');
  const [systemType, setSystemType] = useState<SystemType>('ac');
  const [systemSize, setSystemSize] = useState<SystemSize>('3');
  const [quality, setQuality] = useState<'economy' | 'standard' | 'premium'>('standard');
  const [emergency, setEmergency] = useState(false);
  const [weekend, setWeekend] = useState(false);
  const [ductworkFeet, setDuctworkFeet] = useState('');
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const laborRates = {
    base: 95, // per hour
    emergency: 175, // per hour
    weekend: 142.50, // per hour (1.5x base)
    minimum: 175 // minimum service call
  };

  // Equipment costs by type and quality
  const equipmentCosts = {
    ac: {
      economy: { '1.5': 2200, '2': 2400, '2.5': 2600, '3': 2800, '3.5': 3000, '4': 3300, '5': 3800 },
      standard: { '1.5': 3000, '2': 3300, '2.5': 3600, '3': 3900, '3.5': 4200, '4': 4600, '5': 5200 },
      premium: { '1.5': 4000, '2': 4400, '2.5': 4800, '3': 5200, '3.5': 5600, '4': 6200, '5': 7000 }
    },
    furnace: {
      economy: { '1.5': 2000, '2': 2200, '2.5': 2400, '3': 2600, '3.5': 2800, '4': 3100, '5': 3500 },
      standard: { '1.5': 2800, '2': 3100, '2.5': 3400, '3': 3700, '3.5': 4000, '4': 4400, '5': 5000 },
      premium: { '1.5': 3800, '2': 4200, '2.5': 4600, '3': 5000, '3.5': 5400, '4': 5900, '5': 6800 }
    },
    heatPump: {
      economy: { '1.5': 3500, '2': 3800, '2.5': 4100, '3': 4400, '3.5': 4700, '4': 5100, '5': 5800 },
      standard: { '1.5': 4500, '2': 4900, '2.5': 5300, '3': 5700, '3.5': 6100, '4': 6600, '5': 7500 },
      premium: { '1.5': 5800, '2': 6300, '2.5': 6800, '3': 7300, '3.5': 7800, '4': 8500, '5': 9500 }
    },
    ductwork: {
      economy: 35, // per linear foot
      standard: 45,
      premium: 60
    },
    thermostat: {
      economy: 85,
      standard: 225,
      premium: 450
    }
  };

  // Installation/repair time estimates in hours
  const timeEstimates = {
    repair: {
      ac: 2,
      furnace: 2,
      heatPump: 2.5,
      ductwork: 0.5, // per 10 feet
      thermostat: 1
    },
    replacement: {
      ac: 6,
      furnace: 6,
      heatPump: 8,
      ductwork: 0.75, // per 10 feet
      thermostat: 1.5
    },
    installation: {
      ac: 8,
      furnace: 8,
      heatPump: 10,
      ductwork: 1, // per 10 feet
      thermostat: 2
    },
    maintenance: {
      ac: 1.5,
      furnace: 1.5,
      heatPump: 2,
      ductwork: 0.25, // per 10 feet
      thermostat: 0.5
    }
  };

  const calculateEstimate = () => {
    if (systemType === 'ductwork' && !ductworkFeet) return null;

    // Calculate labor rate based on conditions
    let hourlyRate = laborRates.base;
    if (emergency) hourlyRate = laborRates.emergency;
    else if (weekend) hourlyRate = laborRates.weekend;

    // Calculate base time needed
    let hours = timeEstimates[serviceType][systemType];
    if (systemType === 'ductwork') {
      const feet = parseFloat(ductworkFeet);
      hours = hours * (feet / 10);
    }

    // Calculate equipment/materials cost
    let materials = 0;
    if (systemType === 'ductwork') {
      const feet = parseFloat(ductworkFeet);
      materials = equipmentCosts.ductwork[quality] * feet;
    } else if (systemType === 'thermostat') {
      materials = equipmentCosts.thermostat[quality];
    } else if (serviceType === 'replacement' || serviceType === 'installation') {
      materials = equipmentCosts[systemType][quality][systemSize];
    } else if (serviceType === 'repair') {
      materials = 150; // Average repair parts cost
    } else if (serviceType === 'maintenance') {
      materials = 50; // Basic maintenance supplies
    }

    // Calculate labor cost (minimum service call applies)
    const laborCost = Math.max(laborRates.minimum, hours * hourlyRate);

    // Add permit fees for certain jobs
    const permitFee = (includePermit && (serviceType === 'installation' || serviceType === 'replacement')) ? 175 : 0;

    // Calculate totals
    const subtotal = laborCost + materials;
    const tax = materials * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax + permitFee;

    return {
      labor: laborCost.toFixed(2),
      materials: materials.toFixed(2),
      tax: tax.toFixed(2),
      permit: permitFee.toFixed(2),
      total: total.toFixed(2),
      hours: hours.toFixed(1)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-600 to-sky-800 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Wind className="w-8 h-8 text-sky-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">HVAC Estimator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Type</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['repair', 'replacement', 'maintenance', 'installation'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setServiceType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceType === type
                      ? 'bg-sky-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">System Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['ac', 'furnace', 'heatPump', 'ductwork', 'thermostat'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setSystemType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    systemType === type
                      ? 'bg-sky-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'ac' ? 'AC' : 
                   type === 'heatPump' ? 'Heat Pump' : type}
                </button>
              ))}
            </div>
          </div>

          {(systemType === 'ac' || systemType === 'furnace' || systemType === 'heatPump') && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">System Size (Tons)</label>
              <div className="grid grid-cols-4 md:grid-cols-7 gap-2">
                {(['1.5', '2', '2.5', '3', '3.5', '4', '5'] as const).map((size) => (
                  <button
                    key={size}
                    onClick={() => setSystemSize(size)}
                    className={`py-2 px-4 rounded-lg ${
                      systemSize === size
                        ? 'bg-sky-600 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
          )}

          {systemType === 'ductwork' && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Ductwork Length (feet)</label>
              <input
                type="number"
                value={ductworkFeet}
                onChange={(e) => setDuctworkFeet(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-500 focus:ring focus:ring-sky-200"
                placeholder="Enter length in feet"
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Quality Level</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setQuality(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    quality === type
                      ? 'bg-sky-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={emergency}
                onChange={(e) => {
                  setEmergency(e.target.checked);
                  if (e.target.checked) setWeekend(false);
                }}
                className="rounded text-sky-600 focus:ring-sky-500"
              />
              <span>Emergency Service (Higher Rate)</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={weekend}
                onChange={(e) => {
                  setWeekend(e.target.checked);
                  if (e.target.checked) setEmergency(false);
                }}
                className="rounded text-sky-600 focus:ring-sky-500"
              />
              <span>Weekend Service (1.5x Rate)</span>
            </label>
            {(serviceType === 'installation' || serviceType === 'replacement') && (
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={includePermit}
                  onChange={(e) => setIncludePermit(e.target.checked)}
                  className="rounded text-sky-600 focus:ring-sky-500"
                />
                <span>Include Permit Fee ($175)</span>
              </label>
            )}
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-sky-600 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Thermometer className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Estimated Time:</span>
                  </div>
                  <span className="font-semibold">{results.hours} hours</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Materials/Equipment:</span>
                    <span className="font-semibold">${results.materials}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                  {parseFloat(results.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit Fee:</span>
                      <span className="font-semibold">${results.permit}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-sky-600 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-sky-600">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-sky-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-sky-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-sky-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Some HVAC work requires licensed professionals and permits by law.</p>
                <p>* Energy efficiency ratings and rebates may affect final costs.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}