import React, { useState } from 'react';
import { Sun, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type SystemType = 'residential' | 'commercial';
type PanelType = 'economy' | 'standard' | 'premium';
type InverterType = 'string' | 'microinverter' | 'optimizer';
type RoofType = 'asphalt' | 'metal' | 'tile' | 'flat';

export default function SolarCalculator() {
  // State for solar details
  const [systemType, setSystemType] = useState<SystemType>('residential');
  const [panelType, setPanelType] = useState<PanelType>('standard');
  const [inverterType, setInverterType] = useState<InverterType>('string');
  const [roofType, setRoofType] = useState<RoofType>('asphalt');
  const [monthlyBill, setMonthlyBill] = useState('');
  const [roofAngle, setRoofAngle] = useState('30');
  const [facingSouth, setFacingSouth] = useState(true);
  const [includeBattery, setIncludeBattery] = useState(false);
  const [includeMonitoring, setIncludeMonitoring] = useState(true);
  const [needsRoofRepair, setNeedsRoofRepair] = useState(false);
  const [needsTreeRemoval, setNeedsTreeRemoval] = useState(false);
  const [includePermit, setIncludePermit] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const solarPanels = {
    economy: {
      wattage: 350,
      cost: 0.75, // per watt
      efficiency: 0.17
    },
    standard: {
      wattage: 400,
      cost: 0.90,
      efficiency: 0.20
    },
    premium: {
      wattage: 450,
      cost: 1.10,
      efficiency: 0.23
    }
  };

  const inverters = {
    string: {
      cost: 0.25, // per watt
      efficiency: 0.96
    },
    microinverter: {
      cost: 0.40,
      efficiency: 0.98
    },
    optimizer: {
      cost: 0.35,
      efficiency: 0.97
    }
  };

  const installationCosts = {
    residential: 0.50, // per watt
    commercial: 0.65
  };

  const additionalCosts = {
    battery: {
      base: 8500,
      installation: 1500
    },
    monitoring: {
      equipment: 500,
      installation: 300
    },
    roofRepair: 2500,
    treeRemoval: 1200,
    permit: 400,
    inspection: 300
  };

  // Utility rates and incentives
  const utilityRates = {
    averageRate: 0.13, // per kWh
    netMetering: true,
    federalTaxCredit: 0.30, // 30% of total cost
    stateTaxCredit: 0.05, // 5% of total cost
    utilityRebate: 0.10 // per watt
  };

  const calculateSystemSize = (monthlyBill: number) => {
    const annualUsage = (monthlyBill / utilityRates.averageRate) * 12;
    const panel = solarPanels[panelType];
    const inverter = inverters[inverterType];
    const roofAngleEfficiency = Math.cos((90 - parseInt(roofAngle)) * Math.PI / 180);
    const directionEfficiency = facingSouth ? 1 : 0.85;
    
    const systemEfficiency = panel.efficiency * inverter.efficiency * roofAngleEfficiency * directionEfficiency;
    const averageSunHours = 4.2; // Average daily sun hours in Lima/Dayton area
    
    const requiredWattage = (annualUsage / (365 * averageSunHours * systemEfficiency)) * 1000;
    const numberOfPanels = Math.ceil(requiredWattage / panel.wattage);
    const totalWattage = numberOfPanels * panel.wattage;

    return {
      wattage: totalWattage,
      panels: numberOfPanels,
      annualProduction: totalWattage * averageSunHours * 365 * systemEfficiency / 1000
    };
  };

  const calculateEstimate = () => {
    const monthly = parseFloat(monthlyBill);
    if (!monthly) return null;

    const system = calculateSystemSize(monthly);
    const panel = solarPanels[panelType];
    const inverter = inverters[inverterType];

    // Calculate component costs
    const panelCost = system.wattage * panel.cost;
    const inverterCost = system.wattage * inverter.cost;
    const installCost = system.wattage * installationCosts[systemType];
    
    // Calculate additional costs
    const batteryCost = includeBattery ? 
      (additionalCosts.battery.base + additionalCosts.battery.installation) : 0;
    const monitoringCost = includeMonitoring ?
      (additionalCosts.monitoring.equipment + additionalCosts.monitoring.installation) : 0;
    const roofCost = needsRoofRepair ? additionalCosts.roofRepair : 0;
    const treeCost = needsTreeRemoval ? additionalCosts.treeRemoval : 0;
    const permitCost = includePermit ? (additionalCosts.permit + additionalCosts.inspection) : 0;

    // Calculate incentives
    const subtotal = panelCost + inverterCost + installCost + batteryCost + 
                    monitoringCost + roofCost + treeCost + permitCost;
    const utilityRebate = system.wattage * utilityRates.utilityRebate;
    const federalCredit = subtotal * utilityRates.federalTaxCredit;
    const stateCredit = subtotal * utilityRates.stateTaxCredit;
    
    // Calculate final costs
    const totalBeforeIncentives = subtotal;
    const totalAfterIncentives = subtotal - utilityRebate - federalCredit - stateCredit;

    // Calculate savings
    const annualSavings = (system.annualProduction * utilityRates.averageRate);
    const paybackPeriod = totalAfterIncentives / annualSavings;

    return {
      system: {
        size: system.wattage.toFixed(0),
        panels: system.panels,
        production: system.annualProduction.toFixed(0)
      },
      costs: {
        panels: panelCost.toFixed(2),
        inverter: inverterCost.toFixed(2),
        installation: installCost.toFixed(2),
        battery: batteryCost.toFixed(2),
        monitoring: monitoringCost.toFixed(2),
        roof: roofCost.toFixed(2),
        tree: treeCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      incentives: {
        utility: utilityRebate.toFixed(2),
        federal: federalCredit.toFixed(2),
        state: stateCredit.toFixed(2)
      },
      totals: {
        before: totalBeforeIncentives.toFixed(2),
        after: totalAfterIncentives.toFixed(2)
      },
      savings: {
        annual: annualSavings.toFixed(2),
        payback: paybackPeriod.toFixed(1)
      }
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-yellow-500 to-yellow-700 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Sun className="w-8 h-8 text-yellow-500 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Solar Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">System Type</label>
            <div className="grid grid-cols-2 gap-2">
              {(['residential', 'commercial'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setSystemType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    systemType === type
                      ? 'bg-yellow-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Panel Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setPanelType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    panelType === type
                      ? 'bg-yellow-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Inverter Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['string', 'microinverter', 'optimizer'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setInverterType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    inverterType === type
                      ? 'bg-yellow-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Monthly Electric Bill ($)</label>
            <input
              type="number"
              value={monthlyBill}
              onChange={(e) => setMonthlyBill(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
              placeholder="Enter average monthly bill"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Roof Angle (degrees)</label>
              <select
                value={roofAngle}
                onChange={(e) => setRoofAngle(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
              >
                {['0', '15', '30', '45'].map((angle) => (
                  <option key={angle} value={angle}>{angle}°</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Roof Type</label>
              <select
                value={roofType}
                onChange={(e) => setRoofType(e.target.value as RoofType)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring focus:ring-yellow-200"
              >
                {(['asphalt', 'metal', 'tile', 'flat'] as const).map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={facingSouth}
                onChange={(e) => setFacingSouth(e.target.checked)}
                className="rounded text-yellow-500 focus:ring-yellow-500"
              />
              <span>Roof Faces South</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBattery}
                onChange={(e) => setIncludeBattery(e.target.checked)}
                className="rounded text-yellow-500 focus:ring-yellow-500"
              />
              <span>Include Battery Storage</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeMonitoring}
                onChange={(e) => setIncludeMonitoring(e.target.checked)}
                className="rounded text-yellow-500 focus:ring-yellow-500"
              />
              <span>Include Monitoring System</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsRoofRepair}
                onChange={(e) => setNeedsRoofRepair(e.target.checked)}
                className="rounded text-yellow-500 focus:ring-yellow-500"
              />
              <span>Needs Roof Repair</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsTreeRemoval}
                onChange={(e) => setNeedsTreeRemoval(e.target.checked)}
                className="rounded text-yellow-500 focus:ring-yellow-500"
              />
              <span>Needs Tree Removal</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePermit}
                onChange={(e) => setIncludePermit(e.target.checked)}
                className="rounded text-yellow-500 focus:ring-yellow-500"
              />
              <span>Include Permits & Inspection</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-yellow-500 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">System Size:</span>
                  </div>
                  <span className="font-semibold">{results.system.size} watts ({results.system.panels} panels)</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Solar Panels:</span>
                    <span className="font-semibold">${results.costs.panels}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Inverter:</span>
                    <span className="font-semibold">${results.costs.inverter}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Installation:</span>
                    <span className="font-semibold">${results.costs.installation}</span>
                  </div>
                  {parseFloat(results.costs.battery) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Battery Storage:</span>
                      <span className="font-semibold">${results.costs.battery}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.monitoring) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Monitoring System:</span>
                      <span className="font-semibold">${results.costs.monitoring}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.roof) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Roof Repair:</span>
                      <span className="font-semibold">${results.costs.roof}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.tree) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Tree Removal:</span>
                      <span className="font-semibold">${results.costs.tree}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permits & Inspection:</span>
                      <span className="font-semibold">${results.costs.permit}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Total Before Incentives:</span>
                    <span className="font-semibold">${results.totals.before}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Federal Tax Credit:</span>
                    <span className="font-semibold text-green-600">-${results.incentives.federal}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">State Tax Credit:</span>
                    <span className="font-semibold text-green-600">-${results.incentives.state}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Utility Rebate:</span>
                    <span className="font-semibold text-green-600">-${results.incentives.utility}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <DollarSign className="w-5 h-5 text-yellow-500 mr-2" />
                      <span className="font-bold">Final Cost:</span>
                    </div>
                    <span className="text-2xl font-bold text-yellow-500">${results.totals.after}</span>
                  </div>
                </div>

                <div className="bg-green-50 p-4 rounded-lg mt-4">
                  <h3 className="font-semibold text-green-800 mb-2">Energy Production & Savings</h3>
                  <div className="space-y-1">
                    <div className="flex justify-between text-green-700">
                      <span>Annual Production:</span>
                      <span className="font-semibold">{results.system.production} kWh</span>
                    </div>
                    <div className="flex justify-between text-green-700">
                      <span>Annual Savings:</span>
                      <span className="font-semibold">${results.savings.annual}</span>
                    </div>
                    <div className="flex justify-between text-green-700">
                      <span>Payback Period:</span>
                      <span className="font-semibold">{results.savings.payback} years</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-yellow-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-yellow-800">
                <p>* Estimates based on average Lima/Dayton area rates and solar conditions.</p>
                <p>* Additional incentives may be available from local utilities.</p>
                <p>* Actual savings may vary based on energy usage and weather patterns.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}