import React from 'react';
import { ExternalLink } from 'lucide-react';

interface Business {
  name: string;
  website: string;
  phone?: string;
  address?: string;
  rating?: number;
}

interface BusinessDirectoryProps {
  businesses: Business[];
}

export default function BusinessDirectory({ businesses }: BusinessDirectoryProps) {
  return (
    <div className="mt-4 space-y-3">
      <h3 className="text-lg font-semibold text-gray-800">Local Service Providers</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {businesses.map((business, index) => (
          <a
            key={index}
            href={business.website}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-start p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex-grow">
              <div className="flex items-center">
                <h4 className="font-medium text-gray-900">{business.name}</h4>
                <ExternalLink className="w-4 h-4 ml-2 text-gray-400" />
              </div>
              {business.phone && (
                <p className="text-sm text-gray-600 mt-1">{business.phone}</p>
              )}
              {business.address && (
                <p className="text-sm text-gray-500 mt-1">{business.address}</p>
              )}
              {business.rating && (
                <div className="flex items-center mt-1">
                  {[...Array(5)].map((_, i) => (
                    <span
                      key={i}
                      className={`text-sm ${
                        i < business.rating! 
                          ? 'text-yellow-400' 
                          : 'text-gray-300'
                      }`}
                    >
                      ★
                    </span>
                  ))}
                </div>
              )}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}