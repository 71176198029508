import React, { useState } from 'react';
import { Construction, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type ProjectType = 'driveway' | 'patio' | 'foundation' | 'sidewalk' | 'custom';
type ConcreteGrade = 'standard' | 'reinforced' | 'decorative';

export default function ConcreteCalculator() {
  // State for concrete details
  const [projectType, setProjectType] = useState<ProjectType>('driveway');
  const [concreteGrade, setConcreteGrade] = useState<ConcreteGrade>('standard');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [depth, setDepth] = useState('4');
  const [includeRebar, setIncludeRebar] = useState(true);
  const [includeFiberMesh, setIncludeFiberMesh] = useState(false);
  const [includeFinish, setIncludeFinish] = useState(true);
  const [needsExcavation, setNeedsExcavation] = useState(true);
  const [includeFormWork, setIncludeFormWork] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const concreteCosts = {
    standard: 145, // per cubic yard
    reinforced: 165,
    decorative: 185
  };

  const additionalCosts = {
    rebar: 2.75, // per linear foot
    fiberMesh: 12, // per cubic yard
    finish: {
      standard: 2.50, // per sq ft
      stamped: 8.00,
      colored: 5.00
    },
    excavation: 3.50, // per sq ft
    formWork: 4.50, // per linear ft
    disposal: 250, // flat fee
    delivery: {
      base: 150,
      perMile: 5
    },
    permit: 250
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    const d = parseFloat(depth);
    if (!l || !w) return null;

    // Calculate volumes and areas
    const cubicFeet = (l * w * (d / 12));
    const cubicYards = cubicFeet / 27;
    const squareFeet = l * w;
    const perimeter = 2 * (l + w);

    // Calculate base concrete cost
    const concreteCost = cubicYards * concreteCosts[concreteGrade];

    // Calculate rebar costs if included
    const rebarCost = includeRebar ? (perimeter * 2 * additionalCosts.rebar) : 0;

    // Calculate fiber mesh cost if included
    const fiberMeshCost = includeFiberMesh ? (cubicYards * additionalCosts.fiberMesh) : 0;

    // Calculate finishing costs
    const finishCost = includeFinish ? 
      (squareFeet * additionalCosts.finish[concreteGrade === 'decorative' ? 'stamped' : 'standard']) : 0;

    // Calculate excavation and disposal if needed
    const excavationCost = needsExcavation ? 
      (squareFeet * additionalCosts.excavation + additionalCosts.disposal) : 0;

    // Calculate form work if included
    const formWorkCost = includeFormWork ? (perimeter * additionalCosts.formWork) : 0;

    // Calculate delivery (assume 10 mile average)
    const deliveryCost = additionalCosts.delivery.base + 
                        (additionalCosts.delivery.perMile * 10);

    // Calculate permit fee
    const permitCost = additionalCosts.permit;

    // Calculate totals
    const subtotal = concreteCost + rebarCost + fiberMeshCost + finishCost + 
                    excavationCost + formWorkCost + deliveryCost + permitCost;
    const tax = concreteCost * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        area: squareFeet.toFixed(0),
        volume: cubicYards.toFixed(2),
        perimeter: perimeter.toFixed(0)
      },
      costs: {
        concrete: concreteCost.toFixed(2),
        rebar: rebarCost.toFixed(2),
        fiberMesh: fiberMeshCost.toFixed(2),
        finish: finishCost.toFixed(2),
        excavation: excavationCost.toFixed(2),
        formWork: formWorkCost.toFixed(2),
        delivery: deliveryCost.toFixed(2),
        permit: permitCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-700 to-gray-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Construction className="w-8 h-8 text-gray-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Concrete Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Project Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['driveway', 'patio', 'foundation', 'sidewalk', 'custom'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setProjectType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    projectType === type
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Concrete Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['standard', 'reinforced', 'decorative'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setConcreteGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    concreteGrade === grade
                      ? 'bg-gray-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200"
                placeholder="Enter width"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Depth (inches)</label>
              <select
                value={depth}
                onChange={(e) => setDepth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200"
              >
                {['3', '4', '5', '6', '8', '12'].map((d) => (
                  <option key={d} value={d}>{d}"</option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeRebar}
                onChange={(e) => setIncludeRebar(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Rebar</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeFiberMesh}
                onChange={(e) => setIncludeFiberMesh(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Add Fiber Mesh</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeFinish}
                onChange={(e) => setIncludeFinish(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Finishing</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsExcavation}
                onChange={(e) => setNeedsExcavation(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Needs Excavation</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeFormWork}
                onChange={(e) => setIncludeFormWork(e.target.checked)}
                className="rounded text-gray-700 focus:ring-gray-500"
              />
              <span>Include Form Work</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-gray-700 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Project Size:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.volume} cubic yards</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Concrete:</span>
                    <span className="font-semibold">${results.costs.concrete}</span>
                  </div>
                  {parseFloat(results.costs.rebar) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Rebar:</span>
                      <span className="font-semibold">${results.costs.rebar}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.fiberMesh) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Fiber Mesh:</span>
                      <span className="font-semibold">${results.costs.fiberMesh}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.finish) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Finishing:</span>
                      <span className="font-semibold">${results.costs.finish}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.excavation) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Excavation:</span>
                      <span className="font-semibold">${results.costs.excavation}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.formWork) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Form Work:</span>
                      <span className="font-semibold">${results.costs.formWork}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Delivery:</span>
                    <span className="font-semibold">${results.costs.delivery}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Permit:</span>
                    <span className="font-semibold">${results.costs.permit}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-gray-700 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-gray-700">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-gray-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-gray-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-gray-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for difficult terrain or special requirements.</p>
                <p>* All concrete work requires proper permits and inspections.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}