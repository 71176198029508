import React, { useState } from 'react';
import { Heart, Calculator, DollarSign, Calendar, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type VenueType = 'hotel' | 'banquet' | 'outdoor' | 'restaurant' | 'church' | 'historic';
type CateringStyle = 'buffet' | 'plated' | 'stations' | 'family';
type ServiceLevel = 'basic' | 'standard' | 'premium' | 'luxury';

export default function WeddingCalculator() {
  // State for wedding details
  const [guestCount, setGuestCount] = useState('100');
  const [venueType, setVenueType] = useState<VenueType>('banquet');
  const [cateringStyle, setCateringStyle] = useState<CateringStyle>('buffet');
  const [serviceLevel, setServiceLevel] = useState<ServiceLevel>('standard');
  const [season, setSeason] = useState<'spring' | 'summer' | 'fall' | 'winter'>('summer');
  const [includeBar, setIncludeBar] = useState(true);
  const [includeDJ, setIncludeDJ] = useState(true);
  const [includePhotographer, setIncludePhotographer] = useState(true);
  const [includeFlorist, setIncludeFlorist] = useState(true);
  const [includeWeddingCake, setIncludeWeddingCake] = useState(true);
  const [includeCoordinator, setIncludeCoordinator] = useState(true);

  // Current market rates (2024 Lima/Dayton area)
  const venueCosts = {
    hotel: { basic: 3500, standard: 5000, premium: 7500, luxury: 12000 },
    banquet: { basic: 3000, standard: 4500, premium: 6500, luxury: 10000 },
    outdoor: { basic: 2500, standard: 4000, premium: 6000, luxury: 9000 },
    restaurant: { basic: 2000, standard: 3500, premium: 5500, luxury: 8500 },
    church: { basic: 1000, standard: 1500, premium: 2500, luxury: 4000 },
    historic: { basic: 4000, standard: 6000, premium: 9000, luxury: 15000 }
  };

  const cateringCosts = {
    buffet: { basic: 35, standard: 45, premium: 65, luxury: 95 }, // per person
    plated: { basic: 45, standard: 60, premium: 85, luxury: 125 },
    stations: { basic: 40, standard: 55, premium: 75, luxury: 110 },
    family: { basic: 38, standard: 50, premium: 70, luxury: 100 }
  };

  const barCosts = {
    basic: 25, // per person (beer & wine)
    standard: 35, // (basic + well liquor)
    premium: 45, // (standard + premium liquor)
    luxury: 65 // (premium + champagne + specialty)
  };

  const serviceCosts = {
    dj: { basic: 800, standard: 1200, premium: 1800, luxury: 2500 },
    photographer: { basic: 2000, standard: 3000, premium: 4500, luxury: 6500 },
    florist: { basic: 1500, standard: 2500, premium: 4000, luxury: 6000 },
    cake: { basic: 4, standard: 6, premium: 8, luxury: 12 }, // per person
    coordinator: { basic: 1200, standard: 2000, premium: 3500, luxury: 5000 }
  };

  // Seasonal multipliers
  const seasonalMultipliers = {
    spring: 1.1,
    summer: 1.2,
    fall: 1.15,
    winter: 0.9
  };

  const calculateEstimate = () => {
    const guests = parseInt(guestCount);
    if (!guests) return null;

    // Apply seasonal multiplier
    const seasonMultiplier = seasonalMultipliers[season];

    // Calculate venue cost
    const venueCost = venueCosts[venueType][serviceLevel] * seasonMultiplier;

    // Calculate catering cost
    const perPersonCatering = cateringCosts[cateringStyle][serviceLevel];
    const cateringCost = guests * perPersonCatering;

    // Calculate bar cost if included
    const barCost = includeBar ? (guests * barCosts[serviceLevel]) : 0;

    // Calculate additional services
    const djCost = includeDJ ? serviceCosts.dj[serviceLevel] : 0;
    const photographerCost = includePhotographer ? serviceCosts.photographer[serviceLevel] : 0;
    const floristCost = includeFlorist ? serviceCosts.florist[serviceLevel] : 0;
    const cakeCost = includeWeddingCake ? (guests * serviceCosts.cake[serviceLevel]) : 0;
    const coordinatorCost = includeCoordinator ? serviceCosts.coordinator[serviceLevel] : 0;

    // Calculate totals
    const subtotal = venueCost + cateringCost + barCost + djCost + 
                    photographerCost + floristCost + cakeCost + coordinatorCost;
    const tax = subtotal * 0.075; // 7.5% sales tax
    const serviceCharge = subtotal * 0.20; // 20% service charge
    const total = subtotal + tax + serviceCharge;

    return {
      costs: {
        venue: venueCost.toFixed(2),
        catering: cateringCost.toFixed(2),
        bar: barCost.toFixed(2),
        dj: djCost.toFixed(2),
        photographer: photographerCost.toFixed(2),
        florist: floristCost.toFixed(2),
        cake: cakeCost.toFixed(2),
        coordinator: coordinatorCost.toFixed(2)
      },
      charges: {
        tax: tax.toFixed(2),
        service: serviceCharge.toFixed(2)
      },
      total: total.toFixed(2),
      perPerson: (total / guests).toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-500 to-pink-700 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Heart className="w-8 h-8 text-pink-500 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Wedding Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Number of Guests</label>
            <input
              type="number"
              value={guestCount}
              onChange={(e) => setGuestCount(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-pink-500 focus:ring focus:ring-pink-200"
              placeholder="Enter guest count"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Venue Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['hotel', 'banquet', 'outdoor', 'restaurant', 'church', 'historic'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setVenueType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    venueType === type
                      ? 'bg-pink-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Catering Style</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['buffet', 'plated', 'stations', 'family'] as const).map((style) => (
                <button
                  key={style}
                  onClick={() => setCateringStyle(style)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    cateringStyle === style
                      ? 'bg-pink-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {style}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Service Level</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['basic', 'standard', 'premium', 'luxury'] as const).map((level) => (
                <button
                  key={level}
                  onClick={() => setServiceLevel(level)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    serviceLevel === level
                      ? 'bg-pink-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {level}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Season</label>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
              {(['spring', 'summer', 'fall', 'winter'] as const).map((s) => (
                <button
                  key={s}
                  onClick={() => setSeason(s)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    season === s
                      ? 'bg-pink-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {s}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeBar}
                onChange={(e) => setIncludeBar(e.target.checked)}
                className="rounded text-pink-500 focus:ring-pink-500"
              />
              <span>Include Bar Service</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDJ}
                onChange={(e) => setIncludeDJ(e.target.checked)}
                className="rounded text-pink-500 focus:ring-pink-500"
              />
              <span>Include DJ</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includePhotographer}
                onChange={(e) => setIncludePhotographer(e.target.checked)}
                className="rounded text-pink-500 focus:ring-pink-500"
              />
              <span>Include Photographer</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeFlorist}
                onChange={(e) => setIncludeFlorist(e.target.checked)}
                className="rounded text-pink-500 focus:ring-pink-500"
              />
              <span>Include Florist</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeWeddingCake}
                onChange={(e) => setIncludeWeddingCake(e.target.checked)}
                className="rounded text-pink-500 focus:ring-pink-500"
              />
              <span>Include Wedding Cake</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeCoordinator}
                onChange={(e) => setIncludeCoordinator(e.target.checked)}
                className="rounded text-pink-500 focus:ring-pink-500"
              />
              <span>Include Wedding Coordinator</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-pink-500 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Calendar className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Guest Count:</span>
                  </div>
                  <span className="font-semibold">{guestCount} people</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Venue:</span>
                    <span className="font-semibold">${results.costs.venue}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Catering:</span>
                    <span className="font-semibold">${results.costs.catering}</span>
                  </div>
                  {parseFloat(results.costs.bar) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Bar Service:</span>
                      <span className="font-semibold">${results.costs.bar}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.dj) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">DJ:</span>
                      <span className="font-semibold">${results.costs.dj}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.photographer) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Photographer:</span>
                      <span className="font-semibold">${results.costs.photographer}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.florist) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Florist:</span>
                      <span className="font-semibold">${results.costs.florist}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.cake) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Wedding Cake:</span>
                      <span className="font-semibold">${results.costs.cake}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.coordinator) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Coordinator:</span>
                      <span className="font-semibold">${results.costs.coordinator}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Service Charge:</span>
                    <span className="font-semibold">${results.charges.service}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.charges.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <DollarSign className="w-5 h-5 text-pink-500 mr-2" />
                      <span className="font-bold">Total Estimate:</span>
                    </div>
                    <span className="text-2xl font-bold text-pink-500">${results.total}</span>
                  </div>
                  <div className="text-sm text-gray-600 text-center">
                    Cost per Guest: ${results.perPerson}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-pink-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-pink-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-pink-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific vendors and requirements.</p>
                <p>* Additional costs may apply for special requests or custom features.</p>
                <p>* Seasonal pricing variations may affect final costs.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Wedding Planning Timeline</h3>
            <div className="space-y-4">
              <div>
                <h4 className="font-semibold text-gray-800">12+ Months Before</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Set budget and guest list</li>
                  <li>Choose wedding date</li>
                  <li>Book venue and caterer</li>
                  <li>Start dress shopping</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">6-12 Months Before</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Book photographer and DJ</li>
                  <li>Order invitations</li>
                  <li>Plan honeymoon</li>
                  <li>Register for gifts</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">3-6 Months Before</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Order wedding cake</li>
                  <li>Book florist</li>
                  <li>Plan ceremony details</li>
                  <li>Schedule dress fittings</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-800">1-3 Months Before</h4>
                <ul className="list-disc pl-5 text-gray-700">
                  <li>Send invitations</li>
                  <li>Finalize menu</li>
                  <li>Create timeline</li>
                  <li>Confirm all vendors</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}