import React from 'react';
import { Club, Trash2 } from 'lucide-react';
import Footer from './Footer';

interface PlayerSetupProps {
  players: { name: string; scores: number[] }[];
  onNameChange: (index: number, name: string) => void;
  onAddPlayer: () => void;
  onRemovePlayer: (index: number) => void;
  onStartGame: () => void;
}

export default function PlayerSetup({ 
  players, 
  onNameChange, 
  onAddPlayer, 
  onRemovePlayer, 
  onStartGame 
}: PlayerSetupProps) {
  return (
    <div className="min-h-screen bg-gradient-to-b from-green-800 to-green-600 p-6">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Club className="w-8 h-8 text-green-600 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Golf Score</h1>
        </div>
        
        <div className="space-y-4">
          {players.map((player, index) => (
            <div key={index} className="flex items-center space-x-2">
              <input
                type="text"
                placeholder={`Player ${index + 1} name`}
                value={player.name}
                onChange={(e) => onNameChange(index, e.target.value)}
                className="flex-1 p-2 border rounded focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
              {players.length > 1 && (
                <button
                  onClick={() => onRemovePlayer(index)}
                  className="p-2 text-red-500 hover:text-red-700"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              )}
            </div>
          ))}
        </div>

        {players.length < 6 && (
          <button
            onClick={onAddPlayer}
            className="mt-4 w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition"
          >
            Add Player
          </button>
        )}

        <button
          onClick={() => {
            if (players.some(p => p.name.trim() === '')) {
              alert('Please enter names for all players');
              return;
            }
            onStartGame();
          }}
          className="mt-4 w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition"
        >
          Start Game
        </button>

        <Footer />
      </div>
    </div>
  );
}