import React, { useState } from 'react';
import { Square, Calculator, DollarSign, Ruler, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';
import ShareSection from '../components/ShareSection';

type FlooringType = 'hardwood' | 'laminate' | 'tile' | 'vinyl' | 'carpet';
type QualityGrade = 'economy' | 'standard' | 'premium';

export default function FlooringCalculator() {
  // State for flooring details
  const [flooringType, setFlooringType] = useState<FlooringType>('hardwood');
  const [qualityGrade, setQualityGrade] = useState<QualityGrade>('standard');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [includeUnderlayment, setIncludeUnderlayment] = useState(true);
  const [includeRemoval, setIncludeRemoval] = useState(false);
  const [includeDisposal, setIncludeDisposal] = useState(false);
  const [includeTrim, setIncludeTrim] = useState(true);
  const [needsLeveling, setNeedsLeveling] = useState(false);

  // Current market rates (2024 Lima/Dayton area)
  const flooringCosts = {
    hardwood: {
      economy: 6.50, // per sq ft
      standard: 8.50,
      premium: 12.50
    },
    laminate: {
      economy: 3.50,
      standard: 4.75,
      premium: 6.50
    },
    tile: {
      economy: 5.50,
      standard: 7.50,
      premium: 11.50
    },
    vinyl: {
      economy: 3.75,
      standard: 5.25,
      premium: 7.50
    },
    carpet: {
      economy: 3.25,
      standard: 4.50,
      premium: 6.75
    }
  };

  const laborRates = {
    hardwood: 4.50, // per sq ft
    laminate: 3.50,
    tile: 6.50,
    vinyl: 3.00,
    carpet: 2.50
  };

  const additionalCosts = {
    underlayment: {
      hardwood: 1.25, // per sq ft
      laminate: 0.75,
      tile: 1.50,
      vinyl: 0.85,
      carpet: 0.95
    },
    removal: 1.75, // per sq ft
    disposal: 1.25, // per sq ft
    trim: {
      hardwood: 3.50, // per linear ft
      laminate: 2.75,
      tile: 4.50,
      vinyl: 2.50,
      carpet: 2.25
    },
    leveling: 2.25 // per sq ft
  };

  const calculateEstimate = () => {
    const l = parseFloat(length);
    const w = parseFloat(width);
    if (!l || !w) return null;

    // Calculate total floor area
    const floorArea = l * w;
    const perimeter = 2 * (l + w);
    
    // Calculate base flooring and labor costs
    const flooringCost = floorArea * flooringCosts[flooringType][qualityGrade];
    const laborCost = floorArea * laborRates[flooringType];

    // Calculate additional feature costs
    const underlaymentCost = includeUnderlayment ? 
      (floorArea * additionalCosts.underlayment[flooringType]) : 0;

    const removalCost = includeRemoval ? (floorArea * additionalCosts.removal) : 0;
    const disposalCost = includeDisposal ? (floorArea * additionalCosts.disposal) : 0;
    
    const trimCost = includeTrim ? 
      (perimeter * additionalCosts.trim[flooringType]) : 0;

    const levelingCost = needsLeveling ? (floorArea * additionalCosts.leveling) : 0;

    // Calculate totals
    const subtotal = flooringCost + laborCost + underlaymentCost + removalCost + 
                    disposalCost + trimCost + levelingCost;
    const tax = flooringCost * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax;

    return {
      dimensions: {
        area: floorArea.toFixed(0),
        perimeter: perimeter.toFixed(0)
      },
      costs: {
        materials: flooringCost.toFixed(2),
        labor: laborCost.toFixed(2),
        underlayment: underlaymentCost.toFixed(2),
        removal: removalCost.toFixed(2),
        disposal: disposalCost.toFixed(2),
        trim: trimCost.toFixed(2),
        leveling: levelingCost.toFixed(2)
      },
      tax: tax.toFixed(2),
      total: total.toFixed(2),
      perSqFt: (total / floorArea).toFixed(2)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-700 to-amber-900 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Square className="w-8 h-8 text-amber-700 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Flooring Calculator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Flooring Type</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['hardwood', 'laminate', 'tile', 'vinyl', 'carpet'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFlooringType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    flooringType === type
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Quality Grade</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((grade) => (
                <button
                  key={grade}
                  onClick={() => setQualityGrade(grade)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    qualityGrade === grade
                      ? 'bg-amber-700 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {grade}
                </button>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Length (feet)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Enter length"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Width (feet)</label>
              <input
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
                placeholder="Enter width"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeUnderlayment}
                onChange={(e) => setIncludeUnderlayment(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Underlayment</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeRemoval}
                onChange={(e) => setIncludeRemoval(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Floor Removal</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeDisposal}
                onChange={(e) => setIncludeDisposal(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Disposal</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={includeTrim}
                onChange={(e) => setIncludeTrim(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Include Trim/Baseboards</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={needsLeveling}
                onChange={(e) => setNeedsLeveling(e.target.checked)}
                className="rounded text-amber-700 focus:ring-amber-500"
              />
              <span>Needs Floor Leveling</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-amber-700 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Ruler className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Floor Area:</span>
                  </div>
                  <span className="font-semibold">{results.dimensions.area} sq ft</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Materials:</span>
                    <span className="font-semibold">${results.costs.materials}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.costs.labor}</span>
                  </div>
                  {parseFloat(results.costs.underlayment) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Underlayment:</span>
                      <span className="font-semibold">${results.costs.underlayment}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.removal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Removal:</span>
                      <span className="font-semibold">${results.costs.removal}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.disposal) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Disposal:</span>
                      <span className="font-semibold">${results.costs.disposal}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.trim) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Trim Work:</span>
                      <span className="font-semibold">${results.costs.trim}</span>
                    </div>
                  )}
                  {parseFloat(results.costs.leveling) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Floor Leveling:</span>
                      <span className="font-semibold">${results.costs.leveling}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                </div>

                <div className="border-t pt-4 flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <DollarSign className="w-5 h-5 text-amber-700 mr-2" />
                      <span className="font-bold">Total Estimate:</span>
                    </div>
                    <span className="text-2xl font-bold text-amber-700">${results.total}</span>
                  </div>
                  <div className="text-sm text-gray-600 text-center">
                    Cost per Square Foot: ${results.perSqFt}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="bg-amber-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-amber-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Additional costs may apply for repairs or special requirements.</p>
                <p>* Material costs may vary based on specific styles and patterns selected.</p>
              </div>
            </div>
          </div>

          <div className="prose prose-sm max-w-none">
            <h3 className="text-lg font-semibold text-gray-900">Understanding Flooring Costs</h3>
            <p className="text-gray-700">
              Flooring costs in the Lima/Dayton area vary based on several factors including material choice, room size, and additional features. Basic installation typically ranges from $3-15 per square foot, with premium materials and additional services increasing costs.
            </p>
            <h4 className="text-md font-semibold text-gray-900 mt-4">Key Considerations</h4>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Material durability and maintenance requirements</li>
              <li>Subfloor condition and preparation needs</li>
              <li>Room layout and complexity</li>
              <li>Existing flooring removal</li>
              <li>Trim and transition pieces</li>
            </ul>
            <p className="text-gray-700 mt-4">
              For the most accurate estimate, we recommend getting quotes from multiple local contractors and considering both immediate costs and long-term maintenance requirements when selecting materials.
            </p>
          </div>
        </div>

        <Footer />
        
        <ShareSection currentUrl={window.location.href} />
      </div>
    </div>
  );
}