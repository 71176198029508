import React, { useState } from 'react';
import { Zap, Calculator, DollarSign, Clock, AlertTriangle } from 'lucide-react';
import Footer from '../components/Footer';

type JobType = 'repair' | 'installation' | 'upgrade';
type FixtureType = 'outlet' | 'switch' | 'fixture' | 'panel' | 'circuit' | 'ceiling-fan';
type PanelSize = '100' | '150' | '200' | '400';

interface MaterialCosts {
  [key: string]: {
    economy: number;
    standard: number;
    premium: number;
  };
}

export default function ElectricalEstimator() {
  // State for job details
  const [jobType, setJobType] = useState<JobType>('repair');
  const [fixtureType, setFixtureType] = useState<FixtureType>('outlet');
  const [quality, setQuality] = useState<'economy' | 'standard' | 'premium'>('standard');
  const [quantity, setQuantity] = useState<string>('1');
  const [panelSize, setPanelSize] = useState<PanelSize>('200');
  const [wireLength, setWireLength] = useState<string>('');
  const [emergency, setEmergency] = useState(false);
  const [weekend, setWeekend] = useState(false);

  // Current market rates (2024)
  const laborRates = {
    base: 95, // per hour
    emergency: 175, // per hour
    weekend: 142.50, // per hour (1.5x base)
    minimum: 175, // minimum service call
  };

  // Material costs by fixture type and quality
  const materialCosts: MaterialCosts = {
    outlet: {
      economy: 8,
      standard: 15,
      premium: 25
    },
    switch: {
      economy: 10,
      standard: 20,
      premium: 45
    },
    fixture: {
      economy: 45,
      standard: 85,
      premium: 200
    },
    panel: {
      economy: 450,
      standard: 750,
      premium: 1200
    },
    circuit: {
      economy: 75,
      standard: 125,
      premium: 200
    },
    'ceiling-fan': {
      economy: 85,
      standard: 150,
      premium: 300
    }
  };

  // Wire costs per foot (includes cable + conduit if needed)
  const wireCosts = {
    outlet: { economy: 2, standard: 3, premium: 4 },
    switch: { economy: 2, standard: 3, premium: 4 },
    fixture: { economy: 2.5, standard: 3.5, premium: 5 },
    panel: { economy: 4, standard: 6, premium: 8 },
    circuit: { economy: 3, standard: 4, premium: 6 },
    'ceiling-fan': { economy: 3, standard: 4, premium: 5 }
  };

  // Time estimates in hours (base time per unit)
  const timeEstimates = {
    repair: {
      outlet: 0.75,
      switch: 0.75,
      fixture: 1,
      panel: 4,
      circuit: 2,
      'ceiling-fan': 2
    },
    installation: {
      outlet: 1,
      switch: 1,
      fixture: 1.5,
      panel: 8,
      circuit: 4,
      'ceiling-fan': 3
    },
    upgrade: {
      outlet: 1,
      switch: 1,
      fixture: 1.5,
      panel: 6,
      circuit: 3,
      'ceiling-fan': 2.5
    }
  };

  // Panel upgrade additional costs
  const panelUpgradeCosts = {
    '100': { cost: 800, time: 6 },
    '150': { cost: 1200, time: 7 },
    '200': { cost: 1600, time: 8 },
    '400': { cost: 2500, time: 10 }
  };

  const calculateEstimate = () => {
    if (!quantity) return null;

    // Calculate labor rate based on conditions
    let hourlyRate = laborRates.base;
    if (emergency) hourlyRate = laborRates.emergency;
    else if (weekend) hourlyRate = laborRates.weekend;

    // Calculate base time needed
    let hours = timeEstimates[jobType][fixtureType];
    const units = parseInt(quantity);
    
    // Adjust time for multiple units
    if (fixtureType !== 'panel') {
      hours = hours * units;
    }

    // Add panel size upgrade time if applicable
    if (fixtureType === 'panel') {
      hours = panelUpgradeCosts[panelSize].time;
    }

    // Calculate materials cost
    let materials = materialCosts[fixtureType][quality];
    if (fixtureType !== 'panel') {
      materials = materials * units;
    } else {
      materials = panelUpgradeCosts[panelSize].cost;
    }

    // Add wire costs if specified
    if (wireLength) {
      const length = parseFloat(wireLength);
      materials += wireCosts[fixtureType][quality] * length;
      hours += (length / 50) * 0.5; // Add 30 mins per 50 feet of wire
    }

    // Calculate total labor cost (minimum service call applies)
    const laborCost = Math.max(laborRates.minimum, hours * hourlyRate);

    // Add permit fees for certain jobs
    const permitFee = (jobType === 'installation' || fixtureType === 'panel' || 
                      (fixtureType === 'circuit' && jobType !== 'repair')) ? 175 : 0;

    // Calculate totals
    const subtotal = laborCost + materials;
    const tax = materials * 0.075; // 7.5% sales tax on materials
    const total = subtotal + tax + permitFee;

    return {
      labor: laborCost.toFixed(2),
      materials: materials.toFixed(2),
      tax: tax.toFixed(2),
      permit: permitFee.toFixed(2),
      total: total.toFixed(2),
      hours: hours.toFixed(1)
    };
  };

  const results = calculateEstimate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-amber-500 to-amber-700 p-6">
      <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center mb-6">
          <Zap className="w-8 h-8 text-amber-500 mr-2" />
          <h1 className="text-2xl font-bold text-gray-800">Electrical Estimator</h1>
        </div>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Job Type</label>
            <div className="grid grid-cols-3 gap-2">
              {(['repair', 'installation', 'upgrade'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setJobType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    jobType === type
                      ? 'bg-amber-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Type of Work</label>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
              {(['outlet', 'switch', 'fixture', 'panel', 'circuit', 'ceiling-fan'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setFixtureType(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    fixtureType === type
                      ? 'bg-amber-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type === 'ceiling-fan' ? 'Ceiling Fan' : type}
                </button>
              ))}
            </div>
          </div>

          {fixtureType === 'panel' ? (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Panel Size (Amps)</label>
              <div className="grid grid-cols-4 gap-2">
                {(['100', '150', '200', '400'] as const).map((size) => (
                  <button
                    key={size}
                    onClick={() => setPanelSize(size)}
                    className={`py-2 px-4 rounded-lg ${
                      panelSize === size
                        ? 'bg-amber-500 text-white'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {size}A
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <label className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Wire Length (feet)</label>
            <input
              type="number"
              value={wireLength}
              onChange={(e) => setWireLength(e.target.value)}
              placeholder="Optional"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring focus:ring-amber-200"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Material Quality</label>
            <div className="grid grid-cols-3 gap-2">
              {(['economy', 'standard', 'premium'] as const).map((type) => (
                <button
                  key={type}
                  onClick={() => setQuality(type)}
                  className={`py-2 px-4 rounded-lg capitalize ${
                    quality === type
                      ? 'bg-amber-500 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>

          <div className="flex space-x-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={emergency}
                onChange={(e) => {
                  setEmergency(e.target.checked);
                  if (e.target.checked) setWeekend(false);
                }}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Emergency Service</span>
            </label>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={weekend}
                onChange={(e) => {
                  setWeekend(e.target.checked);
                  if (e.target.checked) setEmergency(false);
                }}
                className="rounded text-amber-500 focus:ring-amber-500"
              />
              <span>Weekend Service</span>
            </label>
          </div>

          {results && (
            <div className="bg-gray-50 rounded-lg p-6 mt-6">
              <div className="flex items-center justify-center mb-4">
                <Calculator className="w-6 h-6 text-amber-500 mr-2" />
                <h2 className="text-xl font-semibold text-gray-800">Cost Estimate</h2>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 text-gray-500 mr-2" />
                    <span className="text-gray-600">Estimated Time:</span>
                  </div>
                  <span className="font-semibold">{results.hours} hours</span>
                </div>

                <div className="border-t pt-4 space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Labor:</span>
                    <span className="font-semibold">${results.labor}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Materials:</span>
                    <span className="font-semibold">${results.materials}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Sales Tax:</span>
                    <span className="font-semibold">${results.tax}</span>
                  </div>
                  {parseFloat(results.permit) > 0 && (
                    <div className="flex justify-between">
                      <span className="text-gray-600">Permit Fee:</span>
                      <span className="font-semibold">${results.permit}</span>
                    </div>
                  )}
                </div>

                <div className="border-t pt-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-amber-500 mr-2" />
                    <span className="font-bold">Total Estimate:</span>
                  </div>
                  <span className="text-2xl font-bold text-amber-500">${results.total}</span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-amber-50 p-4 rounded-lg space-y-2">
            <div className="flex items-start space-x-2">
              <AlertTriangle className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-amber-800">
                <p>* Estimates based on average Lima/Dayton area rates. Actual costs may vary based on specific conditions.</p>
                <p>* Permit fees may be required and vary by municipality.</p>
                <p>* Some electrical work may require licensed professionals by law.</p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}